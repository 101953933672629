import { FormControl, FormHelperText } from "@material-ui/core";
import React from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { createGlobalStyle } from "styled-components";
import { Input } from "./Input";
import arrowInput from "../assets/input-phone-arrow.svg";

export const InputPhone = ({
  onChange,
  value,
  error,
  helperText,
  country = "BE",
  countries,
  inputProps,
  placeholder,
}: {
  onChange: (e: any) => void;
  value?: string;
  error?: boolean;
  helperText?: string;
  country?: string;
  countries?: string[];
  inputProps?: object;
  placeholder?: string | JSX.Element;
}) => {
  if (!isValidPhoneNumber(value) && isValidPhoneNumber("+" + value)) {
    value = "+" + value;
  }

  return (
    <FormControl error={error} style={{ display: "block" }}>
      <PhoneInputStyle />
      <PhoneInput
        defaultCountry={country}
        inputComponent={PhoneInputComponent}
        flags={flags}
        onChange={onChange}
        className="phone-number-input-wrapper--custom"
        numberInputProps={{
          className: "phone-number-input--custom",
          ...inputProps,
        }}
        countries={countries}
        value={value}
        placeholder={placeholder}
      />
      {error &&
        !!helperText &&
        (Array.isArray(helperText) ? (
          helperText.map((text, i) => (
            <FormHelperText key={i}>{text}</FormHelperText>
          ))
        ) : (
          <FormHelperText>{helperText}</FormHelperText>
        ))}
    </FormControl>
  );
};

const PhoneInputComponent = React.forwardRef(
  (
    {
      placeholder,
      type,
      autoComplete,
      onBlur,
      onFocus,
      onChange,
      onKeyDown,
      value,
      name,
      disabled,
      className,
      inputProps,
      label,
    }: any,
    ref,
  ) => (
    <Input
      ref={ref as any}
      {...{
        placeholder,
        type,
        autoComplete,
        onBlur,
        onFocus,
        onKeyDown,
        value,
        name,
        disabled,
        className,
        inputProps,
        label,
      }}
      onChange={e => {
        onChange(e.currentTarget.value);
      }}
    />
  ),
);

const PhoneInputStyle = createGlobalStyle`
  .phone-number-input--custom {
    border-bottom: none !important;
    height: 49px;
  }

  .phone-number-input-wrapper--custom {
    .react-phone-number-input__icon {
      border: none;

      svg {
        border-radius: 3px;
      }
    }

    .MuiFormControl-root {
      flex: 1;
    }

    .react-phone-number-input__country-select-arrow {
      width: 8px;
      height: 4px;
      border: 0;
      margin: 0;
      padding: 0;
      background: url(${arrowInput}) no-repeat center;
      margin-left: 8px;
    }
  }
`;
