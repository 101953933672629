import React, { createContext, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDimensions, useWindowSize } from "react-recipes";
import { mediaQuery } from "react-styled-mediaquery";
import VisibilitySensor from "react-visibility-sensor";
import styled from "styled-components";
import { Button } from "./Button";
import { Dropdown, IDropdownItem } from "./Dropdown";
import { GlobalStyles } from "./GlobalStyles";
import { LayoutFooter } from "./LayoutFooter";
import { LayoutFooterMobile } from "./LayoutFooterMobile";
import { LayoutHeader } from "./LayoutHeader";
import { LayoutHeaderMobile } from "./LayoutHeaderMobile";
import { LayoutNavigation } from "./LayoutNavigation";
import { LayoutNavigationMobile } from "./LayoutNavigationMobile";
import { LayoutServicesMobile } from "./LayoutServicesMobile";
import { colors } from "./lib/constants";
import { Media } from "./lib/media";
import { useWindowScrollBottom } from "./lib/useWindowScrollBottom";
import { Modal } from "./Modal";
import { ILink, INavLink, IService } from "./types";

const layoutContext = createContext({
  hasScroll: false,
  isFooterVisible: false,
});

interface IUserRolePartner {
  extId: string;
  name: string | null;
  type: string;
}

interface IUserRole {
  isCurrentRole: boolean;
  token: string;
  name: string;
  partner: IUserRolePartner;
}

export interface IMenuItem extends INavLink {
  hrefChildren?: string[];
}

export const useLayoutContext = () => useContext(layoutContext);

export const Layout = ({
  children,
  locale,
  onLocaleChange,
  userRoles = [],
  userRolesLoading,
  onPartnerChange,
  navLinks = [],
  footerLinks = [],
  services = [],
  onClickLogout,
  FormMyCredentialsComponent,
}: {
  children: React.ReactNode;
  locale: string;
  onLocaleChange: (locale: any) => void;
  userRoles?: IUserRole[];
  userRolesLoading?: boolean;
  onPartnerChange: (token: string) => void;
  navLinks?: IMenuItem[];
  footerLinks?: ILink[];
  services?: IService[];
  onClickLogout: React.ComponentProps<typeof LayoutNavigation>["onClickLogout"];
  FormMyCredentialsComponent?: React.ComponentType<any>;
}) => {
  const hideService = useWindowScrollBottom(20, 50);
  const [isNavigationMobileOpen, setNavigationMobileOpen] = useState(false);
  const [isServicesMobileOpen, setServicesMobileOpen] = useState(false);
  const [isModalPartnerOpen, setModalPartnerIsOpen] = useState(false);
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [footerRef, footerDimensions] = useDimensions();
  const { height } = useWindowSize();
  const hasScroll = footerDimensions && footerDimensions.height > height;
  const userRole = userRoles.find((role) => role.isCurrentRole);
  const currentPath =
    (typeof window !== "undefined" &&
      window.location.pathname.replace(/\/$/, "")) ||
    "";
  const [myCredentialsIsOpen, setMyCredentialsIsOpen] = useState(false);

  const nav = navLinks.map((item) => ({
    ...item,
    active:
      item.href === currentPath ||
      !!(
        item.hrefChildren?.length && item.hrefChildren.indexOf(currentPath) >= 0
      ),
  }));

  const closeAllMenusOnEsc = (e: React.KeyboardEvent) => {
    if (e.key === "Escape" || e.keyCode === 27) {
      setNavigationMobileOpen(false);
      setServicesMobileOpen(false);
    }
  };

  return (
    <layoutContext.Provider value={{ hasScroll, isFooterVisible }}>
      <GlobalStyles />
      <Modal
        onClose={() => setModalPartnerIsOpen(false)}
        open={isModalPartnerOpen}
        title={
          <FormattedMessage
            id="partnerSelector.title"
            defaultMessage="Sélectionnez votre entité"
          />
        }
      >
        <PartnerSelectorForm
          items={userRoles.map((role) => ({
            value: role.token,
            label: role.name,
          }))}
          value={userRole && userRole.token}
          disabled={!!userRolesLoading}
          onPartnerChange={(token) => {
            onPartnerChange(token);
            setModalPartnerIsOpen(false);
          }}
        />
      </Modal>
      <Media when="tablet">
        <LayoutNavigationMobile
          navLinks={nav}
          openPartnerModal={() => setModalPartnerIsOpen(true)}
          onClose={closeAllMenusOnEsc}
          onCloseMenu={() => {
            setNavigationMobileOpen(false);
          }}
          isOpen={isNavigationMobileOpen}
          locale={locale}
          onLocaleChange={onLocaleChange}
          partner={userRole?.partner}
          onClickLogout={onClickLogout}
          onOpenMyCredentials={() => { setMyCredentialsIsOpen(true) }}
        />
      </Media>
      <Media when="tablet">
        <LayoutServicesMobile
          services={services}
          onClose={closeAllMenusOnEsc}
          isOpen={isServicesMobileOpen}
        />
      </Media>
      <Media when="desktop">
        <LayoutNavigation
          navLinks={nav}
          locale={locale}
          onLocaleChange={onLocaleChange}
          onClickLogout={onClickLogout}
        />
      </Media>
      <Container>
        <Media when="desktop">
          <LayoutHeader
            onClickPartnerSelector={() => setModalPartnerIsOpen(true)}
            onClickLogout={onClickLogout}
            partner={userRole?.partner}
            onOpenMyCredentials={() => { setMyCredentialsIsOpen(true) }}
          />
        </Media>
        <Media when="tablet">
          <LayoutHeaderMobile
            isNavigationMobileOpen={isNavigationMobileOpen}
            isServicesMobileOpen={isServicesMobileOpen}
            onNavigationClose={() => setNavigationMobileOpen(false)}
            onNavigationOpen={() => {
              setServicesMobileOpen(false);
              setNavigationMobileOpen(true);
            }}
            onServicesOpen={() => {
              setNavigationMobileOpen(false);
              setServicesMobileOpen(true);
            }}
            onServicesClose={() => setServicesMobileOpen(false)}
          />
        </Media>
        <Content ref={footerRef}>
          <Children>{children}</Children>
          <MediaSticky when="desktop">
            <LayoutFooter
              hideService={hideService}
              services={services}
              footerLinks={footerLinks}
            />
          </MediaSticky>
          <Media when="tablet">
            <VisibilitySensor
              partialVisibility
              onChange={(visible) => {
                setIsFooterVisible(visible);
              }}
            >
              <LayoutFooterMobile footerLinks={footerLinks} />
            </VisibilitySensor>
          </Media>
        </Content>
      </Container>
      <Modal
        maxWidth="m"
        title={
          <FormattedMessage
            id="userPanel.nav.myCredentials"
            defaultMessage="Mes identifiants"
          />
        }
        open={myCredentialsIsOpen}
        onClose={() => setMyCredentialsIsOpen(false)}
      >
        {FormMyCredentialsComponent ? (
          <FormMyCredentialsComponent
            onClose={() => setMyCredentialsIsOpen(false)}
          />
        ) : null}
      </Modal>
    </layoutContext.Provider>
  );
};

const PartnerSelectorForm = ({
  items,
  value,
  disabled,
  onPartnerChange,
}: {
  items: IDropdownItem<string>[];
  value?: string;
  disabled?: boolean;
  onPartnerChange: (token: string) => void;
}) => {
  const [currentValue, setValue] = useState<string | null | undefined>(value);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onPartnerChange(currentValue || "");
  };
  return (
    <form onSubmit={handleSubmit}>
      <Dropdown
        items={items}
        value={currentValue}
        disabled={!!disabled}
        onChange={(val) => {
          setValue(val as string);
        }}
      />
      <Submit>
        <Button type="submit">
          <FormattedMessage
            id="partnerSelector.button.select"
            defaultMessage="Sélectionner"
          />
        </Button>
      </Submit>
    </form>
  );
};

const Container = styled.div`
  flex: 1 0 auto;
  background: ${colors.lightLter};
  display: flex;
  flex-direction: column;

  ${mediaQuery(">", "768px")`
    padding-left: 90px;
  `}
`;

const Content = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  ${mediaQuery(">", "768px")`
    padding: 30px 0 20px 0;
  `}

  ${mediaQuery("<=", "768px")`
    padding: 15px 0 0 0;
  `}
`;

const Children = styled.div`
  flex: 1 0 auto;
`;

const Submit = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
`;

const MediaSticky = styled(Media)`
  margin-top: 30px;
  position: sticky;
  bottom: 20px;
`;
