import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Button } from "./Button";
import { Card } from "./Card";
import { CouponsList } from "./CouponsList";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily } from "./lib/fonts";
import { mediaQuery } from "react-styled-mediaquery";

export const CardCouponsLatest = ({
  linkListing,
  ...props
}: React.ComponentProps<typeof CouponsList> & {
  linkListing?: string;
}) => (
  <CardStyled>
    <Header>
      <Title>
        <FormattedMessage
          id="website.coupons.table.title"
          defaultMessage="Derniers bons scannés"
        />
      </Title>
      {!!linkListing && !!props.coupons.length && (
        <ButtonMore href={linkListing} mini theme="bordered">
          <FormattedMessage
            id="website.coupons.table.button"
            defaultMessage="En voir plus"
          />
        </ButtonMore>
      )}
    </Header>
    <CouponsList {...props} />
  </CardStyled>
);

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${colors.lightLt};
`;

const CardStyled = styled(Card)`
  ${mediaQuery("<", "960px")`
    margin-top: 20px;
  `}
`;

const Title = styled.div`
  ${fontFamily(ffdinPro.bold)}
`;

const ButtonMore = styled(Button)`
  ${mediaQuery("<=", "mobile")`
    margin-top: 20px;
    flex-basis: 100%;
  `}
`;
