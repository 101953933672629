import * as React from "react";
import styled from "styled-components";
const SvgVideo16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M37.5 31.25L68.75 50 37.5 68.75zM75 12.5H25A12.5 12.5 0 0012.5 25v50A12.5 12.5 0 0025 87.5h50A12.5 12.5 0 0087.5 75V25A12.5 12.5 0 0075 12.5zM75 0a25 25 0 0125 25v50a25 25 0 01-25 25H25A25 25 0 010 75V25A25 25 0 0125 0z" />
  </svg>
);
export default styled(SvgVideo16)``;
