import useWindowScroll from "@react-hook/window-scroll"

export const useWindowScrollBottom = (fps: number, offset: number = 0): boolean => {
  const scrollY = useWindowScroll(fps)
  if (typeof window === 'undefined') {
    return false;
  }
  const isBottom = scrollY + window.innerHeight >= document.body.scrollHeight - offset

  return isBottom
}