import * as React from "react";
import styled from "styled-components";
const SvgBreeder16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M38 62.5C39.93 72 43.17 75 50 75s10.07-3 12-12.5zM50 0l48.08 44.8a6.55 6.55 0 010 9.27l-.26.26a6.55 6.55 0 01-9.27 0L50 15.78 11.45 54.33a6.55 6.55 0 01-9.27 0l-.26-.26a6.55 6.55 0 010-9.27zM38.56 85.14q-10.27-5-13.33-20.29a12.54 12.54 0 0110.36-14.63A13.57 13.57 0 0138 50h24a12.77 12.77 0 0113 12.5 12 12 0 01-.23 2.35q-3.06 15.34-13.33 20.29A12.5 12.5 0 0081.25 75a6.25 6.25 0 0112.5 0A25 25 0 0150 91.54 25 25 0 016.25 75a6.25 6.25 0 0112.5 0 12.5 12.5 0 0019.81 10.14z" />
  </svg>
);
export default styled(SvgBreeder16)``;
