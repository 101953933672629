import * as React from "react";
import styled from "styled-components";
const SvgFacebook16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 8 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 14.502v-5.5h-.5a1.5 1.5 0 010-3H2v-2c0-3 1.933-4 4-4L6.496 0A1.501 1.501 0 116.5 3.002H6c-.667 0-1 1-1 3h1.5a1.5 1.5 0 110 3H5v5.5a1.5 1.5 0 11-3 0z"
      fillRule="nonzero"
    />
  </svg>
);
export default styled(SvgFacebook16)``;
