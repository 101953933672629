import React from "react";
import styled from "styled-components";
import { Wrapper } from "./Wrapper";
import { Alert } from "./Alert";

export const WarningBox = styled(
  ({
    children,
    className,
    wrapper,
  }: {
    children: React.ReactNode | string;
    className?: string;
    wrapper?: boolean;
  }) => (
    <div className={className}>
      {!!wrapper ? (
        <Wrapper>
          <Alert type="warning">{children}</Alert>
        </Wrapper>
      ) : (
        <Alert type="warning">{children}</Alert>
      )}
    </div>
  ),
)`
  margin-bottom: 30px;
`;
