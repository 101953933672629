import React from "react";
import styled from "styled-components";
import { IconInfo16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { Media } from "./lib/media";
import { Link } from "./Link";
import logoPartnerportal from "../assets/partnerportal.svg"

const LanguagesItem = styled(Link)`
  &:not(:first-child) {
    margin-left: 15px;
  }
`;

const LanguagesContainer = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const Languages = ({
  locale,
  onLocaleChange,
}: {
  locale: string;
  onLocaleChange: (locale: any) => void;
}) => (
  <LanguagesContainer>
    {["Fr", "Nl"].map((language, i) => (
      <LanguagesItem
        key={i}
        borderless
        onClick={() => {
          onLocaleChange(language.toLowerCase());
        }}
        dark={locale === language.toLowerCase()}
        light={locale !== language.toLowerCase()}
      >
        {language}
      </LanguagesItem>
    ))}
  </LanguagesContainer>
);

const Img = styled.img`
  margin: 0 auto;
  display: block;
`;

const Container = styled.div`
  position: relative;
`;

const MobilePanelOpen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const LoginHeader = ({
  onClick,
  onLocaleChange,
  locale,
}: {
  onClick: () => void;
  onLocaleChange: (locale: any) => void;
  locale: string;
}) => (
  <Container>
    <Media when={["phablet", "desktop"]}>
      <Languages onLocaleChange={onLocaleChange} locale={locale} />
    </Media>
    <Img src={logoPartnerportal} />
    <Media when={["mobile"]}>
      <MobilePanelOpen>
        <IconInfo16
          style={{ display: "block" }}
          onClick={onClick}
          width={20}
          height={20}
          fill={colors.darkDk}
        />
      </MobilePanelOpen>
    </Media>
  </Container>
);
