import * as React from "react";
import styled from "styled-components";
const SvgCross16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M59.23 50l38.86 38.85a6.53 6.53 0 11-9.23 9.23L50 59.23 11.15 98.09a6.53 6.53 0 11-9.23-9.23L40.77 50 1.91 11.15a6.53 6.53 0 119.23-9.23L50 40.77 88.85 1.91a6.53 6.53 0 119.23 9.23z" />
  </svg>
);
export default styled(SvgCross16)``;
