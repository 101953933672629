import React from "react";
import { SwitchProps, Switch } from "@material-ui/core";
import { IUseInputPropsResult } from "./Form";
import styled from "styled-components";

export const InputBoolean = React.forwardRef(
  (
    {
      label,
      helperText,
      error,
      ...props
    }: SwitchProps &
      IUseInputPropsResult & {
        label?: string | React.ReactNode;
      },
    ref,
  ) => {
    return (
      <Container>
        {!!label && <Label>{label}</Label>}
        <Switch {...props} inputRef={ref as any} />
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled.div`
  > * {
    margin: 0;
  }
`;
