import React, { useState } from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import { colors } from "./lib/constants";
import { Button } from "./Button";
import { fontFamily, fontSize } from "./lib/cssUtils";
import { ffdinPro } from "./lib/fonts";
import { IconInfo16 } from "./icons-ts";
import { Modal } from "./Modal";
import { mediaQuery } from "react-styled-mediaquery";
import { groupBy } from "lodash";
import { format } from "date-fns";
import { FormattedMessage } from "react-intl";
import { PageLoading } from "./PageLoading";

export interface IPointsHistory {
  action: string;
  transaction_date: Date;
  label: string;
  orderNr?: number;
  total_points: number;
}

export const LoyaltyHistory: React.FC<{
  points: number;
  expiringPointsSummary: { points: number; date: string };
  leftPoints: { points: number; date: string }[];
  historyPoints: IPointsHistory[];
  buttonMore: React.ComponentProps<typeof Button> & { hidden?: boolean };
  loading?: boolean;
  purchaseLink?: string;
}> = ({
  points,
  leftPoints,
  historyPoints,
  buttonMore,
  expiringPointsSummary,
  loading,
  purchaseLink,
}) => {
  const [modalInfoPointsIsOpen, setModalInfoPointsIsOpen] = useState(false);

  const historyPointsGrouped = groupBy(historyPoints, (item) =>
    item.transaction_date.getFullYear(),
  );
  const years = Object.keys(historyPointsGrouped).map<
    [string, typeof historyPoints]
  >((year) => [year, historyPointsGrouped[year]]);

  if (loading) {
    return <PageLoading />;
  }
  return (
    <>
      <Modal
        onClose={() => setModalInfoPointsIsOpen(false)}
        open={modalInfoPointsIsOpen}
      >
        <ModalTitle>
          {leftPoints.reduce((a, b) => a + b.points, 0)} pts arrivent à
          expiration
        </ModalTitle>

        <Table>
          {leftPoints.map((item, index) => (
            <tr key={index}>
              <td>
                <ModalPoints>{item.points} pts</ModalPoints>
              </td>
              <td align="right">Le {item.date}</td>
            </tr>
          ))}
        </Table>
        {!!purchaseLink && (
          <ModalButton>
            <Button href={purchaseLink}>Échanger mes points</Button>
          </ModalButton>
        )}
      </Modal>
      <Row>
        <Col xs={12} sm={12} md={4}>
          <CardBalance>
            <CardBalanceTitle>
              <FormattedMessage
                id="loyalty.history.expiring.points.title"
                defaultMessage="Mon solde de points"
              />
            </CardBalanceTitle>
            <CardBalancePoints>{points}</CardBalancePoints>
            {expiringPointsSummary.points && expiringPointsSummary.date ? (
              <CardBalanceDesc>
                <FormattedMessage
                  id="loyalty.history.expiring.points.warning"
                  defaultMessage="{points} pts à utiliser rapidement avant le {date}"
                  values={{
                    points: expiringPointsSummary.points,
                    date: expiringPointsSummary.date,
                  }}
                />
                <ButtonModal onClick={() => setModalInfoPointsIsOpen(true)}>
                  <IconInfo16 width="16" fill="#fff" />
                </ButtonModal>
              </CardBalanceDesc>
            ) : null}
            {!!purchaseLink && (
              <Button theme="white" href={purchaseLink}>
                <FormattedMessage
                  id="loyalty.button.exchange.points"
                  defaultMessage="Échanger mes points"
                />
              </Button>
            )}
          </CardBalance>
        </Col>
        <Col xs={12} sm={12} md={8}>
          <HistoryPoints>
            {years.length <= 0 ? (
              <NoTransactions>
                <FormattedMessage
                  id="loyalty.history.transactions.empty.warning"
                  defaultMessage="Vous n'avez pas de points de fidélité"
                />
              </NoTransactions>
            ) : (
              years.map(([year, items], i) => (
                <div key={i}>
                  <Data
                    style={{
                      borderTop: i !== 0 ? `1px solid ${colors.lightLt}` : 0,
                      paddingTop: i === 0 ? 0 : 30,
                      marginTop: i === 0 ? 0 : 30,
                      paddingBottom: 30,
                      borderBottom: `1px solid ${colors.lightLt}`,
                    }}
                  >
                    {year}
                  </Data>
                  <TableHistory>
                    {items.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Data>
                            {format(item.transaction_date, "dd.MM.yyyy")}
                          </Data>
                        </td>
                        <td>{item.label}</td>
                        <td align="right">
                          <Points points={item.total_points}>
                            {item.total_points} pts
                          </Points>
                        </td>
                      </tr>
                    ))}
                  </TableHistory>
                </div>
              ))
            )}
          </HistoryPoints>
          {!buttonMore.hidden && (
            <ButtonMore>
              <Button {...buttonMore}>Voir plus</Button>
            </ButtonMore>
          )}
        </Col>
      </Row>
    </>
  );
};

const CardBalance = styled.div`
  background: ${colors.primary};
  border-radius: 10px;
  box-shadow: 0 10px 15px -5px rgba(51, 51, 51, 0.15);
  padding: 40px 35px;
  text-align: center;
  color: #fff;

  ${mediaQuery("<=", "tablet")`
    margin-bottom: 30px;
  `}
`;

const ButtonMore = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardBalanceTitle = styled.div`
  ${fontSize("16px")}
  ${fontFamily(ffdinPro.medium)}
`;

const CardBalancePoints = styled.div`
  ${fontSize("50px")}
  ${fontFamily(ffdinPro.bold)}
  letter-spacing: -0.5px;
  color: #ffffff;
  font-size: 50px;
  text-shadow: 5px 5px 0 #bf0016;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const CardBalanceDesc = styled.div`
  ${fontSize("14px")}
  ${fontFamily(ffdinPro.medium)}
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: center;
`;

const ButtonModal = styled.div`
  cursor: pointer;
  margin-left: 10px;
`;

const ModalTitle = styled.div`
  ${fontSize("19px")}
  ${fontFamily(ffdinPro.bold)}
`;

const ModalPoints = styled.div`
  ${fontSize("16px")}
  ${fontFamily(ffdinPro.bold)}
`;

const ModalButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const Table = styled.table`
  width: 100%;
  margin-top: 30px;

  td {
    width: 50%;
    padding: 20px 0;
    border-top: 1px solid ${colors.lightLt};
  }

  tr:last-child td {
    border-bottom: 1px solid ${colors.lightLt};
  }
`;

const HistoryPoints = styled.div`
  background: #fff;
  padding: 30px;
  border-radius: 10px;
`;

const TableHistory = styled.table`
  width: 100%;
  table-layout: fixed;
  margin-top: 20px;
  border-collapse: collapse;
  border-spacing: 0;

  tr:not(:first-child) td {
    border-top: 1px dashed ${colors.lightLt};
  }

  td {
    padding: 20px 0;
  }

  tr:last-child td {
    padding-bottom: 0;
  }
  tr:first-child td {
    padding-top: 0;
  }
`;

const Points = styled.div<{ points: number }>`
  background: ${(p) => (p.points >= 0 ? colors.successLt : colors.primaryLt)};
  border-radius: 99px;
  padding: 7px 13px 5px;
  color: ${(p) => (p.points >= 0 ? colors.success : colors.primary)};
  display: inline-block;
  ${fontSize("12px")}
  ${fontFamily(ffdinPro.bold)}
  min-width: 60px;
  text-align: center;
`;

const Data = styled.div`
  ${fontSize("16px")}
  ${fontFamily(ffdinPro.bold)}
`;

const NoTransactions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.dark};
`;
