import { FORM_ERROR } from "final-form";
import gql from "graphql-tag";
import React from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Col, Row } from "react-styled-flexboxgrid";
import { object, string } from "yup";
import { CardCoordinatesPartnerFragment } from "./__generated__/CardCoordinatesPartnerFragment";
import { Alert } from "./Alert";
import { Dropdown } from "./Dropdown";
import { Fieldset } from "./Fieldset";
import { Form, useInputProps } from "./Form";
import { FormTitle } from "./FormTitle";
import { Input } from "./Input";
import { IErrorResult, validationErrorsToObject } from "./lib/validationUtils";
import { ModalFooter } from "./ModalFooter";
import { OmitRecursively, ICountriesValue } from "./types";
import { InputRadioList } from "./InputRadioList";

export const messages = defineMessages({
  street1: {
    id: "form.label.address.street1",
    defaultMessage: "Adresse",
  },
  streetnum: {
    id: "form.label.address.streetnum",
    defaultMessage: "Numéro",
  },
  postalCode: {
    id: "form.label.address.postalCode",
    defaultMessage: "Code postal",
  },
  city: {
    id: "form.label.address.city",
    defaultMessage: "Localité",
  },
  country: {
    id: "form.label.address.country",
    defaultMessage: "Pays",
  },
  email: {
    id: "form.label.address.email",
    defaultMessage: "Adresse e-mail",
  },
  phone: {
    id: "form.label.address.phone",
    defaultMessage: "Téléphone",
  },
  fax: {
    id: "form.label.address.fax",
    defaultMessage: "Fax",
  },
  publicAddressDisplayPreference: {
    id: "form.label.publicAddressDisplayPreference",
    defaultMessage:
      "Comment souhaitez-vous faire apparaitre votre adresse au public ?",
  },
  publicAddressDisplayPreferenceValueComplete: {
    id: "form.label.publicAddressDisplayPreference.complete",
    defaultMessage: "Afficher mon adresse complète",
  },
  publicAddressDisplayPreferenceValueCity: {
    id: "form.label.publicAddressDisplayPreference.city",
    defaultMessage: "Afficher uniquement ma commune",
  },
  errorInvalidPhone: {
    id: "form.error.invalidPhone",
    defaultMessage: "Numéro de phone invalide",
  },
  errorInvalidFax: {
    id: "form.error.invalidFax",
    defaultMessage: "Numéro de fax invalide",
  },
});

type CardCoordinatesFormEditValues = OmitRecursively<
  CardCoordinatesPartnerFragment,
  "__typename"
>;

export const CardCoordinatesFormEdit = ({
  onClose,
  initialValues,
  countries,
  onSubmit,
  publicAddressDisplayPreferenceRequired,
}: {
  onClose: () => void;
  onSubmit: (
    values: Omit<CardCoordinatesFormEditValues, "type">,
  ) => Promise<IErrorResult | null | void>;
  initialValues: CardCoordinatesFormEditValues | undefined;
  countries?: ICountriesValue;
  publicAddressDisplayPreferenceRequired?: boolean;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Form<CardCoordinatesFormEditValues>
      initialValues={{
        type: "",
        email: "",
        phone: "",
        fax: "",
        ...initialValues,
        address: {
          street1: "",
          streetnum: "",
          postalCode: "",
          city: "",
          country: "",
          ...initialValues?.address,
        },
        publicAddressDisplayPreference:
          initialValues?.publicAddressDisplayPreference || "complete",
      }}
      fieldLabels={{
        address: {
          street1: formatMessage(messages.street1),
          streetnum: formatMessage(messages.streetnum),
          postalCode: formatMessage(messages.postalCode),
          city: formatMessage(messages.city),
          country: formatMessage(messages.country),
        },
        email: formatMessage(messages.email),
        phone: formatMessage(messages.phone),
        fax: formatMessage(messages.fax),
        publicAddressDisplayPreference: formatMessage(
          messages.publicAddressDisplayPreference,
        ),
      }}
      schema={object().shape({
        address: object().shape({
          street1: string().required(),
          streetnum: string().required(),
          postalCode: string().required(),
          city: string().required(),
          country: string().required(),
        }),
        email: string().required(),
        // phone: string().test(
        //   "isValidPhone",
        //   formatMessage(messages.errorInvalidPhone),
        //   value =>
        //     !value ||
        //     isValidPhoneNumber(value) ||
        //     isValidPhoneNumber(`+${value}`),
        // ),
        // fax: string().test(
        //   "isValidFax",
        //   formatMessage(messages.errorInvalidFax),
        //   value =>
        //     !value ||
        //     isValidPhoneNumber(value) ||
        //     isValidPhoneNumber(`+${value}`),
        // ),
        publicAddressDisplayPreference: publicAddressDisplayPreferenceRequired
          ? string().required()
          : string(),
      })}
      onSubmit={async ({ type, ...values }: CardCoordinatesFormEditValues) => {
        const rgxCleanPhone = /\s|-|_|\/|\.|\+|[a-z]/gi;
        const error = await onSubmit({
          ...values,
          phone: values.phone?.toString().replace(rgxCleanPhone, "") || "",
          fax: values.fax?.toString().replace(rgxCleanPhone, "") || "",
        });
        if (error) {
          return {
            [FORM_ERROR]: error.message,
            ...validationErrorsToObject(error.validationErrors),
          };
        }

        onClose();
        return;
      }}
      render={({ handleSubmit, submitError, values }) => {
        const phoneInputProps = useInputProps({ name: "phone" });
        const faxInputProps = useInputProps({ name: "fax" });
        const publicAddressDisplayPreferenceInputProps = {
          label: formatMessage(messages.publicAddressDisplayPreference),
          items: [
            {
              ...useInputProps({
                name: "publicAddressDisplayPreference",
                config: {
                  type: "radio",
                  value: "complete",
                },
              }),
              label: formatMessage(
                messages.publicAddressDisplayPreferenceValueComplete,
              ),
            },
            {
              ...useInputProps({
                name: "publicAddressDisplayPreference",
                config: {
                  type: "radio",
                  value: "city",
                },
              }),
              label: formatMessage(
                messages.publicAddressDisplayPreferenceValueCity,
              ),
            },
          ],
        };

        return (
          <form onSubmit={handleSubmit}>
            {submitError && (
              <div style={{ marginBottom: 30 }}>
                <Alert type="error">{submitError}</Alert>
              </div>
            )}
            <FormTitle spacing={values.type === "breeders"}>
              <FormattedMessage
                id="form.title.coordinatesAddress"
                defaultMessage="Adresse"
              />
            </FormTitle>
            <Input fullWidth {...useInputProps({ name: "address.street1" })} />
            <Row>
              <Col xs={12} md={6}>
                <Fieldset responsive offset={30}>
                  <Input
                    fullWidth
                    {...useInputProps({ name: "address.streetnum" })}
                  />
                </Fieldset>
              </Col>
              <Col xs={12} md={6}>
                <Fieldset responsive offset={30}>
                  <Input
                    fullWidth
                    {...useInputProps({ name: "address.postalCode" })}
                  />
                </Fieldset>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Fieldset responsive offset={30}>
                  <Input
                    fullWidth
                    {...useInputProps({ name: "address.city" })}
                  />
                </Fieldset>
              </Col>
              <Col xs={12} md={6}>
                <Fieldset responsive offset={30}>
                  <Dropdown
                    {...useInputProps({ name: "address.country" })}
                    items={countries || []}
                  />
                </Fieldset>
              </Col>
            </Row>
            {values.type === "breeders" && (
              <>
                <FormTitle spacing>
                  <FormattedMessage
                    id="website.informations.coordinates.formPreference.title"
                    defaultMessage="Préférence"
                  />
                </FormTitle>
                <Row>
                  <Col xs={12}>
                    <InputRadioList
                      {...publicAddressDisplayPreferenceInputProps}
                    />
                  </Col>
                </Row>
              </>
            )}
            <FormTitle spacing>
              <FormattedMessage
                id="website.informations.coordinates.formContact.title"
                defaultMessage="Contact"
              />
            </FormTitle>
            <Row>
              <Col xs={12}>
                <Input
                  fullWidth
                  type="email"
                  {...useInputProps({ name: "email" })}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Fieldset responsive offset={30}>
                  <Input
                    {...phoneInputProps}
                    fullWidth
                    type="tel"
                    // placeholder={phoneInputProps.label}
                    // countries={countries?.map(({ value }) => value)}
                    // country={values.address?.country || undefined}
                  />
                </Fieldset>
              </Col>
              <Col xs={12} md={6}>
                <Fieldset responsive offset={30}>
                  <Input
                    {...faxInputProps}
                    fullWidth
                    type="tel"
                    // placeholder={faxInputProps.label}
                    // countries={countries?.map(({ value }) => value)}
                  />
                </Fieldset>
              </Col>
            </Row>
            <ModalFooter onClose={onClose} buttonType="submit" />
          </form>
        );
      }}
    />
  );
};

CardCoordinatesFormEdit.PartnerFragment = gql`
  fragment CardCoordinatesPartnerFragment on PartnerFull {
    email
    phone
    fax
    address {
      street1
      streetnum
      postalCode
      city
      country
    }
    type
    publicAddressDisplayPreference
  }
`;
