import * as React from "react";
import styled from "styled-components";
const SvgPointerMap = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={36} height={40} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M36 17.702C36 7.93 27.936 0 17.98 0 8.022 0 0 7.93 0 17.702 0 23.31 2.633 28.275 6.747 31.52l10.45 8.21c.453.361 1.153.361 1.605 0l10.45-8.21A17.474 17.474 0 0036 17.702"
      fill="#EC001A"
      fillRule="nonzero"
    />
  </svg>
);
export default styled(SvgPointerMap)``;
