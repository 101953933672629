import React, { useState } from "react";
import styled from "styled-components";
import { Card } from "./Card";
import { CardIdentityFormEdit } from "./CardIdentityFormEdit";
import { ContentLoader } from "./ContentLoader";
import { IconEdit } from "./IconEdit";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { Modal } from "./Modal";
import { Placeholder } from "./Placeholder";
import { IPartner } from "./types";
import { IconPartnerType } from "./IconPartnerType";
import { colors } from "./lib/constants";
import { FormattedMessage } from "react-intl";

export const CardIdentity = ({
  partner,
  loading,
  onSubmit,
}: {
  loading?: boolean;
  partner?: React.ComponentProps<typeof CardIdentityFormEdit>["initialValues"] &
    Pick<
      IPartner,
      | "extId"
      | "hasLogo"
      | "logo"
      | "logoSmall"
      | "logoMedium"
      | "logoLarge"
      | "type"
    >;
  onSubmit: React.ComponentProps<typeof CardIdentityFormEdit>["onSubmit"];
}) => {
  const [modalEditIdentityIsOpen, setModalEditIdentityIsOpen] = useState(false);

  return (
    <>
      <Modal
        maxWidth="m"
        title={
          <FormattedMessage
            id="website.informations.identity.title"
            defaultMessage="Mon compte partenaire"
          />
        }
        open={modalEditIdentityIsOpen}
        onClose={() => setModalEditIdentityIsOpen(false)}
      >
        <CardIdentityFormEdit
          onClose={() => setModalEditIdentityIsOpen(false)}
          onSubmit={onSubmit}
          initialValues={partner}
        />
      </Modal>
      <Card>
        <Container>
          {loading || !partner ? (
            <ContentLoader {...{ width: 70, height: 70 }} />
          ) : partner.hasLogo ? (
            <Logo
              style={{
                background: `url(${partner.logoSmall}) no-repeat center / contain`,
              }}
            />
          ) : (
            <LogoThumb type={partner.type || undefined} />
          )}

          {loading || !partner ? (
            <div style={{ flex: 1, margin: "0 20px" }}>
              <Placeholder />
            </div>
          ) : (
            <Identity>
              <Name>{partner.name}</Name>
              <Identifier>
                <FormattedMessage
                  id="website.informations.identity.identifier"
                  defaultMessage="Identifiant"
                />{" "}
                : {partner.extId}
              </Identifier>
            </Identity>
          )}

          <IconEdit onClick={() => setModalEditIdentityIsOpen(true)} />
        </Container>
      </Card>
    </>
  );
};

const LogoThumb = ({ type, ...props }: { type?: string }) => (
  <IconPartnerType
    type={type}
    height={60}
    width={60}
    radius={20}
    color={colors.light}
    background={colors.lightLter}
    iconWidth={16}
  />
);

const Logo = styled.div`
  width: 60px;
  height: 60px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("19px")}
  letter-spacing: -0.5px;
`;

const Identifier = styled.div`
  color: ${colors.dark};
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("14px")}
  margin-top: 10px;
`;

const Identity = styled.div`
  flex: 1;
  margin: 0 30px;
`;
