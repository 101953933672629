import React, { useState } from "react";
import posed from "react-pose";
import styled from "styled-components";
import { LayoutFooterServices } from "./LayoutFooterServices";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { Link } from "./Link";
import { ILink, IService } from "./types";
import { Wrapper } from "./Wrapper";
import { useIntl } from "react-intl";

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Links = styled.div`
  display: flex;
`;

export const LayoutFooterItem = styled(Link)`
  color: ${colors.dark};
  letter-spacing: -0.5px;
  ${fontSize("14px")}
  ${fontFamily(ffdinPro.medium)}
  padding: 0;
  border: none;

  &:not(:first-child) {
    margin-left: 20px;
  }

  &:hover {
    color: ${colors.primary};
  }
`;

const Posed = posed.div({
  off: {
    opacity: 0,
    transition: {
      duration: 200,
    },
  },
  on: {
    opacity: 1,
    transition: {
      duration: 200,
    },
  },
});

export const LayoutFooter = ({
  services,
  footerLinks,
  hideService,
}: {
  services: IService[];
  footerLinks: ILink[];
  hideService?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { locale } = useIntl();

  return (
    <div>
      <Wrapper>
        <Inner>
          <Posed pose={hideService ? "on" : "off"}>
            <Links>
              {footerLinks.map((item, i) => (
                <LayoutFooterItem key={i} href={item.href} target="_blank">
                  {item.label}
                </LayoutFooterItem>
              ))}
            </Links>
            <Copyright>
              {locale === "fr" ? (
                <>
                  <div>
                    ©{new Date().getFullYear()} Royal Canin SAS. Tous droits
                    réservés.
                  </div>
                  <div>Une filiale de Mars, Incorporated.</div>
                </>
              ) : locale === "nl" ? (
                <>
                  <div>
                    ©{new Date().getFullYear()} Royal Canin SAS. Alle rechten
                    voorbehouden.
                  </div>
                  <div>Een dochteronderneming van Mars, Incorporated.</div>
                </>
              ) : (
                ""
              )}
            </Copyright>
          </Posed>
          <LayoutFooterServices
            isOpen={isOpen}
            onClick={() => setIsOpen(!isOpen)}
            services={services}
          />
        </Inner>
      </Wrapper>
    </div>
  );
};

const Copyright = styled.div`
  ${fontSize("11px")}
  color: #999;
  padding-top: 10px;
`;
