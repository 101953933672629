import * as React from "react";
import styled from "styled-components";
const SvgClub12 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M54.17 58.33A20.83 20.83 0 0075 37.5V16.67H25V37.5a20.83 20.83 0 0020.83 20.83zM89.53 50a37.54 37.54 0 01-31.2 24.77v8.56H75A8.33 8.33 0 0175 100H25a8.33 8.33 0 010-16.67h16.67v-8.56A37.54 37.54 0 0110.47 50H0V16.67h8.33V8.33A8.33 8.33 0 0116.67 0h66.66a8.33 8.33 0 018.33 8.33v8.33H100V50z" />
  </svg>
);
export default styled(SvgClub12)``;
