import * as React from "react";
import styled from "styled-components";
const SvgPen16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M78 12.89l-3.31 3.3 9.11 9.11 3.31-3.3zm-11.8 11.8L12.89 78v9.11H22L75.31 33.8zM87.11 3.78l9.11 9.11a12.89 12.89 0 010 18.23l-3.41 3.4a6 6 0 01-8.29 8.3L27.34 100H0V72.66l57.18-57.18a6 6 0 018.29-8.29l3.4-3.41a12.89 12.89 0 0118.24 0z" />
  </svg>
);
export default styled(SvgPen16)``;
