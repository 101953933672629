import * as React from "react";
import styled from "styled-components";
const SvgMale16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M93.75 0A6.25 6.25 0 01100 6.25V25a6.25 6.25 0 01-12.5 0V12.5H75A6.25 6.25 0 0175 0zm-50 25A31.25 31.25 0 1075 56.25 31.25 31.25 0 0043.75 25zm0-12.5A43.75 43.75 0 110 56.25 43.75 43.75 0 0143.75 12.5z" />
  </svg>
);
export default styled(SvgMale16)``;
