import { CircularProgress } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const LoaderContainer = styled.div`
  text-align: center;
  margin-top: 60px;
  display: flex;
  justify-content: center;
`;

export const Loader = ({
  style,
  size,
}: {
  style?: React.CSSProperties;
  size?: React.ComponentProps<typeof CircularProgress>["size"];
}) => (
  <LoaderContainer style={style}>
    <CircularProgress color="primary" size={size} />
  </LoaderContainer>
);
