import React from 'react';
import { MainProvider } from './src/lib/MainProvider'
import 'core-js/modules/es6.set';
import 'core-js/modules/es6.map';
import { setupLogicalLink } from "./gatsby-logical-link";

setupLogicalLink();

export const wrapRootElement = ({ element }) => (
  <MainProvider>
    {element}
  </MainProvider>
)
