import React from "react";
import styled from "styled-components";
import { Card } from "./Card";
import { LogicalLink } from "./LogicalLink";
import { ProductItem } from "./ProductItem";

const Container = styled(LogicalLink)`
  text-decoration: none;
  color: inherit;
  transition: box-shadow 0.2s;
  display: flex;
  flex: 1;

  &:hover {
    box-shadow: rgba(134, 134, 134, 0.08) 0px 4px 8px -1px,
      rgba(134, 134, 134, 0.06) 0px 8px 24px -2px;
  }
`;

export const ProductsListItem = ({
  item: { url, ...item },
}: {
  item: React.ComponentProps<typeof ProductItem>["item"] & {
    url: string;
  };
}) => (
  <Container href={url}>
    <Card containerStyles={{ display: "flex", flexDirection: "column" }}>
      <ProductItem item={item} />
    </Card>
  </Container>
);
