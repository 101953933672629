import { FORM_ERROR } from "final-form";
import gql from "graphql-tag";
import React from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { object, string } from "yup";
import { CardIdentityPartnerFragment } from "./__generated__/CardIdentityPartnerFragment";
import { Alert } from "./Alert";
import { Form, useInputProps } from "./Form";
import { Input } from "./Input";
import { IErrorResult, validationErrorsToObject } from "./lib/validationUtils";
import { ModalFooter } from "./ModalFooter";
import { OmitRecursively } from "./types";
import { IUploadZoneFile, UploadZone } from "./UploadZone";
import { Thumb } from "./UploadZoneThumbnails";
import styled from "styled-components";
import { mediaQuery } from "react-styled-mediaquery";
import { FormTitle } from "./FormTitle";

type CardIdentityFormEditValues = OmitRecursively<
  CardIdentityPartnerFragment,
  "__typename"
>;

const messages = defineMessages({
  name: {
    id: "form.label.partnerName",
    defaultMessage: "Dénomination",
  },
});

type FormValues = CardIdentityFormEditValues & {
  image?: IUploadZoneFile;
};

export const CardIdentityFormEdit = ({
  onClose,
  onSubmit,
  initialValues,
}: {
  onClose: () => void;
  onSubmit: (
    values: CardIdentityFormEditValues,
  ) => Promise<IErrorResult | null | void>;
  initialValues: CardIdentityFormEditValues | undefined;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Form<FormValues>
      initialValues={{
        name: "",
        hasLogo: false,
        logo: "",
        ...initialValues,
        image:
          initialValues?.hasLogo && initialValues?.logo
            ? { preview: initialValues.logo }
            : undefined,
      }}
      fieldLabels={{
        name: formatMessage(messages.name),
      }}
      schema={object().shape({
        name: string().required(),
      })}
      onSubmit={async ({ image, ...values }: FormValues) => {
        if (image && image.file) {
          const reader = new FileReader();
          reader.readAsDataURL(image.file);
          const imageDataURL = await new Promise<string>((resolve, reject) => {
            reader.onload = (e) => {
              resolve(reader.result as string);
            };
          });
          values.logo = imageDataURL.split(",")[1];
        }
        const error = await onSubmit(values);
        if (error) {
          return {
            [FORM_ERROR]: error.message,
            ...validationErrorsToObject(error.validationErrors),
          };
        }

        onClose();
        return;
      }}
      render={({
        handleSubmit,
        submitError,
        values,
        form: { change },
        submitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            {submitError && (
              <div style={{ marginBottom: 30 }}>
                <Alert type="error">{submitError}</Alert>
              </div>
            )}
            <FormTitle>
              <FormattedMessage
                id="form.label.partnerName.formTitle"
                defaultMessage="Dénomination"
              />
            </FormTitle>
            <Input fullWidth {...useInputProps({ name: "name" })} />
            <FormTitle spacing>
              <FormattedMessage
                id="form.label.logo.formTitle"
                defaultMessage="Logo"
              />
            </FormTitle>
            <Container>
              <Thumb
                single
                noTag
                image={
                  values.image && values.image.preview
                    ? values.image.preview
                    : values.hasLogo && values.logo
                    ? values.logo
                    : undefined
                }
              />
              <UploadZone
                files={
                  values.hasLogo ? [{ preview: values.logo || undefined }] : []
                }
                setFile={(file) => {
                  change("image", file);
                }}
                thumbnails={false}
                disabled={submitting}
                zoneStyle={{ flex: 1 }}
                zoneHeight={80}
              />
            </Container>
            <ModalFooter
              onClose={onClose}
              buttonType="submit"
              disabled={submitting}
            />
          </form>
        );
      }}
    />
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mediaQuery("<=", "phablet")`
    display: block;

    > div:first-child {
      margin: 0 auto 15px;
    }
  `}
`;

CardIdentityFormEdit.PartnerFragment = gql`
  fragment CardIdentityPartnerFragment on PartnerFull {
    name
    hasLogo
    logo
  }
`;
