import React from "react";
import { FormattedMessage } from "react-intl";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { Card } from "./Card";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { format } from "date-fns/esm";
import { ICouponAction } from "./types";
import illuCoupon from "../assets/illu-coupon.svg";
import dots from "../assets/dots.svg";
import { Tooltip } from "./Tooltip";

export const CardCouponsDeals = ({
  amount,
  deal,
}: {
  amount?: number;
  deal?: ICouponAction;
}) => (
  <Container>
    <Illu src={illuCoupon} alt="" />
    {!!deal ? (
      <Action>
        <ActionTitle>{deal.name || deal.ref}</ActionTitle>
        <ActionDate>
          {!!deal.availableFrom && (
            <>
              {!!deal.availableTo ? (
                <FormattedMessage
                  id="date.availableFrom"
                  defaultMessage="Valable du"
                />
              ) : (
                <FormattedMessage
                  id="date.availableSinceFrom"
                  defaultMessage="Valable à partir du"
                />
              )}{" "}
              {format(new Date(deal.availableFrom), "dd.MM.yyyy")}
            </>
          )}
          {!!deal.availableTo && (
            <>
              {!deal.availableFrom ? (
                <FormattedMessage
                  id="date.availableTo"
                  defaultMessage="Valable jusqu'au"
                />
              ) : (
                <FormattedMessage id="date.to" defaultMessage="au" />
              )}{" "}
              {format(new Date(deal.availableTo), "dd.MM.yyyy")}
            </>
          )}
        </ActionDate>
      </Action>
    ) : (
      <ActionsCounter>
        <Amount>{typeof amount !== "undefined" ? amount : "-"}</Amount>
        <Tooltip
          arrow
          content={
            <FormattedMessage
              id="website.coupons.actions.tooltip"
              defaultMessage="Actions entrainant un trafic de consommateurs vers votre point de vente"
            />
          }
        >
          <div>
            <div>
              <FormattedMessage
                id="website.coupons.actions"
                defaultMessage="Actions"
              />
            </div>
            <div>
              <FormattedMessage
                id="website.coupons.available"
                defaultMessage="disponibles"
              />
            </div>
          </div>
        </Tooltip>
      </ActionsCounter>
    )}
  </Container>
);

const Container = styled(Card)`
  background: ${colors.primary};
  color: #fff;
  display: flex;
  align-items: center;
`;

const ActionsCounter = styled.div`
  ${mediaQuery(">", "640px")`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
  `}

  ${mediaQuery("<=", "phablet")`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
  `}

  ${mediaQuery(">", "tablet")`
    padding-left: 10%;
    margin-left: 10%;
    border-left: 4px solid pink;
    border-image-source: url(${dots});
    border-image-slice: 6 4;
    border-image-repeat: round;
  `}
`;

const Amount = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("50px")}
  color: #ffffff;
  letter-spacing: -0.5px;
  margin-right: 20px;
  text-shadow: 5px 5px 0 ${colors.primaryDk};
  font-size: 50px;
`;

const Illu = styled.img`
  ${mediaQuery("<=", "tablet")`
    display: none;
  `}
`;

const Action = styled.div`
  margin-left: 30px;
`;

const ActionTitle = styled.div`
  ${fontFamily(ffdinPro.bold)}
`;

const ActionDate = styled.div`
  ${fontSize("14px")}
  margin-top: 10px;
`;
