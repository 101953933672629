import * as React from "react";
import styled from "styled-components";
const SvgVet12 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M50 65.62V75a8.33 8.33 0 008.33 8.33h8.33A8.33 8.33 0 0075 75v-8.33a8.33 8.33 0 1116.67 0V75a25 25 0 01-25 25h-8.34a25 25 0 01-25-25v-9.38a33.35 33.35 0 01-25-32.28v-25A8.33 8.33 0 0125 8.33v25a16.67 16.67 0 1033.33 0v-25a8.33 8.33 0 0116.67 0v25a33.35 33.35 0 01-25 32.29z" />
  </svg>
);
export default styled(SvgVet12)``;
