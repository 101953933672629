import React, { Fragment, useState } from "react";
import { LocaleValue, locales } from "@royalcanin-be-partner-portal/settings";
import { useInputProps } from "./Form";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { Textarea } from "./Textarea";
import { Link } from "./Link";
import { IconCross10, IconPlus10 } from "./icons-ts";
import { colors } from "./lib/constants";
import styled from "styled-components";
import posed from "react-pose";
import { fontFamily, ffdinPro } from "./lib/fonts";

export const messages = defineMessages({
  title: {
    id: "informations.desc.title",
    defaultMessage: "Description",
  },
  ["lang.fr"]: {
    id: "language.fr",
    defaultMessage: "Français",
  },
  ["lang.nl"]: {
    id: "language.nl",
    defaultMessage: "Néerlandais",
  },
  ["descriptionIn.fr"]: {
    id: "description.in.fr",
    defaultMessage: "Description en français",
  },
  ["descriptionIn.nl"]: {
    id: "description.in.nl",
    defaultMessage: "Description en néerlandais",
  },
});

export interface IInputDescriptionValues {
  descriptionByLang: { [lang: string]: string | null };
}

export const InputDescription = ({ locale }: { locale: LocaleValue }) => {
  const { formatMessage } = useIntl();
  const [showOtherInputs, setShowOtherInputs] = useState(false);
  const languages = [locale].concat(
    locales.filter(lang => lang.toLowerCase() !== locale.toLowerCase()),
  );

  return (
    <>
      {languages.map((lang, i) => {
        const inputProps = useInputProps({
          name: `descriptionByLang.${lang}`,
        });

        const boxTitle = `${formatMessage(messages.title)} (${formatMessage(
          messages[`lang.${lang}` as keyof typeof messages],
        )})`;

        if (i === 0) {
          return (
            <BoxTextarea key={i} title={boxTitle} inputProps={inputProps} />
          );
        }
        return (
          <Fragment key={i}>
            <SlideToggle pose={!showOtherInputs ? "on" : "off"}>
              <Add
                borderless
                onClick={() => setShowOtherInputs(true)}
                prefix={<IconPlus10 fill={colors.primary} width={10} height={10} />}
              >
                {formatMessage(
                  messages[`descriptionIn.${lang}` as keyof typeof messages],
                )}
              </Add>
            </SlideToggle>
            <SlideToggle pose={showOtherInputs ? "on" : "off"}>
              <BoxTextarea
                title={boxTitle}
                inputProps={inputProps}
                header={
                  <div onClick={() => setShowOtherInputs(false)}>
                    <Link borderless prefix={<IconCross10 width={10} height={10} />}>
                      <FormattedMessage
                        id="button.label.delete"
                        defaultMessage="Supprimer"
                      />
                    </Link>
                  </div>
                }
                style={{ marginTop: 30 }}
              />
            </SlideToggle>
          </Fragment>
        );
      })}
    </>
  );
};

const BoxTextarea = ({
  title,
  inputProps,
  header,
  style,
}: {
  title: string;
  inputProps: any;
  header?: JSX.Element;
  style?: React.CSSProperties;
}) => (
  <div style={style}>
    <Header>
      <Title>{title}</Title>
      {header}
    </Header>
    <Textarea {...inputProps} />
  </div>
);

const Add = styled(Link)`
  margin-top: 20px;
`;

const SlideToggle = posed.div({
  off: {
    height: 0,
    opacity: 0,
    overflow: "hidden",
  },
  on: {
    height: "auto",
    opacity: 1,
  },
});

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Title = styled.div`
  ${fontFamily(ffdinPro.bold)}
`;
