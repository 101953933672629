import {
  defaultLocale,
  ILocale,
  locales,
  LocaleValue,
} from "@royalcanin-be-partner-portal/settings";
import acceptLanguage from "accept-language";
import { flatten } from "lodash";
import React, { createContext, ReactNode, useContext, useState } from "react";
import { IntlProvider } from "react-intl";
import { setLocale as setYupLocale } from "yup";
import * as yupLocales from "yup-locales";
import dataTranslations from "../../../datastore/data/translations.json";

const determineLocale = (): LocaleValue | ILocale => {
  if (typeof window === "undefined") {
    return defaultLocale;
  }

  const supportedLanguages = flatten([
    window.navigator.languages,
    window.navigator.language,
  ]).join(";");

  acceptLanguage.languages(locales);

  const savedLocale = getSavedLocale();

  return (
    savedLocale ||
    (acceptLanguage.get(supportedLanguages) as LocaleValue) ||
    locales[0]
  );
};

const saveLocale = (locale: LocaleValue) => {
  if (typeof window === "undefined") {
    return;
  }
  try {
    window.localStorage.setItem("locale", locale);
  } catch (error) {
    console.error(error);
  }
};

const getSavedLocale = () => {
  if (typeof window === "undefined") {
    return;
  }
  try {
    return window.localStorage.getItem("locale") as LocaleValue;
  } catch (error) {
    console.error(error);
  }
  return;
};

const ctx = createContext<{
  locale: LocaleValue | ILocale;
  setLocale: (locale: LocaleValue) => void;
}>({
  locale: determineLocale(),
  setLocale: () => {},
});

export const LocaleProvider = ({ children }: { children: ReactNode }) => {
  const [state, setLocale] = useState({
    locale: determineLocale(),
    setLocale: (locale: LocaleValue) => {
      setLocale({ ...state, locale });
      saveLocale(locale);
      if (yupLocales[locale]) {
        setYupLocale(yupLocales[locale]);
      }
    },
  });

  if (yupLocales[state.locale]) {
    setYupLocale(yupLocales[state.locale]);
  }

  return (
    <ctx.Provider value={state}>
      <IntlProvider
        locale={state.locale}
        defaultLocale={defaultLocale}
        messages={dataTranslations.reduce((values, record) => {
          return {
            ...values,
            [record.id]: (record.value && record.value[state.locale]) || "",
          };
        }, {})}
      >
        {children}
      </IntlProvider>
    </ctx.Provider>
  );
};

export const useLocale = (): LocaleValue => {
  const { locale } = useContext(ctx);
  return locale;
};

export const useSetLocale = () => {
  const { setLocale } = useContext(ctx);
  return setLocale;
};
