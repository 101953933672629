import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider, ThemeProvider } from "@material-ui/styles";
import frLocale from "date-fns/locale/fr";
import React from "react";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { styledFlexboxGrid } from "./lib/constants";
import { muiTheme } from "./lib/muiTheme";

const StyledThemeProvider = (props: { children: React.ReactChild }) => (
  <SCThemeProvider theme={{ flexboxgrid: styledFlexboxGrid }} {...props} />
);

export const UIKitProvider = ({ children }: { children: React.ReactNode }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
    <StylesProvider injectFirst>
      <StyledThemeProvider>
        <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
      </StyledThemeProvider>
    </StylesProvider>
  </MuiPickersUtilsProvider>
);
