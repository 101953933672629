import React from "react";
import {
  IconBreeder12,
  IconShop12,
  IconVet12,
  IconVet16,
  IconShop16,
  IconBreeder16,
} from "./icons-ts";
import styled from "styled-components";
import { colors } from "./lib/constants";

interface IIconProps {
  height?: number;
  width?: number;
  radius?: number;
  background?: string;
  color?: string;
  style?: React.CSSProperties;
}

// vets | shops | breeders | clubs | SHELTERS
export const IconPartnerType = ({
  type,
  color = "#fff",
  iconWidth,
  ...props
}: { type?: string; iconWidth?: 12 | 16 } & IIconProps) => (
  <Icon className="iconPartnerType" {...props}>
    {type === "vets" && <IconVet width={iconWidth} fill={color} />}
    {type === "shops" && <IconShop width={iconWidth} fill={color} />}
    {type === "breeders" && <IconBreeder width={iconWidth} fill={color} />}
  </Icon>
);

const Icon = styled.div<IIconProps>`
  height: ${p => p.height || 30}px;
  width: ${p => p.width || 30}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${p => p.background || colors.darkDk};
  border-radius: ${p => p.radius || 15}px;
  transition: background 0.2s;
`;

const IconVet = ({ width, fill }: { width?: number; fill: string }) =>
  width === 16 ? (
    <IconVet16 width={16} height={21} fill={fill} />
  ) : (
    <IconVet12 width={12} height={16} fill={fill} />
  );

const IconShop = ({ width, fill }: { width?: number; fill: string }) =>
  width === 16 ? (
    <IconShop16 width={16} height={16} fill={fill} />
  ) : (
    <IconShop12 width={12} height={12} fill={fill} />
  );

const IconBreeder = ({ width, fill }: { width?: number; fill: string }) =>
  width === 16 ? (
    <IconBreeder16 width={16} height={16} fill={fill} />
  ) : (
    <IconBreeder12 width={12} height={12} fill={fill} />
  );
