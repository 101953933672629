import React from "react";
import { FormattedMessage } from "react-intl";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { Link } from "./Link";
import { IReactChildren } from "./types";

export const DelegateInfoMail = ({
  children,
}: {
  children: React.ReactNode;
}) => <Email>{children}</Email>;

export interface IDelegateInfoProps {
  title: IReactChildren;
  linkUrl?: string;
  children: React.ReactNode;
}

export const DelegateInfo = ({
  title,
  linkUrl,
  children,
  ...props
}: IDelegateInfoProps) => (
  <Container {...props}>
    <Content>
      <Header>
        <Title>{title}</Title>
        <DelegateInfoHeaderContent>{children}</DelegateInfoHeaderContent>
      </Header>
      {linkUrl && (
        <Contact>
          <Link href={linkUrl}>
            <FormattedMessage
              id="information.delegate.contact"
              defaultMessage="Contact"
            />
          </Link>
        </Contact>
      )}
    </Content>
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  flex: 1;
  align-items: flex-start;
  display: flex;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-right: 20px;
`;

const Title = styled.div`
  ${fontSize("16px")}
  ${fontFamily(ffdinPro.bold)}
  margin-bottom: 10px;
`;

const Email = styled.div`
  ${fontSize("14px")}
  color: ${colors.dark};
`;

const Contact = styled.div`
  text-align: right;
  flex: 1;

  ${mediaQuery("<=", "1280px")`
    margin-top: 10px;
  `}
`;

const DelegateInfoHeaderContent = styled.div`
  text-transform: capitalize;
`;
