import {
  TextField,
  InputBase,
  MenuItem,
  TextFieldProps,
} from "@material-ui/core";
import Input, { InputProps } from "@material-ui/core/Input";
import Select, { SelectProps } from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import React, {
  Children,
  createContext,
  ReactNode,
  useContext,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import posed from "react-pose";
import styled, { css } from "styled-components";
import { Button } from "./Button";
import { IDropdownItem } from "./Dropdown";
import { FiltersMobilePanel } from "./FiltersMobilePanel";
import { IconArrowB10, IconCalendar16 } from "./icons-ts";
import { useLayoutContext } from "./Layout";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { Media } from "./lib/media";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

const Label = styled.div`
  ${fontSize("16px")}
  ${fontFamily(ffdinPro.medium)}
  margin-right: 15px;
`;

const InputContainerBase = styled.div<{ isMobile: boolean }>`
  ${(p) =>
    !p.isMobile &&
    css`
      display: flex;
      align-items: center;
    `}
`;

const InputContainer = (props: { children: ReactNode }) => (
  <InputContainerBase
    isMobile={useContext(filtersBarContext).isMobile}
    {...props}
  />
);

export const FiltersBarInputDate = ({
  label,
  onChange,
  value,
}: {
  label: string | JSX.Element;
  onChange: (date: MaterialUiPickersDate) => void;
  value?: ParsableDate;
}) => {
  const ctx = useContext(filtersBarContext);

  return (
    <InputContainer>
      <Label>{label}</Label>
      <DatePicker
        orientation="portrait"
        fullWidth
        format="dd.MM.yyyy"
        onChange={onChange}
        value={value}
        animateYearScrolling
        TextFieldComponent={ctx.isMobile ? TextField : (BlankInput as any)}
        {...({
          endAdornment: (
            <IconCalendar16 fill={colors.darkDk} width={16} height={16} />
          ),
        } as any)}
      />
    </InputContainer>
  );
};
export const FiltersBarInput = React.forwardRef(
  (
    {
      IconComponent,
      helperText,
      ...props
    }: {
      IconComponent: any;
      helperText?: any;
    } & InputProps,
    ref,
  ) => {
    const ctx = useContext(filtersBarContext);

    return (
      <>
        {ctx.isMobile ? (
          <TextField
            inputRef={ref}
            fullWidth
            InputProps={{
              ...props,
              startAdornment: (
                <IconComponent
                  style={{ marginRight: "15px" }}
                  fill={colors.darkDk}
                  width={16}
                  height={16}
                />
              ),
            }}
          />
        ) : (
          <Input
            inputRef={ref}
            fullWidth
            disableUnderline
            {...props}
            startAdornment={
              <IconComponent
                style={{ marginRight: "15px" }}
                fill={colors.darkDk}
                width={16}
                height={16}
              />
            }
          />
        )}
      </>
    );
  },
);

const BlankInput = withStyles({
  input: {
    "&:focus": {
      background: "transparent",
    },
  },
})(
  ({
    helperText,
    margin,
    onKeyDown,
    onKeyUp,
    InputProps: input,
    ...props
  }: Omit<TextFieldProps, "classes">) => <InputBase {...props} />,
);

const SelectIconComponent = () => (
  <div style={{ flexShrink: 0 }}>
    <IconArrowB10 fill={colors.darkDk} height={10} width={23} />
  </div>
);

export const FiltersBarSelect = ({
  label,
  items,
  ...props
}: {
  label: React.ReactNode;
  items: IDropdownItem<any>[];
} & SelectProps) => {
  const ctx = useContext(filtersBarContext);

  return (
    <InputContainer>
      <Label style={{ marginBottom: ctx.isMobile ? 10 : "" }}>{label}</Label>
      <Select
        fullWidth
        IconComponent={SelectIconComponent}
        {...props}
        {...(!ctx.isMobile && {
          input: <BlankInput />,
          label,
        })}
      >
        {items.map((item, i) => (
          <MenuItem key={i} value={item.value as any}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </InputContainer>
  );
};

const Container = styled.div`
  background: #fff;
  height: 50px;
  border-radius: 25px;
  border: 1px solid ${colors.lightLt};
  display: flex;
  align-items: center;
  padding: 0 5px 0 30px;
`;

const Submit = styled.div<{ isMobile: boolean }>`
  ${(p) =>
    !p.isMobile &&
    css`
      margin-left: auto;
      padding-left: 30px;
    `}
  ${(p) =>
    p.isMobile &&
    css`
      margin-top: 30px;
    `}
`;

const Item = styled.div<{ isMobile: boolean }>`
  flex: 1;

  ${(p) =>
    !p.isMobile &&
    css`
      &:not(:first-child) {
        margin-left: 30px;
        padding-left: 30px;
        border-left: 1px solid ${colors.lightLt};
      }
    `}

  ${(p) =>
    p.isMobile &&
    css`
      margin-top: 30px;
    `}
`;

const AnimButton = posed.div({
  off: {
    height: 0,
    opacity: 0,
    overflow: "hidden",
  },
  on: {
    height: "auto",
    opacity: 1,
  },
});

const FiltersBarMobile = ({
  hasScroll,
  isFooterVisible,
  children,
}: {
  hasScroll: boolean;
  isFooterVisible: boolean;
  children: React.ReactNode;
}) => {
  const [filtersMobileIsOpen, setFiltersMobileIsOpen] = useState(false);

  return (
    <AnimButton pose={!isFooterVisible || !hasScroll ? "on" : "off"}>
      <FiltersMobilePanel
        hasScroll={hasScroll}
        filtersMobileIsOpen={filtersMobileIsOpen}
        setFiltersMobileIsOpen={setFiltersMobileIsOpen}
      >
        {children}
      </FiltersMobilePanel>
    </AnimButton>
  );
};

const TitleMobile = styled.div`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("18px")}
  color: ${colors.darkDk};
`;

const filtersBarContext = createContext({ isMobile: false });

export const FiltersBar = ({
  children,
  buttonSubmitFilter,
}: {
  children: ReactNode[];
  buttonSubmitFilter?: boolean;
}) => {
  const { hasScroll, isFooterVisible } = useLayoutContext();
  return (
    <>
      <Media when={"desktop"}>
        <filtersBarContext.Provider value={{ isMobile: false }}>
          <Container>
            {Children.map(children, (item, i) => (
              <Item key={i} isMobile={false}>
                {item}
              </Item>
            ))}
            {!!buttonSubmitFilter && (
              <Submit isMobile={false}>
                <Button type="submit">
                  <FormattedMessage
                    id="website.filters.submit"
                    defaultMessage="Filtrer"
                  />
                </Button>
              </Submit>
            )}
          </Container>
        </filtersBarContext.Provider>
      </Media>
      <Media when={"tablet"}>
        <filtersBarContext.Provider value={{ isMobile: true }}>
          <FiltersBarMobile
            isFooterVisible={isFooterVisible}
            hasScroll={hasScroll}
          >
            <>
              <TitleMobile>
                <FormattedMessage
                  id="website.filters.mobile.title"
                  defaultMessage="Filtrer la recherche"
                />
              </TitleMobile>
              {Children.map(children, (item, i) => (
                <Item key={i} isMobile={true}>
                  {item}
                </Item>
              ))}
              {!!buttonSubmitFilter && (
                <Submit isMobile={true}>
                  <Button fullwidth type="submit">
                    <FormattedMessage
                      id="website.filters.submit"
                      defaultMessage="Filtrer"
                    />
                  </Button>
                </Submit>
              )}
            </>
          </FiltersBarMobile>
        </filtersBarContext.Provider>
      </Media>
    </>
  );
};
