import * as React from "react";
import styled from "styled-components";
const SvgClub16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M25 12.5v25a25 25 0 0050 0v-25zm31.25 62v13H75a6.25 6.25 0 010 12.5H25a6.25 6.25 0 010-12.5h18.75v-13A37.55 37.55 0 0114.63 50H12.5A12.5 12.5 0 010 37.5V25a12.5 12.5 0 0112.5-12.5V6.25A6.25 6.25 0 0118.75 0h62.5a6.25 6.25 0 016.25 6.25v6.25A12.5 12.5 0 01100 25v12.5A12.5 12.5 0 0187.5 50h-2.13a37.55 37.55 0 01-29.12 24.48z" />
  </svg>
);
export default styled(SvgClub16)``;
