import React, { useState } from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { Col, Row } from "react-styled-flexboxgrid";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { FiltersBar, FiltersBarSelect, FiltersBarInput } from "./FiltersBar";
import { ProductsListItem } from "./ProductsListItem";
import { Wrapper } from "./Wrapper";
import { IconMagnify16 } from "./icons-ts";
import escapeStringRegexp from "escape-string-regexp";

const messages = defineMessages({
  search: {
    id: "website.resources.filters.search",
    defaultMessage: "Que cherchez-vous ?",
  },
  allSections: {
    id: "resources.label.allSections",
    defaultMessage: "Toutes les sections",
  },
});

export const ProductsList = ({
  products,
  categories,
  onCategoryChange,
  categoryId,
}: {
  products: React.ComponentProps<typeof ProductsListItem>["item"][];
  categories: { label: string; value: string }[];
  onCategoryChange: (categoryId?: string) => void;
  categoryId?: string;
}) => {
  const { formatMessage } = useIntl();
  const [search, setSearch] = useState("");

  const productsFiltered = products.filter(product => {
    if (!search) {
      return true;
    }

    const rgx = new RegExp(`${escapeStringRegexp(search.trim())}`, "i");
    return rgx.test(product.name);
  });

  return (
    <Wrapper>
      <FiltersBar>
        <FiltersBarSelect
          label={
            <FormattedMessage
              id="website.resources.filters.section"
              defaultMessage="Section"
            />
          }
          items={[
            {
              label: formatMessage(messages.allSections),
              value: "all",
            },
            ...categories,
          ]}
          name="productCategory"
          value={categoryId || "all"}
          onChange={e => {
            onCategoryChange(e.target.value as string | undefined);
          }}
        />
        <FiltersBarInput
          IconComponent={IconMagnify16}
          placeholder={formatMessage(messages.search)}
          value={search}
          onChange={e => setSearch(e.currentTarget.value)}
        />
      </FiltersBar>
      <Container>
        <Row>
          {productsFiltered.map((item, i) => (
            <ColItem
              key={i}
              xs={12}
              sm={6}
              md={4}
              lg={2}
              style={{ display: "flex" }}
            >
              <ProductsListItem item={item} />
            </ColItem>
          ))}
        </Row>
      </Container>
    </Wrapper>
  );
};

const Container = styled.div`
  margin-top: 30px;
`;

const ColItem = styled(Col)`
  ${mediaQuery(">", "960px")`
     &:not(:nth-child(-n + 6)) {
    margin-top: 30px;
  }
  `}

  ${mediaQuery("<=", "960px")`
    &:not(:nth-child(-n + 3)) {
      margin-top: 30px;
    }
  `}

  ${mediaQuery("<=", "tablet")`
    &:not(:nth-child(-n + 2)) {
      margin-top: 30px;
    }
  `}

  ${mediaQuery("<=", "mobile")`
    &:not(:nth-child(-n + 1)) {
      margin-top: 30px;
    }
  `}
`;
