import React from "react";
import { Form } from "./Form";
import { ModalFooter } from "./ModalFooter";
import { IUploadZoneFile, UploadZone } from "./UploadZone";
import gql from "graphql-tag";
import { IErrorResult, validationErrorsToObject } from "./lib/validationUtils";
import {
  CardPicturesPartnerFragment,
  CardPicturesPartnerFragment_pictures,
} from "./__generated__/CardPicturesPartnerFragment";
import { OmitRecursively } from "./types";
import { FORM_ERROR } from "final-form";
import { Alert } from "./Alert";

type CardPicturesFormEditValues = OmitRecursively<
  CardPicturesPartnerFragment,
  "__typename"
>;

export const CardPicturesFormEdit = ({
  onClose,
  onSubmit,
  initialValues,
  disabled,
}: {
  onClose: () => void;
  onSubmit: (values: {
    pictures: (Pick<CardPicturesPartnerFragment_pictures, "content"> &
      IUploadZoneFile)[];
  }) => Promise<IErrorResult | null | void>;
  initialValues: CardPicturesFormEditValues | undefined;
  disabled?: boolean;
}) => (
  <Form
    initialValues={{
      images: (initialValues?.pictures || []).map(({ id, content }) => ({
        id,
        preview: content,
      })) as IUploadZoneFile[],
    }}
    onSubmit={async ({ images }: { images: IUploadZoneFile[] }) => {
      const error = await onSubmit({
        pictures: images.map((img) => ({
          ...img,
          content: img.preview as string,
        })),
      });
      if (error) {
        return {
          [FORM_ERROR]: error.message,
          ...validationErrorsToObject(error.validationErrors),
        };
      }

      onClose();
      return;
    }}
    render={({
      handleSubmit,
      submitError,
      values,
      form: { change },
      submitting,
    }) => (
      <form onSubmit={handleSubmit}>
        {submitError && (
          <div style={{ marginBottom: 30 }}>
            <Alert type="error">{submitError}</Alert>
          </div>
        )}

        <UploadZone
          files={values.images}
          maxFiles={15}
          setFiles={(files) => {
            change("images", files);
          }}
          onDelete={(files) => {
            change(
              "images",
              values.images.filter((f) => {
                const fileToDelete = files.find(({ id, preview }) => {
                  if (f.id) {
                    return f.id === id;
                  }
                  return f.preview === preview;
                });
                return !fileToDelete;
              }),
            );
          }}
          thumbnailItemWrap={5}
        />
        <ModalFooter
          onClose={onClose}
          buttonType={"submit"}
          disabled={disabled || submitting}
        />
      </form>
    )}
  />
);

CardPicturesFormEdit.PartnerFragment = gql`
  fragment CardPicturesPartnerFragment on PartnerFull {
    pictures {
      id
      content
    }
  }
`;
