import React, { useEffect } from "react";
import { slide as Menu, Styles as MenuStyles } from "react-burger-menu";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Button } from "./Button";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { Link } from "./Link";
import { PartnerSelector } from "./PartnerSelector";
import { INavLink } from "./types";
import { IconUser20 } from "./icons-ts";

export const bmStyles: MenuStyles = {
  bmBurgerBars: {},
  bmBurgerButton: {},
  bmCross: {},
  bmCrossButton: {},
  bmItemList: {
    display: "flex",
    flexDirection: "column",
  },
  bmMenuWrap: {
    transitionDuration: "400ms",
    top: "61px",
    height: "calc(100% - 61px)",
  },
  bmMenu: {
    background: "#fff",
  },
  bmMorphShape: {},
  bmOverlay: {
    background: "rgba(255, 255, 255, 0.8)",
    top: "61px",
  },
};

export const Header = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("16px")}
  background: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(51, 51, 51, 0.1);
  padding: 20px;
  position: relative;
  text-align: center;
  z-index: 2s;
`;

export const NavLink = styled(Link)`
  padding: 20px;
  display: flex;
  text-decoration: none;
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
  align-items: center;
  color: ${colors.darkDk};
  border-bottom: 1px solid ${colors.lightLt};

  &:focus {
    outline: none !important;
  }
`;

export const Icon = styled.div`
  margin-right: 20px;
  display: block;
`;

const Delegate = styled.div`
  margin-top: 20px;
  padding: 0 20px;
`;

const LanguagesContainer = styled.div`
  display: flex;
  padding: 20px;
  background: ${colors.lightLter};
  justify-content: space-around;
  margin-top: auto;
`;

export const Languages = ({
  locale,
  onLocaleChange,
}: {
  locale: string;
  onLocaleChange: (locale: any) => void;
}) => (
  <LanguagesContainer>
    {[
      { label: "Français", code: "fr" },
      { label: "Nederlands", code: "nl" },
    ].map(({ label, code }, i) => (
      <Link
        key={i}
        borderless
        onClick={() => {
          onLocaleChange(code);
        }}
        dark={locale === code}
        light={locale !== code}
      >
        {label}
      </Link>
    ))}
  </LanguagesContainer>
);

export const LayoutNavigationMobile = ({
  isOpen,
  onClose,
  onCloseMenu,
  openPartnerModal,
  navLinks,
  locale,
  onLocaleChange,
  partner,
  onClickLogout,
  onOpenMyCredentials,
}: {
  isOpen: boolean;
  onClose: (e: React.KeyboardEvent) => void;
  onCloseMenu: () => void;
  openPartnerModal: () => void;
  navLinks: INavLink[];
  locale: string;
  onLocaleChange: (locale: any) => void;
  partner?: {
    type: string;
    name: string | null;
  };
  onClickLogout: () => void;
  onOpenMyCredentials: () => void;
}) => {
  // Remove body scroll if menu is Open
  useEffect(() => {
    isOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "");
  }, [isOpen]);

  return (
    <Menu
      customOnKeyDown={onClose}
      customCrossIcon={false}
      customBurgerIcon={false}
      width={"100%"}
      styles={bmStyles}
      isOpen={isOpen}
    >
      <Header>
        <FormattedMessage id="website.nav.mobile.title" defaultMessage="Menu" />
      </Header>
      <PartnerSelector
        type={partner && partner.type.toLowerCase()}
        label={partner && partner.name}
        onClick={openPartnerModal}
      />
      {navLinks.map((item, i) => (
        <div key={i}>
          <NavLink href={item.href}>
            <Icon>{item.icon}</Icon>
            <div>{item.label}</div>
          </NavLink>
        </div>
      ))}
      <div>
        <NavLink
          onClick={() => {
            onCloseMenu();
            onOpenMyCredentials();
          }}
        >
          <Icon>
            <IconUser20 width={20} height={22} fill={colors.darkDk} />
          </Icon>
          <div>
            <FormattedMessage
              id="userPanel.nav.myProfile"
              defaultMessage="Mon profil"
            />
          </div>
        </NavLink>
      </div>
      <Delegate>
        <Button theme="bordered" fullwidth to="/contact">
          <FormattedMessage
            id="website.header.delegate"
            defaultMessage="Mon délégué"
          />
        </Button>
      </Delegate>
      <Logout>
        <Link
          dark
          onClick={(e) => {
            e.preventDefault();
            onClickLogout();
          }}
        >
          <FormattedMessage
            id="button.label.logout"
            defaultMessage="Déconnexion"
          />
        </Link>
      </Logout>
      <Languages locale={locale} onLocaleChange={onLocaleChange} />
    </Menu>
  );
};

const Logout = styled.div`
  text-align: center;
  margin: 15px 0;
`;
