import * as React from "react";
import styled from "styled-components";
const SvgNews16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M56.25 18.75a6.25 6.25 0 010 12.5H25a6.25 6.25 0 010-12.5zm0 18.75a6.25 6.25 0 010 12.5H25a6.25 6.25 0 010-12.5zM50 56.25a6.25 6.25 0 010 12.5H25a6.25 6.25 0 010-12.5zM68.75 12.5H12.06l.44 75.44 75.44-.44-.44-60.81h-6.25V75a6.25 6.25 0 01-12.5 0zm0-12.5a12.81 12.81 0 0112.5 12.5v2.06h6.69A12.13 12.13 0 01100 26.69v61.25A12.12 12.12 0 0187.94 100H12.06A12.12 12.12 0 010 87.94V12.06A12.12 12.12 0 0112.06 0z" />
  </svg>
);
export default styled(SvgNews16)``;
