import React from "react";
import { FormattedMessage } from "react-intl";
import { Table, CellMainInfo, Row } from "./LittersListItems";
import { Button } from "./Button";
import { Wrapper } from "./Wrapper";
import { colors } from "./lib/constants";
import { fontFamily, ffdinPro } from "./lib/fonts";
import styled from "styled-components";

type ILitterIncoming = Omit<
  React.ComponentProps<typeof CellMainInfo>,
  "breedName"
> & {
  id: string;
  breedCode?: string;
  type: string;
};

export const LittersListIncoming = ({
  litters,
  onCompleteClick,
  loading,
  BreedNameComponent,
}: {
  litters: ILitterIncoming[];
  onCompleteClick: React.ComponentProps<typeof Litter>["onCompleteClick"];
  loading?: boolean;
  BreedNameComponent: React.ComponentProps<typeof Litter>["BreedNameComponent"];
}) => (
  <Wrapper>
    {loading || !litters ? (
      <Table />
    ) : (
      <Table>
        <tbody>
          {litters.map((item, i) => (
            <Litter
              key={i}
              litter={item}
              onCompleteClick={onCompleteClick}
              BreedNameComponent={BreedNameComponent}
            />
          ))}
        </tbody>
      </Table>
    )}
  </Wrapper>
);

interface BreedNameComponentProps {
  breedCode?: string;
  type?: string;
}

const Litter = ({
  litter,
  onCompleteClick,
  BreedNameComponent,
}: {
  litter: ILitterIncoming;
  onCompleteClick: (litter: ILitterIncoming) => void;
  BreedNameComponent?:
    | React.ComponentClass<BreedNameComponentProps>
    | React.FunctionComponent<BreedNameComponentProps>;
}) => (
  <Row>
    <CellMainInfo
      {...litter}
      breedName={
        BreedNameComponent
          ? React.createElement(BreedNameComponent, {
              breedCode: litter.breedCode,
              type: litter.type,
            })
          : ""
      }
    />
    <CellMessage>
      <FormattedMessage
        id="litters.message.completeForPublish"
        defaultMessage="Complétez cette portée pour commandez vos kits et/ou publiez votre annonce"
      />
    </CellMessage>
    <td style={{ width: 1 }}>
      <Button onClick={() => onCompleteClick(litter)}>
        <FormattedMessage
          id="button.label.complete"
          defaultMessage="Compléter"
        />
      </Button>
    </td>
  </Row>
);

const CellMessage = styled.td`
  color: ${colors.warning};
  ${fontFamily(ffdinPro.medium)}
`;
