import * as React from "react";
import styled from "styled-components";
const SvgMinus10 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M90 40a10 10 0 010 20H10a10 10 0 010-20z" />
  </svg>
);
export default styled(SvgMinus10)``;
