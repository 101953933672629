import * as React from "react";
import styled from "styled-components";
const SvgDesktop16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M87.5 56.25a6.25 6.25 0 01-6.25 6.25h-62.5a6.25 6.25 0 01-6.25-6.25v-37.5a6.25 6.25 0 016.25-6.25h62.5a6.25 6.25 0 016.25 6.25zM59.69 87.5H40.31A24.44 24.44 0 0043.75 75h12.5a24.44 24.44 0 003.44 12.5zM81.25 0h-62.5A18.75 18.75 0 000 18.75v37.5A18.75 18.75 0 0018.75 75h12.5a13 13 0 01-12.5 12.5 6.25 6.25 0 000 12.5h62.5a6.25 6.25 0 000-12.5A13 13 0 0168.75 75h12.5A18.75 18.75 0 00100 56.25v-37.5A18.75 18.75 0 0081.25 0z" />
  </svg>
);
export default styled(SvgDesktop16)``;
