import * as React from "react";
import styled from "styled-components";
const SvgUpload16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M0 68.75a6.25 6.25 0 0112.5 0V75A12.5 12.5 0 0025 87.5h50A12.5 12.5 0 0087.5 75v-6.25a6.25 6.25 0 0112.5 0V75a25 25 0 01-25 25H25A25 25 0 010 75zm52.12 5.82a6.25 6.25 0 01-4.5 0l-1.69-.94-18.75-12.5A6.25 6.25 0 0135 51.38l8.63 4.37V6.25a6.25 6.25 0 0112.5 0v49.5l8.56-4.31a6.25 6.25 0 117.81 9.75l-18.76 12.5a6.25 6.25 0 01-.94.5 6.25 6.25 0 01-.68.38z" />
  </svg>
);
export default styled(SvgUpload16)``;
