import * as React from "react";
import styled from "styled-components";
const SvgArrowB16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path
      d="M98 39.68L54.85 70a6.88 6.88 0 01-9.71 0L2 39.68A6.85 6.85 0 0111.72 30L50 55.48 88.28 30A6.88 6.88 0 0198 30a6.83 6.83 0 010 9.68z"
      fillRule="evenodd"
    />
  </svg>
);
export default styled(SvgArrowB16)``;
