import React from "react";
import ContentLoader from "react-content-loader";
import { FormattedMessage } from "react-intl";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { Card } from "./Card";
import { IconRoundedCheck16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { OmitRecursively } from "./types";
import gql from "graphql-tag";
import { ProductBenefitsFragment as ProductFragment } from "./__generated__/ProductBenefitsFragment";

type IProductBenefits = OmitRecursively<ProductFragment, "__typename">;

export const CardProductsBenefits = ({
  product,
  loading,
}: {
  product?: IProductBenefits;
  loading?: boolean;
}) => {
  if (loading || !product) {
    return <ContentLoading />;
  }

  return (
    <Container>
      <Title>
        <FormattedMessage
          id="website.products.benefits"
          defaultMessage="Les avantages et bénéfices"
        />
      </Title>
      {product.benefits.map((item, i) => (
        <Benefit key={i}>
          <Icon>
            <IconRoundedCheck16 width={16} height={16} fill={colors.darkDk} />
          </Icon>
          <div key={i}>
            <BenefitTitle>{item.title}</BenefitTitle>
            <BenefitDesc>{item.description}</BenefitDesc>
          </div>
        </Benefit>
      ))}
    </Container>
  );
};

export const ProductBenefitsFragment = gql`
  fragment ProductBenefitsFragment on Collection {
    benefits {
      title
      description
    }
  }
`;

const Container = styled(Card)`
  ${mediaQuery("<=", "960px")`
    margin-top: 15px;
  `}
`;

const Title = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("19px")}
  line-height: 23px;
  margin-bottom: 30px;
`;

const Benefit = styled.div`
  display: flex;

  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const BenefitDesc = styled.div`
  line-height: 22px;
  ${fontSize("14px")}
`;

const Icon = styled.div`
  margin-right: 15px;
  flex-shrink: 0;
`;

const BenefitTitle = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
  margin-bottom: 10px;
`;

const ContentLoading = () => (
  <Container>
    {Array(2)
      .fill("")
      .map((e, i) => (
        <ContentLoader height={130} width={400} key={i}>
          <rect x="0" y="0" rx="3" ry="3" width="70" height="10" />
          <rect x="80" y="0" rx="3" ry="3" width="100" height="10" />
          <rect x="190" y="0" rx="3" ry="3" width="10" height="10" />

          <rect x="15" y="20" rx="3" ry="3" width="130" height="10" />
          <rect x="155" y="20" rx="3" ry="3" width="130" height="10" />

          <rect x="15" y="40" rx="3" ry="3" width="90" height="10" />
          <rect x="115" y="40" rx="3" ry="3" width="60" height="10" />
          <rect x="185" y="40" rx="3" ry="3" width="60" height="10" />

          <rect x="0" y="60" rx="3" ry="3" width="30" height="10" />
        </ContentLoader>
      ))}
  </Container>
);
