import * as React from "react";
import styled from "styled-components";
const SvgArrowL10 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path
      d="M56.42 3.22a10.82 10.82 0 0115.39 0 11.05 11.05 0 010 15.53L51.28 50l20.53 31.26a11.06 11.06 0 010 15.53 10.83 10.83 0 01-15.39 0l-28.23-39a11 11 0 010-15.53z"
      fillRule="evenodd"
    />
  </svg>
);
export default styled(SvgArrowL10)``;
