/* tslint:disable:no-implicit-dependencies */
import { createGlobalStyle } from "styled-components";
import { colors } from "./lib/constants";
import { ffdinPro, fontFace, fontFamily, fontSize } from "./lib/fonts";

const {
  default: normalize,
} = require("!css-loader!normalize.css/normalize.css");
const { default: tippy } = require("!css-loader!tippy.js/dist/tippy.css");
const {
  default: tippyAnim,
} = require("!css-loader!tippy.js/animations/shift-toward.css");
const {
  default: phoneNumberTheme,
} = require("!css-loader!react-phone-number-input/style.css");

export const GlobalStyles = createGlobalStyle`
  ${normalize.toString()}
  ${tippy.toString()}
  ${tippyAnim.toString()}
  ${phoneNumberTheme.toString()}
  ${fontFace(ffdinPro.regular)}
  ${fontFace(ffdinPro.medium)}
  ${fontFace(ffdinPro.bold)}
  ${fontFace(ffdinPro.condensedMedium)}
  ${fontFace(ffdinPro.condensedBold)}

  :root {
    font-size: 16px;
  }

  html,
  body {
    height: 100%;
    ${fontFamily(ffdinPro.regular)}
    ${fontSize("16px")}
    color: ${colors.darkDk};
  }

  #___gatsby,
  #___gatsby > div:first-child {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  html {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  }
`;
