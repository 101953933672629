import React from "react";
import { Link } from "./Link";
import styled from "styled-components";
import { fontFamily, fontSize } from "./lib/cssUtils";
import { ffdinPro } from "./lib/fonts";
import { IconArrowL10 } from "./icons-ts";
import { colors } from "./lib/constants";
import { FormattedMessage } from "react-intl";

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 60px;
`;

const Title = styled.div`
  ${fontSize("24px")}
  ${fontFamily(ffdinPro.bold)}
  text-align: center;
  flex: 1;
`;

export const PageHeader: React.FC<{ backLink: string }> = ({ backLink }) => (
  <Container>
    <Link
      href={backLink}
      prefix={<IconArrowL10 width={10} fill={colors.primary} />}
    >
      <FormattedMessage id="button.label.back" defaultMessage="Retour" />
    </Link>
    <Title>
      <FormattedMessage
        id="loyalty.button.exchange.points"
        defaultMessage="Échanger mes points"
      />
    </Title>
  </Container>
);
