import React from "react";
import { mediaQuery } from "react-styled-mediaquery";
import styled, { css } from "styled-components";

export type ISpacing = "xs" | "md";

const spacingCss = {
  xs: `
    padding: 30px;
  `,
  md: `
    padding: 40px;
  `,
};

const Container = styled(
  ({
    elevation,
    spacing,
    ...props
  }: {
    elevation?: boolean;
    spacing?: ISpacing;
  } & React.HTMLAttributes<HTMLDivElement>) => <div {...props} />,
)`
  background: #fff;
  border-radius: 10px;
  flex: 1;
  overflow: hidden;

  ${(p) => {
    return spacingCss[p.spacing || "xs"];
  }}

  ${mediaQuery("<=", "768px")`
    padding: 20px;
  `}

  ${(p) =>
    p.elevation &&
    css`
      background: #ffffff;
      box-shadow: 0 10px 15px -5px rgba(51, 51, 51, 0.1);
      border-radius: 10px;
    `}
`;

export const Card = ({
  className,
  children,
  elevation,
  spacing,
  containerStyles,
}: {
  className?: string;
  children: React.ReactNode;
  elevation?: boolean;
  spacing?: ISpacing;
  containerStyles?: React.HTMLAttributes<HTMLDivElement>["style"];
}) => {
  return (
    <Container
      style={containerStyles}
      spacing={spacing || "xs"}
      className={className}
      elevation={!!elevation}
    >
      {children}
    </Container>
  );
};
