import * as React from "react";
import styled from "styled-components";
const SvgPlus10 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M60 40h30a10 10 0 010 20H60v30a10 10 0 01-20 0V60H10a10 10 0 110-20h30V10a10 10 0 0120 0z" />
  </svg>
);
export default styled(SvgPlus10)``;
