import ffdinproRegular from "../../assets/fonts/ffdinpro/ffdinpro-regular.woff";
import ffdinproRegular2 from "../../assets/fonts/ffdinpro/ffdinpro-regular.woff2";
import ffdinproMedium from "../../assets/fonts/ffdinpro/ffdinpro-medium.woff";
import ffdinproMedium2 from "../../assets/fonts/ffdinpro/ffdinpro-medium.woff2";
import ffdinproBold from "../../assets/fonts/ffdinpro/ffdinpro-bold.woff";
import ffdinproBold2 from "../../assets/fonts/ffdinpro/ffdinpro-bold.woff2";
import ffdinproMediumCondensed from "../../assets/fonts/ffdinpro/ffdinpro-medium-condensed.woff";
import ffdinproMediumCondensed2 from "../../assets/fonts/ffdinpro/ffdinpro-medium-condensed.woff2";
import ffdinproBoldCondensed from "../../assets/fonts/ffdinpro/ffdinpro-bold-condensed.woff";
import ffdinproBoldCondensed2 from "../../assets/fonts/ffdinpro/ffdinpro-bold-condensed.woff2";
export { fontSize, fontFace, fontFamily } from "./cssUtils";

export const ffdinPro = {
  regular: {
    name: "FF Din Pro-regular",
    weight: "400",
    woff: ffdinproRegular,
    woff2: ffdinproRegular2,
  },

  medium: {
    name: "FF Din Pro-medium",
    weight: "500",
    woff: ffdinproMedium,
    woff2: ffdinproMedium2,
  },

  bold: {
    name: "FF Din Pro-bold",
    weight: "600",
    woff: ffdinproBold,
    woff2: ffdinproBold2,
  },

  condensedMedium: {
    name: "FF Din Pro Condensed-medium",
    weight: "500",
    woff: ffdinproMediumCondensed,
    woff2: ffdinproMediumCondensed2,
  },

  condensedBold: {
    name: "FF Din Pro Condensed-bold",
    weight: "600",
    woff: ffdinproBoldCondensed,
    woff2: ffdinproBoldCondensed2,
  },
};
