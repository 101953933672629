import React from "react";
import styled from "styled-components";
import { IconKit40 } from "./icons-ts";
import { InputNumber } from "./InputNumber";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily } from "./lib/fonts";
import { IUseInputPropsResult } from "./Form";
import { useForm } from "react-final-form";
import { FormattedMessage } from "react-intl";

export const KitsCounter = ({
  readonly,
  title,
  ...props
}: IUseInputPropsResult & {
  title: string;
  readonly?: boolean;
}) => {
  const { getState } = useForm();
  const { values } = getState();
  const maxNumberOfPack =
    parseInt(`${values?.male || "0"}`, 10) +
    parseInt(`${values?.female || "0"}`, 10);

  return (
    <KitsContainer>
      <KitsTitle>
        <Icon style={{ display: "block" }} width={40} height={26} fill={colors.darkDk} />
        <div>
          {title}
        </div>
      </KitsTitle>
      {!readonly ? (
        <InputNumber {...props} min={0} max={maxNumberOfPack} step={1} />
      ) : (
        <div>
          <FormattedMessage
            id="form.label.quantity"
            defaultMessage="Quantité"
          />{" "}
          <Highlight>{props.value}</Highlight>
        </div>
      )}
    </KitsContainer>
  );
};

const KitsContainer = styled.div`
  margin: 40px 0;
  background: ${colors.lightLter};
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const KitsTitle = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled(IconKit40)`
  margin-right: 20px;
  display: block;
`;

const Highlight = styled.span`
  ${fontFamily(ffdinPro.bold)}
`;
