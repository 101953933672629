import { LocaleValue } from "@royalcanin-be-partner-portal/settings";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { Card } from "./Card";
import { CardDescFormEdit } from "./CardDescFormEdit";
import { CardHeader } from "./CardHeader";
import { Modal } from "./Modal";
import { Placeholder } from "./Placeholder";
import { messages } from "./InputDescription";
import iconQuote from "../assets/icon-quote.svg";

export const CardDesc = ({
  locale,
  partner,
  loading,
  onSubmit,
}: {
  locale: LocaleValue;
  loading?: boolean;
  partner?: React.ComponentProps<typeof CardDescFormEdit>["initialValues"];
  onSubmit: React.ComponentProps<typeof CardDescFormEdit>["onSubmit"];
}) => {
  const [modalEditDescIsOpen, setModalEditDescIsOpen] = useState(false);
  const { formatMessage } = useIntl();

  return (
    <Container>
      <Modal
        maxWidth="m"
        // title={formatMessage(messages.title)}
        open={modalEditDescIsOpen}
        onClose={() => setModalEditDescIsOpen(false)}
      >
        <CardDescFormEdit
          locale={locale}
          onClose={() => setModalEditDescIsOpen(false)}
          initialValues={partner}
          onSubmit={onSubmit}
        />
      </Modal>
      <Card>
        <CardHeader onClick={() => setModalEditDescIsOpen(true)}>
          {formatMessage(messages.title)}
        </CardHeader>
        {loading || !partner ? (
          <Placeholder />
        ) : (
          <Desc>
            <Icon>
              <img src={iconQuote} alt="" />
            </Icon>
            <Text minChar={310}>{partner.description}</Text>
          </Desc>
        )}
      </Card>
    </Container>
  );
};

const TextEllipsis = ({
  minChar,
  children,
  className,
}: {
  className?: string;
  minChar: number;
  children: string | null;
}) => {
  let text = children || "";
  if (minChar && text.length > minChar) {
    const match = text.slice(minChar).match(/\s/);
    const index = match?.index || 0;
    text = text.slice(0, minChar + index) + "...";
  }
  return <div className={className}>{text}</div>;
};

const Text = styled(TextEllipsis)`
  line-height: 24px;
  white-space: pre-wrap;
`;

const Desc = styled.div`
  display: flex;
`;

const Icon = styled.div`
  margin-right: 20px;
`;

const Container = styled.div`
  flex: 1;
  display: flex;

  ${mediaQuery("<=", "960px")`
    margin-top: 15px;
  `}
`;
