import React from "react";
import { Card } from "./Card";
import { DelegateInfo, IDelegateInfoProps } from "./DelegateInfo";
import { Placeholder } from "./Placeholder";
import styled from "styled-components";

export type ICardDelegateProps = Pick<
  IDelegateInfoProps,
  "title" | "linkUrl"
> & { name: string; loading?: boolean };

export const CardDelegate = ({
  loading,
  title,
  linkUrl,
  name,
}: ICardDelegateProps | ({ loading: true } & Partial<ICardDelegateProps>)) => (
  <CardStyled elevation>
    <DelegateInfoStyled
      {...{
        title: loading || !title ? <Placeholder /> : title,
        linkUrl,
      }}
    >
      {loading || !name ? <Placeholder /> : name}
    </DelegateInfoStyled>
  </CardStyled>
);

const CardStyled = styled(Card)`
  display: flex;
`;

const DelegateInfoStyled = styled(DelegateInfo)`
  flex: 1;
`;
