import * as React from "react";
import styled from "styled-components";
const SvgServices16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M84.38 0a15.63 15.63 0 11-15.63 15.63A15.63 15.63 0 0184.38 0zM15.63 0A15.63 15.63 0 110 15.63 15.63 15.63 0 0115.63 0zm68.75 68.75a15.63 15.63 0 11-15.63 15.63 15.63 15.63 0 0115.63-15.63zm-68.75 0A15.63 15.63 0 110 84.38a15.62 15.62 0 0115.63-15.63z" />
  </svg>
);
export default styled(SvgServices16)``;
