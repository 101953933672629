import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { Card } from "./Card";
import { CardHeader } from "./CardHeader";
import { CardNetworksFormEdit } from "./CardNetworksFormEdit";
import {
  IconDesktop16,
  IconFacebook16,
  IconInstagram16,
  IconTwitter16,
  IconYoutube16,
} from "./icons-ts";
import { colors } from "./lib/constants";
import { Link } from "./Link";
import { Modal } from "./Modal";
import { Placeholder } from "./Placeholder";

const removeHttp = (link: string): string => {
  return link.replace(/^http(s)?:\/\/(www.)?/, "");
};

export const CardNetworks = ({
  partner,
  loading,
  onSubmit,
  validateWebsite,
}: {
  loading?: boolean;
  partner?: React.ComponentProps<typeof CardNetworksFormEdit>["initialValues"];
} & Pick<
  React.ComponentProps<typeof CardNetworksFormEdit>,
  "onSubmit" | "validateWebsite"
>) => {
  const [modalEditNetworksIsOpen, setModalEditNetworksIsOpen] = useState(false);

  return (
    <>
      <Modal
        maxWidth="m"
        title={
          <FormattedMessage
            id="informations.networks.title"
            defaultMessage="Internet"
          />
        }
        open={modalEditNetworksIsOpen}
        onClose={() => setModalEditNetworksIsOpen(false)}
      >
        <CardNetworksFormEdit
          onClose={() => setModalEditNetworksIsOpen(false)}
          initialValues={partner}
          onSubmit={onSubmit}
          validateWebsite={validateWebsite}
        />
      </Modal>
      <Container>
        <CardHeader onClick={() => setModalEditNetworksIsOpen(true)}>
          <FormattedMessage
            id="informations.networks.title"
            defaultMessage="Internet"
          />
        </CardHeader>
        {loading || !partner ? (
          <Placeholder />
        ) : (
          <>
            <Item>
              <Icon>
                <IconDesktop16 fill={colors.darkDk} width={16} height={16} />
              </Icon>
              {!!partner.website ? (
                <Link href={partner.website} target="_blank" dark>
                  {removeHttp(partner.website)}
                </Link>
              ) : (
                "-"
              )}
            </Item>
            <Item>
              <Icon>
                <IconFacebook16 fill={colors.darkDk} height={16} width={8} />
              </Icon>
              {!!partner.facebook ? (
                <Link href={partner.facebook} target="_blank" dark>
                  {removeHttp(partner.facebook)}
                </Link>
              ) : (
                "-"
              )}
            </Item>
            <Item>
              <Icon>
                <IconTwitter16 fill={colors.darkDk} width={16} height={13} />
              </Icon>
              {!!partner.twitter ? (
                <Link href={partner.twitter} target="_blank" dark>
                  {removeHttp(partner.twitter)}
                </Link>
              ) : (
                "-"
              )}
            </Item>
            <Item>
              <Icon>
                <IconInstagram16 fill={colors.darkDk} width={16} height={16} />
              </Icon>
              {!!partner.instagram ? (
                <Link href={partner.instagram} target="_blank" dark>
                  {removeHttp(partner.instagram)}
                </Link>
              ) : (
                "-"
              )}
            </Item>
            <Item>
              <Icon>
                <IconYoutube16 fill={colors.darkDk} height={14} width={16} />
              </Icon>
              {!!partner.youtube ? (
                <Link href={partner.youtube} target="_blank" dark>
                  {removeHttp(partner.youtube)}
                </Link>
              ) : (
                "-"
              )}
            </Item>
          </>
        )}
      </Container>
    </>
  );
};

const Item = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const Icon = styled.div`
  flex-shrink: 0;
  margin-right: 20px;

  > svg {
    display: block;
  }
`;

const Container = styled(Card)`
  ${mediaQuery("<=", "tablet")`
    margin-top: 15px;
  `}
`;
