import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { Button } from "./Button";
import { Card } from "./Card";
import { colors } from "./lib/constants";
import { IPromo } from "./types";
import { Wrapper } from "./Wrapper";
import { FormattedMessage } from "react-intl";
import { PageLoading } from "./PageLoading";

export const PromosList = ({
  promos = [],
  loading,
}: {
  promos?: IPromo[];
  loading?: boolean;
}) => {
  if (loading) {
    return <PageLoading />;
  }

  return (
    <Wrapper>
      <Row>
        {promos.map((item, i) => (
          <ColItem key={i} xs={12} sm={6} md={4} lg={3}>
            <Card>
              <div>{item.name}</div>
              {!!item.intro && (
                <div dangerouslySetInnerHTML={{ __html: item.intro }} />
              )}
              <Img src={item.image} alt="" />
              <Footer>
                <Button fullwidth href={item.url} target="_blank">
                  {item.subscribeLabel}
                </Button>
              </Footer>
            </Card>
          </ColItem>
        ))}
        {!promos.length && (
          <ColItem xs={12} sm={12} md={12} lg={12}>
            <FormattedMessage
              id="website.promos.noPromo"
              defaultMessage="Aucune promo disponible pour le moment"
            />
          </ColItem>
        )}
      </Row>
    </Wrapper>
  );
};

const Img = styled.img`
  max-width: 100%;
  height: auto;
`;

const Footer = styled.div`
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid ${colors.lightLt};
`;

const ColItem = styled(Col)`
  ${mediaQuery(">", "960px")`
     &:not(:nth-child(-n + 4)) {
    margin-top: 30px;
  }
  `}

  ${mediaQuery("<=", "960px")`
    &:not(:nth-child(-n + 3)) {
      margin-top: 30px;
    }
  `}

  ${mediaQuery("<=", "tablet")`
    &:not(:nth-child(-n + 2)) {
      margin-top: 30px;
    }
  `}

  ${mediaQuery("<=", "mobile")`
    &:not(:nth-child(-n + 1)) {
      margin-top: 30px;
    }
  `}

  &:not(:nth-child()) {
    margin-top: 30px;
  }
`;
