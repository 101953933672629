import * as React from "react";
import styled from "styled-components";
const SvgPointer16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path
      d="M50 50a6.25 6.25 0 10-6.25-6.25A6.26 6.26 0 0050 50zm0-25a18.75 18.75 0 11-18.75 18.75A18.75 18.75 0 0150 25zm0-25c24.1 0 43.75 20.11 43.75 44.83a44.77 44.77 0 01-16.43 35L54.76 98.29A7.38 7.38 0 0150 100a7.47 7.47 0 01-4.8-1.73L22.66 79.83a45.06 45.06 0 01-16.41-35C6.25 20.11 25.85 0 50 0zm19.4 70.16a32.35 32.35 0 0011.85-25.33C81.25 27 67.21 12.5 50 12.5S18.75 27 18.75 44.83a32.6 32.6 0 0011.84 25.34L50 86z"
      fillRule="evenodd"
    />
  </svg>
);
export default styled(SvgPointer16)``;
