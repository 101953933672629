import { Dialog, useMediaQuery, useTheme, makeStyles } from "@material-ui/core";
import { DialogProps } from "@material-ui/core/Dialog";
import React from "react";
import styled from "styled-components";
import { IconCross16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { IReactChildren } from "./types";

const useStyles = makeStyles({
  paperMedium: {
    maxWidth: 690,
  },
});

export const Modal = ({
  open,
  onClose,
  children,
  title,
  maxWidth,
}: {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: IReactChildren;
  maxWidth?: DialogProps["maxWidth"] | "m";
}) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={useMediaQuery(theme.breakpoints.down("xs"))}
      fullWidth
      maxWidth={maxWidth ? (maxWidth === "m" ? false : maxWidth) : "sm"}
      classes={maxWidth === "m" ? { paper: classes.paperMedium } : undefined}
    >
      <Content>
        <Header>
          {title}
          <ModalClose onClick={onClose}>
            <IconCross16 fill={colors.darkDk} width={16} height={16} />
          </ModalClose>
        </Header>
        {!!title ? <Children>{children}</Children> : <div>{children}</div>}
      </Content>
    </Dialog>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  ${fontFamily(ffdinPro.bold)};
  ${fontSize("19px")}
  align-items: center;
`;

const ModalClose = styled.div`
  cursor: pointer;
  margin-left: auto;

  svg {
    display: block;
    transition: fill 0.2s;
  }

  &:hover svg {
    fill: ${colors.primary};
  }
`;

const Content = styled.div`
  padding: 40px;
`;

const Children = styled.div`
  margin-top: 40px;
`;
