import * as React from "react";
import styled from "styled-components";
const SvgPack16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path
      d="M75.91 12.5H56.25V25H86l-4.5-9a6.21 6.21 0 00-5.59-3.5zm-32.16 0H24.09a6.23 6.23 0 00-5.6 3.5L14 25h29.75zm6.25 50A6.25 6.25 0 0150 75H31.25a6.25 6.25 0 010-12.5zm37.5-25h-75v43.75a6.26 6.26 0 006.25 6.25h62.5a6.25 6.25 0 006.25-6.25zM75.91 0a18.75 18.75 0 0116.77 10.36L100 25v56.25A18.75 18.75 0 0181.25 100h-62.5A18.75 18.75 0 010 81.25V25l7.32-14.64A18.75 18.75 0 0124.09 0z"
      fillRule="evenodd"
    />
  </svg>
);
export default styled(SvgPack16)``;
