import React from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { Button } from "./Button";
import { Card } from "./Card";
import { IconArrowR10, IconCross10, IconCheck10 } from "./icons-ts";
import { Input } from "./Input";
import { Form, useInputProps } from "./Form";
import { IErrorResult } from "./lib/validationUtils";
import { string, object } from "yup";
import illuBarcode from "../assets/illu-barcode.svg";

const messages = defineMessages({
  label: {
    id: "website.coupons.form.barcode.label",
    defaultMessage: "Code-barres",
  },
  placeholder: {
    id: "website.coupons.form.barcode.placeholder",
    defaultMessage: "Entrez le code-barres du voucher",
  },
});

interface IFormValues {
  barcode: string;
}

export const CardCouponsBarcode = ({
  onSubmit,
  loading,
}: {
  onSubmit: (values: IFormValues) => Promise<IErrorResult | null | void>;
  loading?: boolean;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Container>
      <Illu>
        <Img src={illuBarcode} />
      </Illu>
      <Form<IFormValues>
        loading={loading}
        initialValues={{
          barcode: "",
        }}
        fieldLabels={{
          barcode: formatMessage(messages.label),
        }}
        schema={object().shape({
          barcode: string().required(),
        })}
        onSubmit={async (values: IFormValues) => {
          const error = await onSubmit(values);
          if (error) {
            return {
              barcode: error.message,
            };
          }

          return;
        }}
        render={({
          handleSubmit,
          submitErrors,
          submitSucceeded,
          form: { reset },
        }) => (
          <form onSubmit={handleSubmit}>
            <InputContainer>
              <Input
                fullWidth
                {...useInputProps({
                  name: "barcode",
                })}
                placeholder={formatMessage(messages.placeholder)}
                helperText={null}
              />
            </InputContainer>
            <div>
              <ButtonSubmit
                fullwidth
                disabled={loading}
                hasError={!!submitErrors?.barcode}
                isSuccess={submitSucceeded}
                reset={() => {
                  reset();
                }}
              />
            </div>
          </form>
        )}
      />
    </Container>
  );
};

const ButtonSubmit = ({
  hasError,
  isSuccess,
  reset,
  ...props
}: {
  hasError?: boolean;
  isSuccess?: boolean;
  reset?: () => void;
} & React.ComponentProps<typeof Button>) => {
  const buttonProps: React.ComponentProps<typeof Button> = {
    ...props,
    type: "submit",
    suffixBackground: "transparent",
  };
  if (hasError) {
    return (
      <Button
        {...buttonProps}
        suffix={<IconCross10 height={10} width={10} fill="#fff" />}
        theme="error"
        onClick={e => {
          if (reset) {
            e.preventDefault();
            reset();
          }
        }}
      >
        <FormattedMessage
          id="website.coupons.barcode.codeInvalid"
          defaultMessage="Code invalide"
        />
      </Button>
    );
  }

  if (isSuccess) {
    return (
      <Button
        {...buttonProps}
        suffix={<IconCheck10 height={8} width={10} fill="#fff" />}
        theme="success"
        onClick={e => {
          if (reset) {
            e.preventDefault();
            reset();
          }
        }}
      >
        <FormattedMessage
          id="website.coupons.barcode.codeValided"
          defaultMessage="Code validé"
        />
      </Button>
    );
  }

  return (
    <Button {...buttonProps} suffix={<IconArrowR10 height={10} width={5} fill="#fff" />}>
      <FormattedMessage
        id="website.coupons.barcode.submit"
        defaultMessage="Valider"
      />
    </Button>
  );
};

const Container = styled(Card)`
  ${mediaQuery("<=", "mobile")`
    margin-top: 20px;
  `}

  ${mediaQuery(">=", "640px")`
    display: flex;
    align-items: center;
    form {
      display: flex;
      width: 100%;
    }
  `}
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 0 30px;

  ${mediaQuery("<", "960px")`
    margin-left: 0;
  `}

  ${mediaQuery("<", "640px")`
    margin-bottom: 30px;
    margin-right: 0;
  `}
`;

const Img = styled.img`
  display: block;
`;

const Illu = styled.div`
  ${mediaQuery("<", "960px")`
    display: none;
  `}
`;
