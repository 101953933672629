import * as React from "react";
import styled from "styled-components";
const SvgInstagram16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M50 12.5H29.62a24 24 0 00-7.86 1.5 13.16 13.16 0 00-7.53 7.63 23.87 23.87 0 00-1.72 7.78v41A24 24 0 0014 78.24a13.16 13.16 0 007.63 7.53 23.87 23.87 0 007.78 1.72h41A24 24 0 0078.24 86a13.16 13.16 0 007.53-7.63 23.88 23.88 0 001.72-7.78v-41A24 24 0 0086 21.76a13.16 13.16 0 00-7.63-7.53 23.88 23.88 0 00-7.78-1.72zM29.37 0h41.26a36.69 36.69 0 0112.43 2.63 25.56 25.56 0 0114.63 14.62A36.69 36.69 0 01100 29.37v41.26a36.69 36.69 0 01-2.63 12.44 25.56 25.56 0 01-14.62 14.62A36.69 36.69 0 0170.63 100H29.37a36.69 36.69 0 01-12.44-2.62A25.56 25.56 0 012.31 82.75 36.69 36.69 0 010 70.63V29.37a36.69 36.69 0 012.62-12.43A25.56 25.56 0 0117.25 2.31 36.69 36.69 0 0129.37 0zM50 37.5a12.5 12.5 0 108.84 3.66A12.5 12.5 0 0050 37.5zM50 25a25 25 0 11-25 25 25 25 0 0125-25zm25 6.25A6.25 6.25 0 1181.25 25 6.25 6.25 0 0175 31.25z" />
  </svg>
);
export default styled(SvgInstagram16)``;
