import * as React from "react";
import styled from "styled-components";
const SvgBag16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M79.18 85.44a6.49 6.49 0 01-4.75 2.06H25.57a6.49 6.49 0 01-4.75-2 6.15 6.15 0 01-1.66-4.79l4.1-43.75a6.35 6.35 0 016.41-5.69h40.72a6.35 6.35 0 016.41 5.69l4.1 43.75a6.16 6.16 0 01-1.72 4.73zM50 12.5a6.33 6.33 0 016.41 6.25H43.59A6.33 6.33 0 0150 12.5zm39.5 23.31a19.06 19.06 0 00-19.24-17.06h-1a19.24 19.24 0 00-38.48 0h-1.11a19.06 19.06 0 00-19.24 17.06l-4.1 43.75a18.45 18.45 0 005 14.36A19.47 19.47 0 0025.57 100h48.86a19.47 19.47 0 0014.25-6.07 18.45 18.45 0 005-14.36z" />
  </svg>
);
export default styled(SvgBag16)``;
