import React, { useState } from "react";
import styled from "styled-components";
import { ffdinPro, fontFamily } from "./lib/fonts";
import { Link } from "./Link";
import { IPartner, ICountriesValue } from "./types";
import { FormattedMessage } from "react-intl";
import { Placeholder } from "./Placeholder";
import { countryName } from "./lib/utils";
import { Modal } from "./Modal";
import { ModalFooter } from "./ModalFooter";

export const ShippingAddress = ({
  partner,
  loading,
  countries = [],
  onClickEditAddress,
}: {
  loading?: boolean;
  partner?: Pick<IPartner, "name" | "address">;
  countries?: ICountriesValue;
  onClickEditAddress: () => void;
}) => {
  const [isOpenModalEdit, setIsOpenModalEdit] = useState<boolean>(false);
  return (
    <div>
      <Header>
        <Title>
          <FormattedMessage
            id="form.litterPack.delivery.title"
            defaultMessage="Livraison à domicile"
          />
        </Title>
        <Link
          href="/information"
          onClick={e => {
            e.preventDefault();
            setIsOpenModalEdit(true);
          }}
        >
          <FormattedMessage
            id="button.label.modify"
            defaultMessage="Modifier"
          />
        </Link>
        <Modal
          maxWidth="sm"
          open={isOpenModalEdit}
          onClose={() => setIsOpenModalEdit(false)}
        >
          <ModalEditAddressContent
            onCancel={() => {
              setIsOpenModalEdit(false);
            }}
            onConfirm={onClickEditAddress}
          />
        </Modal>
      </Header>
      <div>
        {loading || !partner ? (
          <Placeholder height={160} />
        ) : (
          <>
            <Name>{partner.name}</Name>
            <div>
              {partner.address?.street1} {partner.address?.streetnum}
            </div>
            <div>
              {partner.address?.postalCode} {partner.address?.city}
            </div>
            <div>
              {countryName(countries, partner.address?.country || undefined)}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const ModalEditAddressContent = ({
  onCancel,
  onConfirm,
}: {
  onCancel: () => void;
  onConfirm: () => void;
}) => (
  <>
    <p>
      <FormattedMessage
        id="form.litterPack.editAddress.confirm"
        defaultMessage="Attention, vous allez être redirigé vers votre fiche d'informations et les données de cette commande seront perdues"
      />
    </p>
    <ModalFooter
      onClose={onCancel}
      onClick={onConfirm}
      clickLabel={
        <FormattedMessage
          id="button.label.continue"
          defaultMessage="Continuer"
        />
      }
    />
  </>
);

const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Title = styled.div`
  ${fontFamily(ffdinPro.bold)}
  margin-right: 60px;
`;

const Name = styled.div`
  ${fontFamily(ffdinPro.medium)}
  margin-bottom: 10px;
`;
