import * as React from "react";
import styled from "styled-components";
const SvgShop16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M87.5 81.25a6.25 6.25 0 01-6.25 6.25h-12.5V72.94a18.75 18.75 0 00-37.5 0V87.5h-12.5a6.25 6.25 0 01-6.25-6.25v-37.5a25 25 0 0018.75-8.63 24.69 24.69 0 0037.5 0 25 25 0 0018.75 8.63zM56.25 87.5h-12.5V72.94a6.25 6.25 0 0112.5 0zM12.5 18.75a6.25 6.25 0 016.25-6.25H25v6.25a12.5 12.5 0 01-12.5 12.5zm25-6.25h25v6.25a12.5 12.5 0 01-25 0zm50 6.25v12.5A12.5 12.5 0 0175 18.75V12.5h6.25a6.25 6.25 0 016.25 6.25zM81.25 0h-62.5A18.75 18.75 0 000 18.75v62.5A18.75 18.75 0 0018.75 100h62.5A18.75 18.75 0 00100 81.25v-62.5A18.75 18.75 0 0081.25 0z" />
  </svg>
);
export default styled(SvgShop16)``;
