import { createTheme } from "@material-ui/core/styles";
import { colors } from "./constants";
import { ffdinPro } from "./fonts";

const MuiPickersOverrides: any = {
  MuiPickersDay: {
    day: {
      fontFamily: ffdinPro.regular.name,
    },
  },
};

export const muiTheme = createTheme({
  palette: {
    primary: {
      dark: colors.dark,
      main: colors.primary,
    },
    secondary: {
      main: colors.primary,
    },
  },
  typography: {
    fontFamily: ffdinPro.regular.name,
  },
  overrides: {
    ...MuiPickersOverrides,
    MuiTouchRipple: {
      root: {
        color: colors.darkDk,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(51,51,51, .75)",
      },
    },
  },
});
