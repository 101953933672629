import { format } from "date-fns";
import React from "react";
import styled from "styled-components";
import { IconDoc16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { LogicalLink } from "./LogicalLink";
import { ICoupon } from "./types";
import { Media } from "./lib/media";
import { FormattedMessage } from "react-intl";
import { Loader } from "./Loader";

export const CouponsList = ({
  loading,
  coupons,
  thead,
}: {
  loading?: boolean;
  coupons: ICoupon[];
  thead?: boolean;
}) => (
  <>
    <Media when={["desktop"]}>
      <Table>
        {thead && (
          <thead>
            <tr>
              <Th>
                <FormattedMessage
                  id="coupon.column.action"
                  defaultMessage="Action"
                />
              </Th>
              <Th>
                <FormattedMessage
                  id="coupon.column.barcode"
                  defaultMessage="Code-barres"
                />
              </Th>
              <Th colSpan={2}>
                <FormattedMessage
                  id="coupon.column.date"
                  defaultMessage="Date"
                />
              </Th>
            </tr>
          </thead>
        )}
        <tbody>
          {loading ? (
            <Row>
              <td colSpan={4}>
                <Loader />
              </td>
            </Row>
          ) : (
            <>
              {coupons.map((item, i) => (
                <Row key={i}>
                  <td>
                    <Action>{item.dealRef}</Action>
                  </td>
                  <td>{item.barcode}</td>
                  <td>
                    <FormattedMessage id="date_the" defaultMessage="Le" />{" "}
                    {format(new Date(item.scanDate), "dd/MM/yyyy")}{" "}
                    <FormattedMessage id="date_to" defaultMessage="à" />{" "}
                    {format(new Date(item.scanDate), "HH:mm")}
                  </td>
                  <td>
                    <LogicalLink
                      href={`https://deals.royalcanin.be/${item.dealRef}`}
                      target="_blank"
                    >
                      <IconDoc16 height={21} width={16} fill={colors.primary} />
                    </LogicalLink>
                  </td>
                </Row>
              ))}
            </>
          )}
        </tbody>
      </Table>
    </Media>
    <Media when={["tablet"]}>
      {coupons.map((item, i) => (
        <MobileRow key={i} style={{ display: "flex", alignItems: "center" }}>
          <div>
            <div style={{ marginBottom: 10 }}>{item.barcode}</div>
            <MobileDate>
              <FormattedMessage id="date_the" defaultMessage="Le" />{" "}
              {format(new Date(item.scanDate), "dd/MM/yyyy")}{" "}
              <FormattedMessage id="date_to" defaultMessage="à" />{" "}
              {format(new Date(item.scanDate), "HH:mm")}
            </MobileDate>
          </div>

          <div style={{ marginLeft: "auto" }}>
            <LogicalLink
              href={`https://deals.royalcanin.be/${item.dealRef}`}
              target="_blank"
            >
              <IconDoc16 height={21} width={16} fill={colors.primary} />
            </LogicalLink>
          </div>
        </MobileRow>
      ))}
    </Media>
  </>
);

const Table = styled.table`
  width: 100%;
`;

const Th = styled.th`
  color: ${colors.dark};
  text-align: left;
  ${fontSize("14px")}
  ${fontFamily(ffdinPro.bold)}
  padding-bottom: 30px;
`;

const Row = styled.tr`
  &:not(:first-child) td {
    padding-top: 20px;
    border-top: 1px dashed ${colors.lightLt};
  }

  &:not(:last-child) td {
    padding-bottom: 20px;
  }
`;

const Action = styled.div`
  ${fontFamily(ffdinPro.bold)}
`;

const MobileRow = styled.div`
  &:not(:first-child) {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px dotted ${colors.lightLt};
  }
`;

const MobileDate = styled.div`
  ${fontFamily(ffdinPro.medium)}
  color: ${colors.light};
  ${fontSize("12px")}

`;
