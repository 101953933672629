import React from "react";
import { FormattedMessage } from "react-intl";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { CardIcon } from "./CardIcon";
import { IconKit60, IconLitter60 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { useInputProps } from "./Form";

export const LittersChoiceCards = ({
  disabledAd,
}: {
  disabledAd?: boolean;
}) => (
  <SplitContainer>
    <CardIcon
      inputProps={{
        ...useInputProps({
          name: "pack",
          config: {
            type: "checkbox",
          },
        }),
        disabled: true,
      }}
      icon={<IconKit60 width={60} height={60} fill={colors.darkDk} />}
    >
      <FormattedMessage
        id="website.litters.add.kits"
        defaultMessage="Je désire commander des colis"
      />
    </CardIcon>
    <Split>
      <Text>
        <FormattedMessage id="website.litters.andOr" defaultMessage="et/ou" />
      </Text>
    </Split>
    <CardIcon
      icon={<IconLitter60 width={60} height={60} fill={colors.darkDk} />}
      inputProps={{
        ...useInputProps({
          name: "litter",
          config: {
            type: "checkbox",
          },
        }),
        disabled: !!disabledAd,
      }}
    >
      <FormattedMessage
        id="website.litters.add.litters"
        defaultMessage="Je souhaite déclarer une portée"
      />
    </CardIcon>
  </SplitContainer>
);

const SplitContainer = styled.div`
  display: flex;

  ${mediaQuery("<=", "phablet")`
    display: block;
  `}
`;

const Split = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("16px")}
  align-items: center;
  color: ${colors.dark};
  display: flex;
  letter-spacing: -0.5px;
  margin: 0 30px;
  position: relative;
  text-align: center;

  ${mediaQuery("<=", "phablet")`
    margin: 0;
    justify-content: center;
    margin: 15px 0;
  `}

  &::before {
    content: "";
    width: 1px;
    top: 40px;
    bottom: 40px;
    position: absolute;
    background: ${colors.lightLt};
    z-index: 1;
    left: 50%;
    margin-left: -0.5px;

    ${mediaQuery("<=", "phablet")`
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      top: 50%;
      bottom: auto;
      margin-top: -0.5px;
    `}
  }
`;

const Text = styled.span`
  display: inline-block;
  background: ${colors.lightLter};
  padding: 15px 0;
  position: relative;
  z-index: 2;

  ${mediaQuery("<=", "phablet")`
      padding: 0 15px;
    `}
`;
