import React from "react";
import ContentLoader from "react-content-loader";

export const Placeholder = ({
  height,
  width,
}: {
  height?: number;
  width?: number;
}) => <ContentLoader style={{ width: "100%" }} height={height || 10} />;
