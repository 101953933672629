import * as React from "react";
import styled from "styled-components";
const SvgLitter60 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M18.33 50h35a1.67 1.67 0 010 3.33h-35a1.67 1.67 0 110-3.33zm0-8.33h35a1.67 1.67 0 010 3.33h-35a1.67 1.67 0 110-3.33zm0-8.33h35a1.67 1.67 0 010 3.33h-35a1.67 1.67 0 110-3.33zM50 4.71V15a6.67 6.67 0 006.67 6.67H67zm18.33 13.62V10a6.67 6.67 0 00-6.67-6.67h-8.33zm1.67 60A1.67 1.67 0 0171.67 80v10a10 10 0 01-10 10H10A10 10 0 010 90V10A10 10 0 0110 0h51.67a10 10 0 0110 10v30a1.67 1.67 0 11-3.33 0V25H56.67a10 10 0 01-10-10V3.33H10A6.67 6.67 0 003.33 10v80A6.67 6.67 0 0010 96.67h51.67A6.67 6.67 0 0068.33 90V80A1.67 1.67 0 0170 78.33zm27.57-45.9a8.3 8.3 0 010 11.74L58.41 83.33H46.67V71.59l39.16-39.16a8.3 8.3 0 0111.74 0zM56 80l-6-6v6zm37.85-36.82l1.36-1.36a5 5 0 00-7-7l-1.36 1.36zm-5.67 5.67l-7-7-29.36 29.3 7 7zm2.34-2.34l1-1-7-7-1 1z" />
  </svg>
);
export default styled(SvgLitter60)``;
