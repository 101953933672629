import * as React from "react";
import styled from "styled-components";
const SvgCalendar16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M81.25 87.5h-62.5a6.25 6.25 0 01-6.25-6.25V50h75v31.25a6.25 6.25 0 01-6.25 6.25zM18.75 25h62.5a6.25 6.25 0 016.25 6.25v6.25h-75v-6.25A6.25 6.25 0 0118.75 25zm62.5-12.5V6.25a6.25 6.25 0 00-12.5 0v6.25h-37.5V6.25a6.25 6.25 0 00-12.5 0v6.25A18.75 18.75 0 000 31.25v50A18.75 18.75 0 0018.75 100h62.5A18.75 18.75 0 00100 81.25v-50A18.75 18.75 0 0081.25 12.5z" />
  </svg>
);
export default styled(SvgCalendar16)``;
