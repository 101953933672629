import React from "react";
import { FormattedMessage } from "react-intl";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { Button } from "./Button";
import { Card } from "./Card";
import { IconRoundedCheck50 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { Link } from "./Link";
import { LittersAddHeader, LittersAddHeaderProps } from "./LittersAddHeader";
import { format } from "date-fns";

export const LittersAddConfirm = ({
  title,
  text,
  adDateStart,
  adDateEnd,
  littersAddHeaderProps,
}: {
  title?: string | JSX.Element;
  text?: React.ComponentProps<typeof TextConfirm>["text"];
  adDateStart?: React.ComponentProps<typeof Split>["dateStart"];
  adDateEnd?: React.ComponentProps<typeof Split>["dateEnd"];
  littersAddHeaderProps?: LittersAddHeaderProps;
}) => (
  <>
    <LittersAddHeader {...littersAddHeaderProps} submitButton={false}>
      <FormattedMessage
        id="website.litters.confirm.title"
        defaultMessage="Confirmation"
      />
    </LittersAddHeader>
    <Container>
      <IconRoundedCheck50 width={50} height={50} fill={colors.success} />
      <Title>
        {!!title ? (
          title
        ) : (
          <FormattedMessage
            id="website.litters.confirm.success"
            defaultMessage="Votre demande a bien été enregistrée"
          />
        )}
      </Title>

      {!!adDateStart && !!adDateEnd ? (
        text === false ? (
          <div>
            <TextAd dateStart={adDateStart} dateEnd={adDateEnd} />
          </div>
        ) : (
          <Split
            text={<TextConfirm text={text} />}
            dateStart={adDateStart}
            dateEnd={adDateEnd}
          />
        )
      ) : (
        <div>
          <TextConfirm text={text} />
        </div>
      )}
      <Submit href="/litters">
        <FormattedMessage
          id="website.litters.confirm.back"
          defaultMessage="Retour aux portées"
        />
      </Submit>
    </Container>
  </>
);

const TextConfirm = ({ text }: { text?: boolean | string | JSX.Element }) => {
  if (text === false) {
    return null;
  }

  return typeof text !== "boolean" && !!text ? (
    <>{text}</>
  ) : (
    <FormattedMessage
      id="website.litters.confirm.kits"
      defaultMessage="Vos colis ont été commandés et seront livrés prochainement."
    />
  );
};

const TextAd = ({
  dateStart,
  dateEnd,
}: {
  dateStart: string | Date;
  dateEnd: string | Date;
}) => (
  <FormattedMessage
    id="website.litters.confirm.adVisibleBetweenDate"
    defaultMessage="L’annonce sera visible sur {website} entre le {dateStart} et le {dateEnd}."
    values={{
      website: (
        <Link href="https://breeders.royalcanin.be" target="_blank">
          breeders.royalcanin.be
        </Link>
      ),
      dateStart: (
        <SplitDate>{format(new Date(dateStart), "dd.MM.yyyy")}</SplitDate>
      ),
      dateEnd: <SplitDate>{format(new Date(dateEnd), "dd.MM.yyyy")}</SplitDate>,
    }}
  />
);

const Split = ({
  dateStart,
  dateEnd,
  text,
}: {
  dateStart: React.ComponentProps<typeof TextAd>["dateStart"];
  dateEnd: React.ComponentProps<typeof TextAd>["dateEnd"];
  text?: string | JSX.Element | null;
}) => (
  <SplitContainer>
    <SplitItem>{text}</SplitItem>
    <SplitItem>
      <TextAd dateStart={dateStart} dateEnd={dateEnd} />
    </SplitItem>
  </SplitContainer>
);

const Container = styled(Card)`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${mediaQuery(">", "tablet")`
    padding-top: 110px;
    padding-bottom: 110px;
  `}
`;

const Title = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("19px")}
  color: ${colors.success};
  text-align: center;
  margin-top: 30px;
  margin-bottom: 15px;
`;

const Submit = styled(Button)`
  margin-top: 30px;
`;

const SplitContainer = styled.div`
  ${mediaQuery(">", "tablet")`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

const SplitDate = styled.span`
  ${fontFamily(ffdinPro.bold)}
`;

const SplitItem = styled.div`
  text-align: center;
  line-height: 24px;

  ${mediaQuery("<=", "tablet")`
    &:first-child {
      margin-bottom: 10px;
    }
  `}

  ${mediaQuery(">", "tablet")`
    width: 35%;
    padding: 40px 0;

    &:first-child {
      margin-right: 40px;
      padding-right: 40px;
      border-right: 1px solid ${colors.lightLt};
    }
  `}
`;
