import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { IconArrowR10 } from "./icons-ts";
import { Input } from "./Input";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { ModalFooter } from "./ModalFooter";
import { Switch } from "./Switch";
import { eachDayOfInterval, endOfWeek, format, startOfWeek } from "date-fns";
import { fr, nl } from "date-fns/esm/locale";
import { LocaleValue } from "@royalcanin-be-partner-portal/settings";
import gql from "graphql-tag";
import { OmitRecursively } from "./types";
import { IErrorResult, validationErrorsToObject } from "./lib/validationUtils";
import { CardOpeningHoursPartnerFragment } from "./__generated__/CardOpeningHoursPartnerFragment";
import { FORM_ERROR } from "final-form";
import { Alert } from "./Alert";
import { Form, useInputProps } from "./Form";
import { FormattedMessage } from "react-intl";
import { mediaQuery } from "react-styled-mediaquery";

const dateLocales = { fr, nl };

export const getWeekdays = (locale: LocaleValue) =>
  eachDayOfInterval({
    start: startOfWeek(new Date(), { weekStartsOn: 1 }),
    end: endOfWeek(new Date(), { weekStartsOn: 1 }),
  }).map(d => format(d, "iiii", { locale: dateLocales[locale] }));

type CardOpeningHoursFormEditValues = OmitRecursively<
  CardOpeningHoursPartnerFragment,
  "__typename"
>;

interface IPeriod {
  from: string;
  to: string;
}

interface IFormValueOpeningHour {
  period_0: IPeriod | null;
  period_1: IPeriod | null;
  isClosed: boolean;
  break: boolean;
}

interface IFormValues {
  day_1: IFormValueOpeningHour;
  day_2: IFormValueOpeningHour;
  day_3: IFormValueOpeningHour;
  day_4: IFormValueOpeningHour;
  day_5: IFormValueOpeningHour;
  day_6: IFormValueOpeningHour;
  day_7: IFormValueOpeningHour;
}

const convertInitialValues = (
  initialValues?: CardOpeningHoursFormEditValues | null,
): IFormValues => {
  const data = new Array(7)
    .fill(undefined)
    .map((v, i) => i + 1)
    .reduce((prev, dayIndex) => {
      const day =
        initialValues &&
        initialValues.openingHours &&
        initialValues.openingHours.find(item => {
          return item.dayIndex === dayIndex;
        });
      return {
        ...prev,
        [`day_${dayIndex}`]: {
          period_0: (day && day.periods[0]) || null,
          period_1: (day && day.periods[1]) || null,
          isClosed: !day || !day.periods.length,
          break: (day && day.periods.length > 1) || false,
        },
      };
    }, {}) as IFormValues;
  return data;
};

export const CardOpeningHoursFormEdit = ({
  locale,
  onClose,
  onSubmit,
  initialValues,
}: {
  locale: LocaleValue;
  onClose: () => void;
  onSubmit: (
    values: CardOpeningHoursFormEditValues,
  ) => Promise<IErrorResult | null | void>;
  initialValues: CardOpeningHoursFormEditValues | undefined;
}) => {
  const weekdays = getWeekdays(locale);
  return (
    <Form<IFormValues>
      initialValues={{
        ...convertInitialValues(initialValues),
      }}
      onSubmit={async (values: IFormValues) => {
        const openingHours = [] as NonNullable<
          CardOpeningHoursFormEditValues["openingHours"]
        >;

        for (const day of Object.keys(values)) {
          const openingHour = {
            day,
            dayIndex: parseInt(day.split("_")[1], 10),
            periods: [] as IPeriod[],
          };
          const value = values[day as keyof IFormValues];
          if (!value.isClosed) {
            if (value.period_0?.from && value.period_0?.to) {
              openingHour.periods.push({
                from: value.period_0.from,
                to: value.period_0.to,
              });
            }
            if (value.break && value.period_1?.from && value.period_1?.to) {
              openingHour.periods.push({
                from: value.period_1.from,
                to: value.period_1.to,
              });
            }
          }
          openingHours.push(openingHour);
        }

        const error = await onSubmit({ openingHours });
        if (error) {
          return {
            [FORM_ERROR]: error.message,
            ...validationErrorsToObject(error.validationErrors),
          };
        }

        onClose();
        return;
      }}
      render={({ handleSubmit, submitError, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            {submitError && (
              <div style={{ marginBottom: 30 }}>
                <Alert type="error">{submitError}</Alert>
              </div>
            )}
            <Table>
              <tbody>
                <tr>
                  <ColThHideMobile></ColThHideMobile>
                  <th></th>
                  <th align="center">
                    <FormattedMessage
                      id="informations.openingHour.break"
                      defaultMessage="Pause de midi"
                    />
                  </th>
                  <th align="center">
                    <FormattedMessage
                      id="informations.openingHour.closed"
                      defaultMessage="Fermé"
                    />
                  </th>
                </tr>
                {weekdays.map((dayName, i) => {
                  const inputNamePrefix = `day_${i + 1}` as keyof IFormValues;
                  return (
                    <Row key={i}>
                      <ColTdHideMobile>
                        <Day>{dayName}</Day>
                      </ColTdHideMobile>
                      <td>
                        <DayMobile>{dayName}</DayMobile>
                        <Inputs
                          disabled={values[inputNamePrefix].isClosed}
                          inputNamePrefix={`${inputNamePrefix}.period_0`}
                        />
                        {values[inputNamePrefix].break &&
                          !values[inputNamePrefix].isClosed && (
                            <Inputs
                              offset
                              inputNamePrefix={`${inputNamePrefix}.period_1`}
                            />
                          )}
                      </td>
                      <td>
                        <Switch
                          {...useInputProps({
                            name: `${inputNamePrefix}.break`,
                            config: {
                              type: "checkbox",
                            },
                          })}
                          disabled={values[inputNamePrefix].isClosed}
                        />
                      </td>
                      <td>
                        <Switch
                          {...useInputProps({
                            name: `${inputNamePrefix}.isClosed`,
                            config: {
                              type: "checkbox",
                            },
                          })}
                        />
                      </td>
                    </Row>
                  );
                })}
              </tbody>
            </Table>

            <ModalFooter onClose={onClose} buttonType="submit" />
          </form>
        );
      }}
    />
  );
};

const ColTdHideMobile = styled.td`
  ${mediaQuery("<=", "500px")`
    display: none;
  `}
`;

const Day = styled.div`
  ${fontSize("16px")}
  ${fontFamily(ffdinPro.bold)}
  text-transform: capitalize;
`;

const DayMobile = styled(Day)`
  ${mediaQuery(">", "500px")`
    display: none;
  `}
`;

const ColThHideMobile = styled.th`
  ${mediaQuery("<=", "500px")`
    display: none;
  `}
`;

const Inputs = ({
  offset,
  disabled,
  inputNamePrefix,
}: {
  offset?: boolean;
  disabled?: boolean;
  inputNamePrefix: string;
}) => (
  <Container offset={!!offset}>
    <Input
      {...useInputProps({
        name: `${inputNamePrefix}.from`,
      })}
      type="time"
      disabled={disabled}
    />
    <div style={{ margin: "0 15px" }}>
      <IconArrowR10 height={10} width={5} fill={colors.light} />
    </div>
    <Input
      {...useInputProps({
        name: `${inputNamePrefix}.to`,
      })}
      type="time"
      disabled={disabled}
    />
  </Container>
);

const Container = styled(
  ({
    offset,
    children,
    ...props
  }: {
    offset: boolean;
    children: ReactNode | ReactNode[];
  }) => <div {...props}>{children}</div>,
)`
  display: flex;
  align-items: center;
  ${p =>
    p.offset &&
    css`
      margin-top: 35px;
    `}
`;

const Table = styled.table`
  width: 100%;

  th {
    border-bottom: 1px solid ${colors.lightLt};
    padding-bottom: 20px;
    color: ${colors.dark};
    ${fontSize("14px")}
    ${fontFamily(ffdinPro.bold)}
  }
`;

const Row = styled.tr`
  td {
    border-top: 1px dashed ${colors.lightLt};
  }
  &:nth-child(2) td {
    border-top: none;
  }
  &:not(:last-child) td {
    padding-bottom: 20px;
  }

  &:not(:first-child) td {
    padding-top: 20px;
  }
`;

CardOpeningHoursFormEdit.PartnerFragment = gql`
  fragment CardOpeningHoursPartnerFragment on PartnerFull {
    openingHours {
      day
      dayIndex
      periods {
        from
        to
      }
    }
  }
`;
