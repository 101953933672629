import React from "react";
import ContentLoader from "react-content-loader";
import { FormattedMessage } from "react-intl";
import { mediaQuery } from "react-styled-mediaquery";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import styled from "styled-components";
import { Caps } from "./Caps";
import { Card } from "./Card";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { Link } from "./Link";
import { Tooltip } from "./Tooltip";
import { ICouponAction } from "./types";

export const CardCouponsStats = ({
  loading,
  actions = [],
  dealRef,
}: {
  loading?: boolean;
  actions?: ICouponAction[];
  dealRef?: string;
}) => {
  if (loading) {
    return (
      <Card>
        <ContentLoader height={410} width={347}>
          <circle cx="180" cy="105" r="100" />
          <rect x="50" y="250" rx="0" ry="0" width="121" height="10" />
          <rect x="184" y="250" rx="0" ry="0" width="121" height="10" />
          <rect x="50" y="350" rx="0" ry="0" width="254" height="10" />
        </ContentLoader>
      </Card>
    );
  }

  const deal = dealRef && actions.find(({ ref }) => ref === dealRef);
  const { subscribed, redeemed } = deal
    ? deal.subscriptionCount
    : actions
        .map(action => action.subscriptionCount)
        .reduce(
          (prev, subscriptionCount) => ({
            subscribed: prev.subscribed + subscriptionCount.subscribed,
            redeemed: prev.redeemed + subscriptionCount.redeemed,
          }),
          {
            subscribed: 0,
            redeemed: 0,
          },
        );

  return (
    <Card>
      <Chart subscribed={subscribed} redeemed={redeemed} />
      <Legend
        subscribed={subscribed}
        redeemed={redeemed}
        actions={!deal ? actions : undefined}
      />
    </Card>
  );
};

const Legend = ({
  subscribed,
  redeemed,
  actions,
}: {
  subscribed: number;
  redeemed: number;
  actions?: ICouponAction[];
}) => (
  <>
    <LegendContainer>
      <LegendItem>
        <LegendTitle>
          <FormattedMessage
            id="website.coupons.graph.subscribe"
            defaultMessage="Inscriptions"
          />
        </LegendTitle>
        <LegendAmount style={{ color: colors.secondaryGraph }}>
          {subscribed + redeemed}
        </LegendAmount>
        <Tooltip
          arrow={true}
          content={
            <FormattedMessage
              id="website.coupons.graph.tooltip.subscribe"
              defaultMessage="Personnes ayant souscrits à l'offre"
            />
          }
        >
          <div>
            <Link dark>
              <FormattedMessage
                id="website.coupons.graph.more"
                defaultMessage="Plus d'infos"
              />
            </Link>
          </div>
        </Tooltip>
      </LegendItem>
      <LegendItem>
        <LegendTitle>
          <FormattedMessage
            id="website.coupons.graph.convert"
            defaultMessage="Conversions"
          />
        </LegendTitle>
        <LegendAmount style={{ color: colors.primaryGraph }}>
          {redeemed}
        </LegendAmount>
        <Tooltip
          arrow={true}
          content={
            <FormattedMessage
              id="website.coupons.graph.tooltip.convert"
              defaultMessage="Personnes ayant effectués un acte d'achat"
            />
          }
        >
          <div>
            <Link dark>
              <FormattedMessage
                id="website.coupons.graph.more"
                defaultMessage="Plus d'infos"
              />
            </Link>
          </div>
        </Tooltip>
      </LegendItem>
    </LegendContainer>
    {!!actions?.length && (
      <LegendTableContainer>
        <LegendTable>
          <tbody>
            {[...actions]
              .sort(
                (action1, action2) =>
                  action2.subscriptionCount.redeemed -
                  action1.subscriptionCount.redeemed, // DESC
              )
              .slice(0, 5)
              .map((action, i) => (
                <tr key={i}>
                  <td>
                    <LegendAction>{action.name || action.ref}</LegendAction>
                  </td>
                  <td align="right">
                    <Caps>{action.subscriptionCount.redeemed}</Caps>
                  </td>
                </tr>
              ))}
          </tbody>
        </LegendTable>
      </LegendTableContainer>
    )}
  </>
);

const Chart = ({
  subscribed,
  redeemed,
}: {
  subscribed: number;
  redeemed: number;
}) => {
  const data = [
    {
      name: "subscribe",
      value: subscribed,
    },
    {
      name: "convert",
      value: redeemed,
    },
  ];

  const COLORS = [colors.secondaryGraph, colors.primaryGraph];
  const total = subscribed + redeemed;

  return (
    <>
      <ChartContainer>
        <ResponsiveContainer width="99%">
          <PieChart>
            <Pie
              data={data}
              dataKey="value"
              endAngle={450}
              innerRadius={87}
              outerRadius={95}
              paddingAngle={1}
              startAngle={90}
            >
              {data.map((entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <ChartPercentage>
          {!!total && Math.floor((redeemed / total) * 100) + "%"}
        </ChartPercentage>
      </ChartContainer>
    </>
  );
};

const LegendContainer = styled.div`
  ${mediaQuery(">=", "1050px")`
     display: flex;
  `}

  ${mediaQuery("<=", "960px")`
     display: flex;
  `}
`;

const LegendItem = styled.div`
  text-align: center;

  ${mediaQuery("<", "1050px")`
     margin-bottom: 30px;
  `}

  ${mediaQuery(">=", "1050px")`
    width: 50%;

    &:first-child {
      margin-right: 10%;
      padding-right: 10%;
      border-right: 1px solid ${colors.lightLter};
    }
  `}

  ${mediaQuery("<=", "960px")`
    width: 50%;
  `}
`;

const LegendAmount = styled.div`
  ${fontSize("30px")}
  ${fontFamily(ffdinPro.bold)}
  letter-spacing: -0.5px;
  line-height: 36px;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const LegendTitle = styled.div`
  ${fontFamily(ffdinPro.bold)}
`;

const LegendTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr:not(:first-child) {
    td {
      padding-top: 20px;
    }
  }
`;

const LegendTableContainer = styled.div`
  padding-top: 30px;
  margin-top: 30px;
  border-top: 2px solid ${colors.lightLter};
`;

const LegendAction = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
`;

const ChartContainer = styled.div`
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  height: 190px;
`;

const ChartPercentage = styled.div`
  ${fontSize("30px")}
  ${fontFamily(ffdinPro.bold)}
  letter-spacing: -0.5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
