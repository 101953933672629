import { FormLabel, Switch as MUISwitch } from "@material-ui/core";
import { SwitchProps } from "@material-ui/core/Switch";
import React from "react";
import styled from "styled-components";

export const Switch = ({
  error,
  name,
  id,
  onChange,
  disabled,
  checked,
}: Pick<SwitchProps, "onChange" | "disabled" | "name" | "id" | "checked"> & {
  error?: boolean;
}) => (
  <>
    <Container error={error}>
      <MUISwitch
        value={name}
        checked={!!checked}
        onChange={onChange}
        inputProps={{ id: id || undefined }}
        disabled={disabled}
      />
    </Container>
  </>
);

const Container = styled(FormLabel)`
  display: flex;
  justify-content: center;
`;
