import React from "react";
import ContentLoader from "react-content-loader";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { Card } from "./Card";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { OmitRecursively } from "./types";
import gql from "graphql-tag";
import { ProductSummaryFragment as ProductFragment } from "./__generated__/ProductSummaryFragment";

type IProductSummary = OmitRecursively<ProductFragment, "__typename">;

export const CardProductsSummary = ({
  product,
  loading,
}: {
  product?: IProductSummary;
  loading?: boolean;
}) => (
  <Card>
    <ContainerHorizontal>
      <div>
        {loading || !product ? (
          <ContentLoader height={220} width={156} />
        ) : (
          <Image
            style={{
              background: `url("${product.image}") center/contain no-repeat`,
            }}
          >
            {!!product.kibble && (
              <Kibble
                style={{
                  background: `#fff url("${product.kibble}") center/70% no-repeat`,
                }}
              />
            )}
          </Image>
        )}
      </div>
      <div>
        <Title>{product?.name}</Title>
        <Range>{product?.range}</Range>
        <Desc>{product?.description}</Desc>
      </div>
    </ContainerHorizontal>
    <ContainerVertical>
      {loading || !product ? (
        <ContentLoader height={350} width={471}>
          <rect x="151" y="76" rx="0" ry="0" width="192" height="224" />
          <rect x="153" y="21" rx="0" ry="0" width="0" height="0" />
          <rect x="121" y="15" rx="0" ry="0" width="275" height="10" />
          <rect x="189" y="46" rx="0" ry="0" width="118" height="10" />
          <rect x="63" y="320" rx="0" ry="0" width="390" height="10" />
        </ContentLoader>
      ) : (
        <>
          <Title>{product.name}</Title>
          <Range>{product.range}</Range>
          <Image
            style={{
              background: `url(${product.image}) center/contain no-repeat`,
            }}
          >
            {!!product.kibble && (
              <Kibble
                style={{
                  background: `#fff url(${product.kibble}) center/70% no-repeat`,
                }}
              />
            )}
          </Image>
          <Desc>{product?.description}</Desc>
        </>
      )}
    </ContainerVertical>

    {/* <div>
      <PackagingTitle>
        <span>
          <FormattedMessage
            id="website.products.packages"
            defaultMessage="Disponible en"
          />
        </span>
      </PackagingTitle>
      <AvailablePackages items={product?.availablePackages} />
    </div> */}
  </Card>
);

export const ProductSummaryFragment = gql`
  fragment ProductSummaryFragment on Collection {
    name
    image
    kibble
    description
    range
    availablePackages {
      quantity
      weight
    }
  }
`;

const Title = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("24px")}
  letter-spacing: -0.5px;
  margin-bottom: 10px;

  ${mediaQuery("<=", "mobile")`
    text-align: center;
  `}

  ${mediaQuery(">", "tablet")`
    text-align: center;
  `}
`;

const Range = styled.div`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("14px")}
  ${mediaQuery("<=", "mobile")`
    text-align: center;
  `}
  ${mediaQuery(">", "tablet")`
    text-align: center;
  `}
`;

const Image = styled.div`
  height: 220px;
  width: 156px;
  margin: 30px auto;
  position: relative;
`;

const Kibble = styled.div`
  width: 80px;
  height: 80px;
  box-shadow: 5px 10px 15px 0 rgba(51, 51, 51, 0.05);
  border-radius: 40px;
  position: absolute;
  bottom: 15px;
  right: -20px;
  z-index: 2;
`;

const Desc = styled.div`
  ${fontSize("14px")}
  line-height: 22px;

  ${mediaQuery("<=", "mobile")`
    text-align: center;
  `}
  ${mediaQuery(">", "tablet")`
    text-align: center;
  `}
`;

// const PackagingTitle = styled.div`
//   ${fontFamily(ffdinPro.bold)}
//   ${fontSize("14px")}
//   text-align: center;
//   margin: 30px 0;
//   position: relative;

//   > span {
//     display: inline-block;
//     position: relative;
//     z-index: 1;
//     background: #fff;
//     padding: 0 15px;
//   }

//   &::before {
//     content: "";
//     position: absolute;
//     left: 0;
//     right: 0;
//     height: 1px;
//     background: ${colors.lightLt};
//     top: 50%;
//     transform: translateY(-50%);
//     z-index: 0;
//   }
// `;

const ContainerVertical = styled.div`
  display: none;

  ${mediaQuery("<=", "mobile")`
    margin-top: 15px;
    display:block;
  `}

  ${mediaQuery(">", "tablet")`
    margin-top: 15px;
    display:block;
  `}
`;

const ContainerHorizontal = styled.div`
  display: none;

  ${mediaQuery("between", "mobile", "tablet")`
    margin-top: 15px;
    display: flex;
    align-items: center;

  ${Image} { margin: 0 70px 0 0; }
  `}
`;
