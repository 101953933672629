import * as React from "react";
import styled from "styled-components";
const SvgFood20 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path
      d="M40 25a5 5 0 11-5 5 5 5 0 015-5zm20 0a5 5 0 11-5 5 5 5 0 015-5zm20 60v-5H20v5a5 5 0 005 5h50a5 5 0 005-5zM20 70h70v15a15 15 0 01-15 15H25a15 15 0 01-15-15V0h10l10 5 10-5 10 5 10-5 10 5 10-5h10v45a5 5 0 01-10 0V35a5 5 0 010-10V11.18l-5.52 2.76L70 16.18l-4.48-2.24L60 11.18l-5.52 2.76L50 16.18l-4.47-2.24L40 11.18l-5.53 2.76L30 16.18l-4.47-2.24L20 11.18V25a5 5 0 010 10z"
      fillRule="evenodd"
    />
  </svg>
);
export default styled(SvgFood20)``;
