import React from "react";
import posed from "react-pose";
import styled from "styled-components";
import { IconWorld20 } from "./icons-ts";
import { colors } from "./lib/constants";
import { Link } from "./Link";

const Posed = posed.div({
  off: {
    boxShadow: "0 10px 15px -5px rgba(51,51,51,0)",
  },
  on: {
    boxShadow: "0 10px 15px -5px rgba(51,51,51,0.10)",
  },
});

const Container = styled(Posed)`
  position: absolute;
  bottom: 20px;
  left: 50%;
  margin-left: -25px;

  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  border-radius: 25px;
  padding: 0 15px;
  height: 50px;
  cursor: pointer;

  svg {
    transition: fill 0.2s;
  }

  &:hover {
    > svg {
      fill: ${colors.primary};
    }
  }
`;

const Icon = styled(IconWorld20)`
  flex-shrink: 0;
`;

const Detail = posed.div({
  off: {
    width: 0,
    opacity: 0,
  },
  on: {
    width: "auto",
    opacity: 1,
  },
});

const Items = styled(Detail)`
  display: flex;
`;

const Item = styled(Link)`
  margin-left: 15px;
`;

export const LanguageSelector = ({
  isOpen,
  onClick,
  locale,
  onLocaleChange,
}: {
  isOpen: boolean;
  onClick: () => void;
  locale: string;
  onLocaleChange: (locale: any) => void;
}) => (
  <Container pose={isOpen ? "on" : "off"} onClick={onClick}>
    <Icon fill={colors.darkDk} width={20} height={20} />
    <Items pose={isOpen ? "on" : "off"}>
      {[
        { label: "Français", code: "fr" },
        { label: "Nederlands", code: "nl" },
      ].map(({label, code}, i) => (
      <Item
        key={i}
        borderless
        onClick={() => {
          onLocaleChange(code);
        }}
        dark={locale === code}
        light={locale !== code}
      >
        {label}
      </Item>
    ))}

    </Items>
  </Container>
);
