import * as React from "react";
import styled from "styled-components";
const SvgMagnify16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M46.88 81.25A34.38 34.38 0 1012.5 46.88a34.37 34.37 0 0034.38 34.37zM75.54 84A46.87 46.87 0 1184 75.54l14.25 14.28a6 6 0 01-8.43 8.43z" />
  </svg>
);
export default styled(SvgMagnify16)``;
