import React from "react";
import { Card } from "./Card";
import { OmitRecursively } from "./types";
import gql from "graphql-tag";
import { ProductPackagingFragment as ProductFragment } from "./__generated__/ProductPackagingFragment";
import { FormattedMessage } from "react-intl";
import { ContentLoading, Title } from "./CardProductsLinks";
import { CapsList, Caps } from "./Caps";
import styled from "styled-components";
import { colors } from "./lib/constants";
import { fontSize, ffdinPro, fontFamily } from "./lib/fonts";

type IProductPackaging = OmitRecursively<ProductFragment, "__typename">;

export const CardProductsPackaging = ({
  product,
  loading,
}: {
  product?: IProductPackaging;
  loading?: boolean;
}) => {
  if (loading || !product) {
    return <ContentLoading />;
  }

  return (
    <Card>
      <Title>
        <FormattedMessage
          id="website.products.packaging"
          defaultMessage="Conditionnement"
        />
      </Title>
      <TablePackages items={product.availablePackages} />
      <div></div>
    </Card>
  );
};

export const ProductPackagingFragment = gql`
  fragment ProductPackagingFragment on Collection {
    availablePackages {
      quantity
      weight
      ean
      code
    }
  }
`;

const formatPackage = (quantity: number, weight: number) => {
  let unit = "g";
  if (weight >= 1000) {
    unit = "kg";
    weight = weight / 1000;
  }
  return quantity > 1 ? `${quantity} x ${weight}${unit}` : `${weight}${unit}`;
};

const TablePackages = ({
  items = [],
}: {
  items?: IProductPackaging["availablePackages"];
}) => (
  <Table>
    <thead>
      <th>
        <FormattedMessage
          id="product.availablePackages.weight"
          defaultMessage="Poids"
        />
      </th>
      <th>
        <FormattedMessage
          id="product.availablePackages.ean"
          defaultMessage="Code EAN"
        />
      </th>
      <th>
        <FormattedMessage
          id="product.availablePackages.code"
          defaultMessage="Référence"
        />
      </th>
    </thead>
    <tbody>
      {items.map((pkg, i) => (
        <tr key={i}>
          <td className="weight">{formatPackage(pkg.quantity, pkg.weight)}</td>
          <td>{pkg.ean}</td>
          <td>{pkg.code}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export const AvailablePackages = ({
  items = [],
  center,
}: {
  items?: IProductPackaging["availablePackages"];
  center?: boolean;
}) => {
  const packages = items.map(({ quantity, weight }) =>
    formatPackage(quantity, weight),
  );
  return (
    <CapsList center={center}>
      {packages.map((item: string, i: number) => (
        <Caps key={i} small>
          {item}
        </Caps>
      ))}
    </CapsList>
  );
};

const Table = styled.table`
  width: 100%;
  text-align: left;
  th,
  td {
    ${fontSize("14px")}
  }
  th {
    color: ${colors.dark};
    ${fontFamily(ffdinPro.bold)}
    padding-bottom: 20px;
  }
  td {
    color: ${colors.darkDk};
    border-top: 1px solid ${colors.lightLt};
    padding: 20px 0;

    &.weight {
      ${fontFamily(ffdinPro.bold)}
    }
  }
`;
