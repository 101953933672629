import * as React from "react";
import styled from "styled-components";
const SvgRoundedCheck16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M72.8 33.39a6.25 6.25 0 01.33 8.48l-21.87 22A11.56 11.56 0 0143 67.31v-.06a11.56 11.56 0 01-8.25-3.44l-8.25-9.87a6.25 6.25 0 019.56-8l7 8.37 21.25-21.25a6.25 6.25 0 018.49.33zM50 12.5A37.5 37.5 0 1087.5 50 37.5 37.5 0 0050 12.5zM50 0A50 50 0 110 50 50 50 0 0150 0z" />
  </svg>
);
export default styled(SvgRoundedCheck16)``;
