import * as React from "react";
import styled from "styled-components";
const SvgArrowT16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path
      d="M98 60.32L54.85 30a6.88 6.88 0 00-9.71 0L2 60.32A6.85 6.85 0 0011.72 70L50 44.52 88.28 70A6.88 6.88 0 0098 70a6.83 6.83 0 000-9.68z"
      fillRule="evenodd"
    />
  </svg>
);
export default styled(SvgArrowT16)``;
