import React from "react";
import { mediaQuery } from "react-styled-mediaquery";
import styled, { css } from "styled-components";

const Container = styled.div<{ responsive?: boolean; offset?: number }>`
  ${p =>
    p.responsive &&
    css`
      ${mediaQuery("<=", "tablet")`
        margin-top: 30px;
      `}
    `}

  ${p =>
    p.offset &&
    css`
      margin-top: ${p.offset}px;
    `}
`;

export const Fieldset = ({
  children,
  offset,
  responsive,
  style,
}: {
  children: React.ReactNode;
  offset?: number;
  responsive?: boolean;
  style?: React.CSSProperties;
}) => (
  <Container responsive={!!responsive} offset={offset} style={style}>
    {children}
  </Container>
);
