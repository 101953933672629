import * as React from "react";
import styled from "styled-components";
const SvgArrowB10 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path
      d="M96.78 43.58a10.82 10.82 0 000-15.39 11.05 11.05 0 00-15.53 0L50 48.72 18.74 28.19a11.06 11.06 0 00-15.53 0 10.83 10.83 0 000 15.39l39 28.23a11 11 0 0015.53 0z"
      fillRule="evenodd"
    />
  </svg>
);
export default styled(SvgArrowB10)``;
