import { ButtonBase } from "@material-ui/core";
import { ButtonBaseProps } from "@material-ui/core/ButtonBase";
import { CSSProperties } from "@material-ui/styles";
import React from "react";
import styled, { css } from "styled-components";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { LogicalLink } from "./LogicalLink";
import { mediaQuery } from "react-styled-mediaquery";

type IButtonContainerProps = {
  fullwidth?: boolean;
  mini?: boolean;
  theme?: ITheme;
  iconComponent?: React.ReactNode;
  disabled?: boolean;
  href?: string;
  prefix?: boolean;
  suffix?: boolean;
} & Omit<ButtonBaseProps, "prefix">;

const ButtonContainer = styled(
  ({ prefix, suffix, fullwidth, iconComponent, mini, ...props }) => {
    return <ButtonBase {...props} component={props.to && LogicalLink} />;
  },
)<IButtonContainerProps>`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
  background: ${colors.primary};
  border-radius: 20px;
  color: #fff;
  height: 40px;
  padding: 0 25px;
  transition: box-shadow 0.2s, transform 0.2s, background 0.2s,
    border-color 0.2s;
  box-sizing: border-box;

  &:hover {
    box-shadow: rgba(25, 80, 137, 0.1) 0px 4px 8px -1px,
      rgba(0, 128, 255, 0.08) 0px 8px 24px -2px;
    background: ${colors.primaryDk};
  }

  ${(p) =>
    p.theme === "dark" &&
    css`
      background: ${colors.darkDk};
      box-shadow: 2px 5px 10px 1px rgba(0, 0, 0, 0.05);
    `}

  ${(p) =>
    p.prefix &&
    css`
      padding: 0 25px 0 5px;
    `}

    ${(p) =>
    p.suffix &&
    css`
      padding: 0 5px 0 25px;
    `}

  ${(p) =>
    p.disabled &&
    css`
      background: ${colors.lightLt};
      cursor: not-allowed;

      &:hover {
        box-shadow: none;
      }
    `}

  ${(p) =>
    p.iconComponent &&
    css`
      padding: 0;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
    `}

  ${(p) =>
    p.theme === "white" &&
    css`
      background: #fff;
      color: ${colors.primary};

      &:hover {
        background: ${colors.lightLt};
      }
    `}

  ${(p) =>
    p.theme === "warning" &&
    css`
      background: ${colors.warningLt};
      color: ${colors.warning};

      &:hover {
        background: ${colors.secondaryGraph};
      }
    `}

  ${(p) =>
    p.theme === "bordered" &&
    css`
      background: #fff;
      color: ${colors.primary};
      border: 2px solid ${colors.primary};

      &:hover {
        color: #fff;
        border-color: ${colors.primaryDk};
      }
    `}
  ${(p) =>
    p.fullwidth &&
    css`
      width: 100%;
    `}
  ${(p) =>
    p.mini &&
    css`
      height: 30px;
      padding: 0 15px;
    `}

    ${(p) =>
    p.theme === "primaryDk" &&
    css`
      background: ${colors.primaryDk};
    `}

    ${(p) =>
    p.theme === "error" &&
    css`
      background: ${colors.primaryDk};
    `}

      ${(p) =>
    p.theme === "success" &&
    css`
      background: ${colors.success};
    `}
`;

const Prefix = styled.div<{ disabled: boolean }>`
  align-items: center;
  border-radius: 15px;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
  transition: background 0.2s;

  ${(p) =>
    p.disabled &&
    css`
      background: ${colors.lightLt};
    `}

  ${mediaQuery("<=", "640px")`
    margin-left: auto !important;
  `}
`;

type ITheme =
  | "dark"
  | "white"
  | "bordered"
  | "primaryDk"
  | "error"
  | "success"
  | "warning";

export const Button = ({
  children,
  fullwidth,
  mini,
  prefix,
  suffix,
  suffixBackground,
  prefixBackground,
  theme,
  icon,
  onClick,
  className,
  type,
  style,
  disabled,
  to,
  href,
  target,
}: {
  children?: React.ReactNode;
  fullwidth?: boolean;
  mini?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  suffixBackground?: string;
  prefixBackground?: string;
  theme?: ITheme;
  icon?: React.ReactNode;
  onClick?: ButtonBaseProps["onClick"];
  className?: string;
  type?: "button" | "submit" | "reset";
  style?: CSSProperties;
  disabled?: boolean;
  to?: string;
  href?: string;
  target?: string;
}) => {
  return (
    <ButtonContainer
      disabled={!!disabled}
      href={href}
      to={to}
      style={style}
      className={className}
      type={type}
      fullwidth={fullwidth}
      mini={mini}
      iconComponent={icon}
      onClick={onClick}
      theme={theme}
      prefix={!!prefix as any}
      suffix={!!suffix as any}
      target={target}
    >
      {prefix && (
        <Prefix
          style={{
            marginRight: 15,
            background: prefixBackground || "undefined",
          }}
          disabled={!!disabled}
        >
          {prefix}
        </Prefix>
      )}
      {children}
      {icon && icon}
      {suffix && (
        <Prefix
          style={{
            marginLeft: 15,
            background: suffixBackground || "undefined",
          }}
          disabled={!!disabled}
        >
          {suffix}
        </Prefix>
      )}
    </ButtonContainer>
  );
};
