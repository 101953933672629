import { set } from "lodash";

export interface IValidationErrorsErrors {
  __typename: "ValidationError";
  validator: string;
  message: string;
}

export interface IValidationErrors {
  __typename: "FieldValidationError";
  field: string;
  errors: IValidationErrorsErrors[];
}

export const validationErrorsToObject = (
  validationErrors: IValidationErrors[] | null,
) => {
  if (!validationErrors) {
    return {};
  }
  return validationErrors.reduce(
    (res, error) => set(res, error.field, error.errors[0].message),
    {},
  );
};

export interface IErrorResult {
  code: string;
  message: string;
  validationErrors: IValidationErrors[] | null;
}
