import React from "react";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { IconArrowB10 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { IconPartnerType } from "./IconPartnerType";

export const PartnerSelector = ({
  onClick,
  type,
  label,
}: {
  onClick: () => void;
  type?: string;
  label?: string | null;
}) => (
  <Container onClick={onClick}>
    <IconPartnerType type={type} style={{ marginRight: 20 }} />
    <div className="partner-name">{label}</div>
    <Arrow>
      <IconArrowB10 width={10} fill={colors.darkDk} />
    </Arrow>
  </Container>
);

const Arrow = styled.div`
  margin-left: 15px;

  ${mediaQuery("<=", "768px")`
    margin-left: auto;
  `}

  svg {
    transition: fill 0.2s;
  }
`;

const Container = styled.div`
  ${fontFamily(ffdinPro.bold)}
  background: ${colors.lightLter};
  display: flex;
  align-items: center;
  ${fontSize("14px")}
  cursor: pointer;
  transition: color .2s;

  ${mediaQuery(">", "768px")`
    border-radius: 20px;
    height: 40px;
    padding: 0 20px 0 5px;
    justify-content: center;
  `}

  ${mediaQuery("<=", "768px")`
    height: 60px;
    padding: 0 15px;
  `}

  &:hover {
    color: ${colors.primary};

    .iconPartnerType {
      background: ${colors.primary};
    }

    ${Arrow} svg {
      fill: ${colors.primary};
    }
  }

  .partner-name {
    max-width: 250px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
