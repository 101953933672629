import React from "react";
import {
  RadioProps,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { IUseInputPropsResult } from "./Form";
import styled, { css } from "styled-components";
import { fontFamily, ffdinPro } from "./lib/fonts";

interface IItem extends IUseInputPropsResult {
  label: string;
}

type IItems = IItem[];

type IInputRadioListDisplayMode = "list" | "inline";

export const InputRadioList = React.forwardRef(
  (
    {
      label,
      items = [],
      displayMode = "list",
    }: RadioProps & {
      label?: string | React.ReactNode;
      items?: IItems;
      displayMode?: IInputRadioListDisplayMode;
    },
    ref,
  ) => {
    return (
      <Container displayMode={displayMode}>
        {!!label && <Label displayMode={displayMode}>{label}</Label>}
        <RadioGroup innerRef={ref as any}>
          {items.map(
            ({ value, checked, label: radioLabel, disabled, onChange }, i) => (
              <FormControlLabel
                key={i}
                value={value}
                control={<Radio />}
                label={radioLabel}
                disabled={disabled}
                checked={checked}
                onChange={onChange}
              />
            ),
          )}
        </RadioGroup>
      </Container>
    );
  },
);

const Container = styled.div<{ displayMode: IInputRadioListDisplayMode }>`
  ${(p) =>
    p.displayMode === "inline" &&
    css`
      display: flex;
      align-items: center;
    `}
`;

const Label = styled.div<{ displayMode: IInputRadioListDisplayMode }>`
  > * {
    margin: 0;
  }

  ${(p) =>
    p.displayMode === "list" &&
    css`
      ${fontFamily(ffdinPro.medium)}
      margin-bottom: 10px;
    `}
`;
