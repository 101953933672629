import * as React from "react";
import styled from "styled-components";
const SvgYoutube16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M37.5 68.75v-37.5L68.75 50zm49.07-57.94Q66 6.25 50 6.25t-36.57 4.56a12.5 12.5 0 00-9.37 9 114.53 114.53 0 000 60.47 12.5 12.5 0 009.37 9Q34 93.75 50 93.75t36.57-4.56a12.5 12.5 0 009.37-9A116.06 116.06 0 00100 50a116.05 116.05 0 00-4.06-30.24 12.5 12.5 0 00-9.37-8.95z" />
  </svg>
);
export default styled(SvgYoutube16)``;
