import React from "react";
import styled, { css } from "styled-components";
import { IconPicture16, IconCheck10 } from "./icons-ts";
import { colors } from "./lib/constants";
import { IUploadZoneFile } from "./UploadZone";
import { mediaQuery } from "react-styled-mediaquery";

export const UploadZoneThumbnails = ({
  files,
  onSelectFile,
  selectable,
  thumbnailSize,
  itemWrap,
}: {
  files: { preview?: string; selected?: boolean }[];
  onSelectFile?: (file: IUploadZoneFile, checked: boolean) => void;
  selectable?: boolean;
  thumbnailSize?: number;
  itemWrap?: number;
}) => (
  <ThumbnailsContainer>
    {files.map((file, i) => (
      <Thumb
        key={i}
        image={file.preview}
        onCheck={
          selectable && onSelectFile
            ? () => onSelectFile(file, !file.selected)
            : undefined
        }
        selected={!!file.selected}
        selectable={!!(selectable && onSelectFile)}
        size={thumbnailSize}
        itemWrap={itemWrap}
      />
    ))}
  </ThumbnailsContainer>
);

export const Thumb = styled(
  ({
    image,
    onCheck,
    selected,
    className,
    style,
    noTag,
  }: {
    single?: boolean;
    image?: string;
    onCheck?: () => void;
    selected?: boolean;
    selectable?: boolean;
    size?: number;
    style?: React.CSSProperties;
    noTag?: boolean;
    itemWrap?: number;
  } & React.HTMLAttributes<HTMLDivElement>) => (
    <div className={className} style={style}>
      {!image ? (
        <IconPicture16 width={16} height={16} fill={colors.light} />
      ) : (
        <ThumbImage
          image={image}
          onClick={onCheck}
          selected={selected}
          noTag={noTag}
        />
      )}
    </div>
  ),
)`
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  margin-bottom: 15px;
  margin-right: 15px;
  border: 1px solid ${colors.lightLt};

  ${(p) =>
    p.single
      ? css`
          width: ${() => (p.size ? p.size + "px" : "80px")};
          height: ${() => (p.size ? p.size + "px" : "80px")};
          margin-right: 30px;
          margin-bottom: 0;
        `
      : css`
          width: ${() => (p.size ? p.size + "px" : "110px")};
        `}

  ${(p) =>
    p.image &&
    p.selected &&
    css`
      border: 2px solid ${colors.primaryDk};
    `}

    ${(p) =>
      p.image &&
      p.selectable &&
      css`
        cursor: pointer;
      `}

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  &:last-child {
    margin-right: 0;
  }

  ${(p) =>
    p.itemWrap &&
    css`
      &:nth-child(${p.itemWrap}n) {
        margin-right: 0;
      }
    `}

  ${mediaQuery("<", "tablet")`
    flex-basis: calc(88% / 5);
    margin-right: 3%;
  `}

  ${mediaQuery("<=", "mobile")`
    flex-basis: calc(94% / 3);
    &:nth-child(5n) {
      margin-right: 3%;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  `}
`;

const ThumbImage = styled(
  ({
    image,
    onClick,
    selected,
    noTag,
    ...props
  }: {
    image?: string;
    onClick?: () => void;
    selected?: boolean;
    noTag?: boolean;
  }) => (
    <div
      {...props}
      style={{ backgroundImage: `url(${image})` }}
      onClick={onClick}
    >
      {!noTag && <Tag on={!!selected} />}
    </div>
  ),
)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-image 0.2s, transform 0.2s;
  box-sizing: border-box;
  border-radius: 20px;
  ${(p) =>
    p.selected &&
    css`
      transform: scale(0.98);
    `}
`;

const Tag = styled(({ on, ...props }: { on?: boolean }) => (
  <div {...props}>
    <IconCheck10 fill="#fff" width={10} height={8} />
  </div>
))`
  position: absolute;
  top: 10px;
  right: 10px;
  background: ${(p) => (p.on ? colors.primaryDk : "#fff")};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ThumbnailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
