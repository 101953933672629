import fastDeepEqual from "fast-deep-equal";
import { get, set } from "lodash";
import React, { Fragment, ReactNode, useCallback, useContext } from "react";
import {
  AnyObject,
  FieldInputProps,
  Form as FinalForm,
  FormProps,
  FormRenderProps,
  useField,
  UseFieldConfig,
  useFormState,
} from "react-final-form";
import { Schema } from "yup";
import { FormApi, SubmissionErrors } from "final-form";

export { useForm } from "react-final-form";

type IFieldLabels<T = AnyObject> = {
  [k in keyof T]?: JSX.Element | string | IFieldLabels<T[k]>;
};
type IValidationErrors<T = AnyObject> = {
  [k in keyof T]?: JSX.Element | string | IValidationErrors<T[k]>;
};

const formatValidateMessage = (
  path: string,
  message: string,
  fieldLabels?: IFieldLabels,
) => (
  <>
    {message.split(path).map((v, i) => (
      <Fragment key={i}>
        {i !== 0 && ((fieldLabels && get(fieldLabels, path)) || path)}
        {v}
      </Fragment>
    ))}
  </>
);

async function validateSchema<T>(
  values: T,
  schema: Schema<T>,
  fieldLabels?: IFieldLabels,
) {
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err) {
    return (err as any).inner.reduce((errors: any, { path, message }: any) => {
      if (errors.hasOwnProperty(path)) {
        set(errors, path, [
          ...get(errors, path),
          formatValidateMessage(path, message, fieldLabels),
        ]);
      } else {
        set(errors, path, [formatValidateMessage(path, message, fieldLabels)]);
      }
      return errors;
    }, {});
  }

  return {};
}

const FormRender = ({ render }: { render: () => ReactNode }) => <>{render()}</>;

const CustomFormContext = React.createContext<{
  loading?: boolean;
  errors?: IValidationErrors;
  fieldLabels?: IFieldLabels;
}>({
  loading: undefined,
  errors: {},
});

export function Form<
  FormValues extends Record<string, any> = Record<string, any>
>({
  render,
  loading,
  schema,
  fieldLabels,
  errors,
  validate: baseValidate,
  ...props
}: Omit<FormProps<FormValues>, "render"> & {
  render: (
    p: FormRenderProps<FormValues> & {
      useInputProps: (
        p: IUseInputProps<keyof FormValues>,
      ) => IUseInputPropsResult;
    },
  ) => ReactNode;
  loading?: boolean;
  schema?: Schema<Partial<FormValues>>;
  fieldLabels?: IFieldLabels<FormValues>;
  errors?: IValidationErrors<FormValues>;
  onSubmit: (
    values: FormValues,
    form: FormApi<FormValues>,
    callback?: (errors?: SubmissionErrors) => void,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
}) {
  const validate = useCallback(
    (values: FormValues) => {
      if (baseValidate) {
        return baseValidate(values);
      } else if (schema) {
        return validateSchema(values, schema, fieldLabels);
      } else {
        return;
      }
    },
    [baseValidate, schema, fieldLabels],
  );

  return (
    <CustomFormContext.Provider value={{ loading, fieldLabels, errors }}>
      <FinalForm
        {...props}
        validate={validate}
        render={(p) => (
          <FormRender render={() => render({ ...p, useInputProps } as any)} />
        )}
        initialValuesEqual={fastDeepEqual}
      />
    </CustomFormContext.Provider>
  );
}

interface IUseInputProps<T> {
  name: T;
  config?: UseFieldConfig<any>;
  onChange?: () => void;
  disabled?: boolean;
}

export interface IUseInputPropsResult
  extends FieldInputProps<any, HTMLElement> {
  disabled?: boolean;
  error: boolean;
  helperText?: string;
  label: JSX.Element | string;
}

export const useInputProps = <T extends string = string>({
  name,
  config,
  onChange,
  disabled,
}: IUseInputProps<T>): IUseInputPropsResult => {
  const field = useField(name, {
    ...config,
  });
  const customForm = useContext(CustomFormContext);

  const error =
    (customForm.errors && get(customForm.errors, name)) ||
    (field.meta.touched && field.meta.error) ||
    (!field.meta.dirtySinceLastSubmit && field.meta.submitError);

  const loading = customForm.loading || field.meta.submitting;

  const label =
    (customForm.fieldLabels && get(customForm.fieldLabels, name)) || "";

  return {
    ...field.input,
    onChange: (e: any) => {
      field.input.onChange(e);
      if (onChange) {
        onChange();
      }
    },
    disabled: loading || disabled,
    error: !!error,
    helperText: error,
    label: typeof label === "object" ? "" : label,
  };
};

export const useButtonProps = (disabled?: boolean) => ({
  disabled: useFormState().submitting || disabled,
});
