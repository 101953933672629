import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Button } from "./Button";
import { IconCross10 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { IReactChildren } from "./types";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid ${colors.lightLt};
  align-items: center;
`;

const Close = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
  color: ${colors.primary};
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color .2s;

  svg { transition: fill .2s; }

   &:hover {
    color: ${colors.primaryDk};

    svg {
      fill: ${colors.primaryDk};
    }
  }

  > svg {
    display: block;
    margin-right: 10px;
  }
`;

export const ModalFooter = ({
  onClick,
  onClose,
  buttonType,
  disabled,
  closeLabel,
  clickLabel,
}: {
  onClick?: () => void;
  onClose: () => void;
  buttonType?: "button" | "submit" | "reset";
  disabled?: boolean;
  closeLabel?: IReactChildren;
  clickLabel?: IReactChildren;
}) => (
  <Container>
    <Close onClick={onClose}>
      <IconCross10 fill={colors.primary} width={10} height={10} />{" "}
      {closeLabel || (
        <FormattedMessage id="button.label.cancel" defaultMessage="Annuler" />
      )}
    </Close>
    <div>
      <Button onClick={onClick} type={buttonType} disabled={disabled}>
        {clickLabel || (
          <FormattedMessage
            id="button.label.save"
            defaultMessage="Sauvegarder"
          />
        )}
      </Button>
    </div>
  </Container>
);
