import * as React from "react";
import styled from "styled-components";
const SvgFemale16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M56.25 12.5A31.25 31.25 0 1087.5 43.75 31.25 31.25 0 0056.25 12.5zm0-12.5a43.75 43.75 0 11-26.13 78.85 5.39 5.39 0 01-.77 1l-4.58 4.58L29.35 89a6.47 6.47 0 01-9.15 9.1l-4.58-4.58L11 98.1A6.47 6.47 0 011.9 89l4.58-4.58L1.9 79.8a6.47 6.47 0 019.1-9.15l4.58 4.58 4.58-4.58a6.51 6.51 0 011-.79A43.74 43.74 0 0156.25 0z" />
  </svg>
);
export default styled(SvgFemale16)``;
