import * as React from "react";
import styled from "styled-components";
const SvgClose80 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 6.25"
    {...props}
  >
    <path d="M3.13 0h93.75a3.13 3.13 0 010 6.25H3.13a3.13 3.13 0 010-6.25z" />
  </svg>
);
export default styled(SvgClose80)``;
