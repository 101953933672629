import * as React from "react";
import styled from "styled-components";
const SvgVet16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M87.5 68.23a12.48 12.48 0 10-18.75 10.61A9.4 9.4 0 0150 77.53V61.22a33.74 33.74 0 0025-31V12.41A12.76 12.76 0 0062.5 0a6.2 6.2 0 100 12.41v17.73a20.73 20.73 0 01-18.75 19.48A20.55 20.55 0 0125 31V12.41A6.2 6.2 0 1025 0a12.76 12.76 0 00-12.5 12.41V31a33.06 33.06 0 0025 30.27v16.26A21.89 21.89 0 0081.25 79a12.39 12.39 0 006.25-10.77z" />
  </svg>
);
export default styled(SvgVet16)``;
