import * as React from "react";
import styled from "styled-components";
const SvgCoin16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M52.07 25c7.2 0 11.49 2.56 15.09 6.35a5.24 5.24 0 011.45 3.59 5.08 5.08 0 01-5.12 5 4.82 4.82 0 01-3.67-1.59c-2.22-2.49-4.57-3.87-8-3.87-4.08 0-7.06 2.28-8.79 6.08h9.62a3.54 3.54 0 013.53 3.52 3.48 3.48 0 01-3.53 3.45H41.41c-.07.76-.07 1.59-.07 2.42s0 1.59.07 2.28h11.28a3.54 3.54 0 013.53 3.52 3.48 3.48 0 01-3.53 3.45h-9.76c1.73 4.07 4.91 6.35 9.35 6.35 3.46 0 5.68-1.45 8.1-4.21A4.59 4.59 0 0164 59.67a4.78 4.78 0 014.78 4.77 5.29 5.29 0 01-1 3C63.9 72.1 59.27 75 52.27 75c-10.59 0-17.79-6.35-20.56-15.81h-3.18A3.48 3.48 0 0125 55.73a3.54 3.54 0 013.53-3.52h2.08a21.42 21.42 0 01-.07-2.14c0-.83 0-1.73.07-2.56h-2.08A3.48 3.48 0 0125 44.06a3.54 3.54 0 013.53-3.52h3.39C34.76 31.42 41.82 25 52.07 25zM50 12.5A37.5 37.5 0 1087.5 50 37.5 37.5 0 0050 12.5zM50 0A50 50 0 110 50 50 50 0 0150 0z" />
  </svg>
);
export default styled(SvgCoin16)``;
