import * as React from "react";
import styled from "styled-components";
const SvgMail16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M81.25 18.75h-62.5A6.25 6.25 0 0013 22.54l28.49 24a13.24 13.24 0 0017 0l28.51-24a6.25 6.25 0 00-5.75-3.79zM87.5 39L67 56.31a26.48 26.48 0 01-34 0L12.5 39v36a6.25 6.25 0 006.25 6.25h62.5A6.25 6.25 0 0087.5 75zM81.25 6.25A18.75 18.75 0 01100 25v50a18.75 18.75 0 01-18.75 18.75h-62.5A18.75 18.75 0 010 75V25A18.75 18.75 0 0118.75 6.25z" />
  </svg>
);
export default styled(SvgMail16)``;
