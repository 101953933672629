import React from "react";
import styled, { css } from "styled-components";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";

const Container = styled.div<{ small?: boolean }>`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
  align-items: center;
  background: ${colors.lightLter};
  border-radius: 12.5px;
  display: inline-flex;
  height: 25px;
  justify-content: center;
  min-width: 25px;
  padding: 0 10px;

  ${p =>
    p.small &&
    css`
      ${fontSize("12px")}
    `}
`;

export const Caps = styled(
  ({
    children,
    small,
    className,
  }: {
    children: React.ReactNode;
    small?: boolean;
    className?: string;
  }) => (
    <Container className={className} small={!!small}>
      {children}
    </Container>
  ),
)``;

export const CapsList = styled.div<{ center?: boolean }>`
  display: flex;
  flex-wrap: wrap;

  ${p =>
    p.center &&
    css`
      justify-content: center;
    `}

  ${Caps} {
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
`;
