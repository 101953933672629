import * as React from "react";
import styled from "styled-components";
const SvgBurger16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M62.5 12.5a6.25 6.25 0 010 12.5H6.25a6.25 6.25 0 010-12.5zm31.25 31.25a6.25 6.25 0 010 12.5H6.25a6.25 6.25 0 010-12.5zM81.25 75a6.25 6.25 0 010 12.5h-75a6.25 6.25 0 010-12.5z" />
  </svg>
);
export default styled(SvgBurger16)``;
