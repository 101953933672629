import React from "react";
import { slide as Menu } from "react-burger-menu";
import { FormattedMessage } from "react-intl";
import { bmStyles, Header, Icon, NavLink } from "./LayoutNavigationMobile";
import { IService } from "./types";

export const LayoutServicesMobile = ({
  isOpen,
  onClose,
  services,
}: {
  isOpen: boolean;
  onClose: (e: React.KeyboardEvent) => void;
  services: IService[];
}) => (
  <Menu
    right
    customOnKeyDown={onClose}
    customCrossIcon={false}
    customBurgerIcon={false}
    width={"100%"}
    styles={bmStyles}
    isOpen={isOpen}
  >
    <Header>
      <FormattedMessage id="website.services.title" defaultMessage="Services" />
    </Header>
    {services.map((item, i) => (
      <div key={i}>
        <NavLink href={item.href} target="_blank" onClick={item.onClick}>
          <Icon>{item.icon}</Icon>
          <div>{item.label}</div>
        </NavLink>
      </div>
    ))}
  </Menu>
);
