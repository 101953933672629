import * as React from "react";
import styled from "styled-components";
const SvgCheck10 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 99.5"
    {...props}
  >
    <path d="M39.83 89.75h-.9a10.41 10.41 0 01-5.79-2.17l-1-.87L3.06 57.6a10.45 10.45 0 0113.79-15.65l1 .87 21.5 21.52 42.81-51.53a10.45 10.45 0 0114.78 14.78l-49.1 59.12a10.41 10.41 0 01-6.77 3h-1.24z" />
  </svg>
);
export default styled(SvgCheck10)``;
