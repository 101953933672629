import * as React from "react";
import styled from "styled-components";
const SvgLitter20 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M55 45h10a5 5 0 010 10H55v10a5 5 0 01-10 0V55H35a5 5 0 010-10h10V35a5 5 0 0110 0zm25.15-21.29A40 40 0 1090 50a5 5 0 0110 0 50 50 0 11-12.23-32.77l-.71.71a5 5 0 11-6.91 5.77z" />
  </svg>
);
export default styled(SvgLitter20)``;
