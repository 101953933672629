import React from "react";
import styled from "styled-components";
import { IconPen16 } from "./icons-ts";
import { colors } from "./lib/constants";

const Icon = styled(IconPen16)`
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
`;

export const IconEdit = ({ onClick }: { onClick?: () => void }) => (
  <Icon onClick={onClick} width={16} height={16} fill={colors.primary} />
);
