import React from "react";
import posed from "react-pose";
import styled from "styled-components";
import { IconBurger16, IconCross16, IconServices16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { LogicalLink } from "./LogicalLink";
import { Wrapper } from "./Wrapper";
import logoCrown from "../assets/crown.svg";

const Container = styled.div`
  background: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.lightLt};
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  display: block;
`;

const Posed = posed.div({
  on: {
    opacity: 1,
    applyAtStart: { display: "block" },
  },
  off: {
    opacity: 0,
    applyAtEnd: { display: "none" },
  },
});

const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
`;

export const LayoutHeaderMobile = ({
  onNavigationOpen,
  onNavigationClose,
  onServicesOpen,
  onServicesClose,
  isNavigationMobileOpen,
  isServicesMobileOpen,
}: {
  onNavigationOpen: () => void;
  onNavigationClose: () => void;
  onServicesOpen: () => void;
  onServicesClose: () => void;
  isNavigationMobileOpen: boolean;
  isServicesMobileOpen: boolean;
}) => (
  <Container>
    <Wrapper>
      <Inner>
        <IconContainer>
          <Posed
            style={{ position: "relative", zIndex: 2 }}
            onClick={onNavigationClose}
            pose={isNavigationMobileOpen ? "on" : "off"}
          >
            <IconCross16
              style={{ display: "block" }}
              width={16}
              height={16}
              color={colors.darkDk}
            />
          </Posed>
          <Posed
            onClick={onNavigationOpen}
            style={{ position: "absolute", top: 0, left: 0, zIndex: 1 }}
            pose={isNavigationMobileOpen ? "off" : "on"}
          >
            <IconBurger16
              style={{ display: "block" }}
              width={16}
              height={12}
              fill={colors.darkDk}
            />
          </Posed>
        </IconContainer>
        <LogicalLink to="/information">
          <Logo src={logoCrown} />
        </LogicalLink>
        <IconContainer>
          <Posed
            style={{ position: "relative", zIndex: 2 }}
            onClick={onServicesClose}
            pose={isServicesMobileOpen ? "on" : "off"}
          >
            <IconCross16
              style={{ display: "block" }}
              width={16}
              height={16}
              color={colors.darkDk}
            />
          </Posed>
          <Posed
            onClick={onServicesOpen}
            style={{ position: "absolute", top: 0, left: 0, zIndex: 1 }}
            pose={isServicesMobileOpen ? "off" : "on"}
          >
            <IconServices16
              onClick={onServicesOpen}
              width={16}
              height={16}
              fill={colors.darkDk}
            />
          </Posed>
        </IconContainer>
      </Inner>
    </Wrapper>
  </Container>
);
