import * as React from "react";
import styled from "styled-components";
const SvgKit60 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M73.8 56.67h20.62l-8.08-15H66.88zm-68.22 0h47.29l6.92-15H13.66zm46.09-35h5a1.67 1.67 0 010 3.33h-5v5a1.67 1.67 0 01-3.33 0v-5h-5a1.67 1.67 0 010-3.33h5v-5a1.67 1.67 0 013.33 0zM70.21 35h-4a20 20 0 10-32.49 0h-4a23.33 23.33 0 1140.42 0zM31.67 96.67H45V83l-1.67.56-5-1.67-5 1.67-1.66-.56zM85 60H71.67L65 45.56v51.11h13.33A6.67 6.67 0 0085 90zm-73.33 0H0l11.67-21.67h76.66L100 60H88.33v30a10 10 0 01-10 10H21.67a10 10 0 01-10-10zM15 60v30a6.67 6.67 0 006.67 6.67h6.67V78.33l5 1.67 5-1.67 5 1.67 5-1.67v18.34h13.33V45.56L55 60z" />
  </svg>
);
export default styled(SvgKit60)``;
