import * as React from "react";
import styled from "styled-components";
const SvgGroom16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M94.14 14.63l-1.93-2L82 2.36a6.18 6.18 0 00-6-1.65 6.26 6.26 0 00-4.42 4.43 6.34 6.34 0 001.59 6.09l10.2 10.32 1.93 2a5.58 5.58 0 010 7.8l-4.85 4.85L63 18.53a6.17 6.17 0 00-8.44.33 6.34 6.34 0 00-.33 8.54l17.46 17.67L63 53.88 45.5 36.39a6.17 6.17 0 00-8.44.33 6.34 6.34 0 00-.33 8.54l17.48 17.68-8.71 8.81L28 54a6.24 6.24 0 00-8.89-.13 6.41 6.41 0 00-.12 9l17.68 17.56-4.85 4.91a5.49 5.49 0 01-7.71 0l-1.93-2-11.69-11.66a6.17 6.17 0 00-8.13.52 6.34 6.34 0 00-.64 8.22l11.69 11.83 1.93 2a17.77 17.77 0 0025.31 0L50.1 85l44.29-44.77a18.26 18.26 0 00-.25-25.6z" />
  </svg>
);
export default styled(SvgGroom16)``;
