import * as React from "react";
import styled from "styled-components";
const SvgUser20 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M50 10a20 20 0 1020 20 20 20 0 00-20-20zm0-10a30 30 0 11-30 30A30 30 0 0150 0zm28.93 80H21.07c-3.5 0-6.07 2.4-6.07 5s2.57 5 6.07 5h57.86c3.5 0 6.07-2.4 6.07-5s-2.57-5-6.07-5zm0-10C87.8 70 95 76.72 95 85s-7.2 15-16.07 15H21.07C12.2 100 5 93.28 5 85s7.2-15 16.07-15z" />
  </svg>
);
export default styled(SvgUser20)``;
