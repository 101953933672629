import * as React from "react";
import styled from "styled-components";
const SvgWorld20 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path
      d="M95 45H74.88c-.61-12.18-3.41-23-7.58-31.06a40.19 40.19 0 0112.85 9.77A5 5 0 0090 22.5c0-2-2.23-5.27-2.23-5.27A50 50 0 10100 50a5 5 0 00-5-5M50 90c-2.24 0-5.6-2.84-8.73-9.11C37.89 74.14 35.7 65 35.15 55h29.7c-.56 10-2.75 19.14-6.12 25.89C55.6 87.16 52.24 90 50 90m8.73-70.89C62.1 25.85 64.3 35 64.85 45h-29.7c.55-10 2.74-19.15 6.12-25.89C44.4 12.83 47.76 10 50 10s5.6 2.83 8.73 9.11M32.69 14c-4.17 8-7 18.86-7.56 31H10.35a40 40 0 0122.34-31M10.35 55h14.78c.6 12.16 3.39 23 7.56 31a40 40 0 01-22.34-31m57 31c4.16-8 7-18.86 7.56-31h14.74a40 40 0 01-22.33 31"
      fillRule="evenodd"
    />
  </svg>
);
export default styled(SvgWorld20)``;
