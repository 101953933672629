import * as React from "react";
import styled from "styled-components";
const SvgBarcode16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 99"
    {...props}
  >
    <path d="M68.75 12A6.25 6.25 0 0175 18.25V62a6.25 6.25 0 01-12.5 0V18.25A6.25 6.25 0 0168.75 12zM50 12a6.25 6.25 0 016.25 6.25V62a6.25 6.25 0 01-12.5 0V18.25A6.25 6.25 0 0150 12zm-18.75 0a6.25 6.25 0 016.25 6.25V62A6.25 6.25 0 1125 62V18.25A6.25 6.25 0 0131.25 12zm37.5 62.5a6.25 6.25 0 010 12.5h-37.5a6.25 6.25 0 010-12.5zm25-62.5a6.25 6.25 0 016.25 6.25v62.5a6.25 6.25 0 11-12.5 0v-62.5A6.25 6.25 0 0193.75 12zm-87.5 0a6.25 6.25 0 016.25 6.25v62.5a6.25 6.25 0 01-12.5 0v-62.5A6.25 6.25 0 016.25 12z" />
  </svg>
);
export default styled(SvgBarcode16)``;
