import React from "react";
import { FormattedMessage } from "react-intl";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { Button } from "./Button";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { Media } from "./lib/media";
import { WarningBox } from "./WarningBox";

const ContainerDesktop = styled.div`
  margin-bottom: 60px;
  position: relative;
  margin-top: 30px;

  ${mediaQuery("<=", "phablet")`
    display: none;
  `}
`;

const ContainerMobile = styled.div`
  margin-bottom: 15px;
`;

const NavMobile = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
`;

const Title = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("24px")}
  line-height: 29px;
  letter-spacing: -0.5px;
  text-align: center;
  flex: 1;
`;

const Back = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const Submit = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export interface LittersAddHeaderProps {
  backLink?: React.ReactNode;
  children?: React.ReactNode;
  submitDisabled?: boolean;
  submitButton?: boolean;
  submitLabel?: string | JSX.Element;
  warningMessage?: string | JSX.Element;
}

export const LittersAddHeader = ({
  backLink,
  children,
  submitDisabled,
  submitButton = true,
  submitLabel,
  warningMessage,
}: LittersAddHeaderProps) => (
  <>
    <Media when={["mobile", "phablet"]}>
      <ContainerMobile>
        <NavMobile>
          {backLink}
          {submitButton && (
            <Button type="submit" disabled={submitDisabled}>
              {submitLabel || (
                <FormattedMessage
                  id="button.label.save"
                  defaultMessage="Sauvegarder"
                />
              )}
            </Button>
          )}
        </NavMobile>
        {!!children && <Title>{children}</Title>}
      </ContainerMobile>
    </Media>

    <Media when="desktop">
      <ContainerDesktop>
        {backLink && <Back>{backLink}</Back>}
        <Title>{children}</Title>
        {submitButton && (
          <Submit>
            <Button type="submit" disabled={submitDisabled}>
              {submitLabel || (
                <FormattedMessage
                  id="button.label.save"
                  defaultMessage="Sauvegarder"
                />
              )}
            </Button>
          </Submit>
        )}
      </ContainerDesktop>
    </Media>

    {!!warningMessage && <WarningBox>{warningMessage}</WarningBox>}
  </>
);
