import { dataIdFromObject } from "@igloo-be-omnipartners/hooks";
import { Button } from "@material-ui/core";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  defaultDataIdFromObject,
  ApolloLink,
  HttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import fetch from "isomorphic-fetch";
import { OptionsObject, useSnackbar } from "notistack";
import * as React from "react";

const makePersistingError = (action: any): OptionsObject => ({
  action: (key) => (
    <>
      <Button size="small" onClick={() => action(key)} color="inherit">
        Fermer
      </Button>
    </>
  ),
  persist: true,
  variant: "error",
});

const createClient = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const uri = `/api/graphql`;
  const onErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(
          `Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
      graphQLErrors.map(({ message, locations, path }) => {
        enqueueSnackbar(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          {
            ...makePersistingError(closeSnackbar),
          },
        );
      });
    }

    if (networkError) {
      const bodyText: string = (networkError as any).bodyText;
      const response: Response = (networkError as any).response;
      if (bodyText && response) {
        try {
          JSON.parse(bodyText);
        } catch (e) {
          // If not replace parsing error message with real one
          networkError.message = `${response.status}: ${response.statusText}`;
        }
      }

      console.error(
        `[Network error]: ${networkError.name}\n${networkError.message}`,
      );
      enqueueSnackbar(`[Network error]: ${networkError}`, {
        ...makePersistingError(closeSnackbar),
      });
    }
  });

  const link = ApolloLink.from([
    onErrorLink,
    new RetryLink(),
    new HttpLink({
      fetch,
      headers: {},
      uri,
    }),
  ]);

  return new ApolloClient({
    cache: new InMemoryCache({
      dataIdFromObject: (value: any) => {
        return dataIdFromObject(value) || defaultDataIdFromObject(value);
      },
    }),
    connectToDevTools: process.env.NODE_ENV !== "production",
    link,
  });
};

export const GraphQLProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const client = createClient();
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
