import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Button } from "./Button";
import { colors } from "./lib/constants";
import { PartnerSelector } from "./PartnerSelector";
import { Wrapper } from "./Wrapper";
import { Link } from "./Link";
import logoRC from "../assets/royalcanin.svg";
import { IconUser16, IconKey20 } from "./icons-ts";
import { Item as ItemNavigation } from "./LayoutNavigation";
import { Tooltip } from "./Tooltip";
import { LogicalLink } from "./LogicalLink";
import { LockOutlined as IconLogout } from "@material-ui/icons";

// Fix for IE11
const WrapperCustom = styled(Wrapper)`
  flex: 1 1 auto;
  width: 100%;
`

export const LayoutHeader = ({
  onClickPartnerSelector,
  onClickLogout,
  partner,
  onOpenMyCredentials,
}: {
  onClickPartnerSelector: () => void;
  onClickLogout: () => void;
  partner?: {
    type: string;
    name: string | null;
  };
  onOpenMyCredentials: () => void;
}) => (
  <Container>
    <WrapperCustom>
      <Inner>
        <PartnerSelector
          type={partner && partner.type.toLowerCase()}
          label={partner && partner.name}
          onClick={onClickPartnerSelector}
        />
        <div>
          <Link href="/information" borderless>
            <img src={logoRC} />
          </Link>
        </div>
        <RightItems>
          <UserPanel
            onClickLogout={onClickLogout}
            onOpenMyCredentials={onOpenMyCredentials}
          />
          <Button theme="bordered" to="/contact">
            <FormattedMessage
              id="website.header.delegate"
              defaultMessage="Mon délégué"
            />
          </Button>
        </RightItems>
      </Inner>
    </WrapperCustom>
  </Container>
);

const UserPanel = styled(
  ({
    className,
    onClickLogout,
    onOpenMyCredentials,
  }: {
    className?: string;
    onClickLogout: () => void;
    onOpenMyCredentials: () => void;
  }) => {
    const [panelIsOpen, setPanelIsOpen] = useState(false);
    return (
      <div className={className}>
        <Tooltip
          visible={panelIsOpen}
          placement="bottom"
          content={
            <UserPanelMenu
              onClickLogout={onClickLogout}
              onClose={() => {
                setPanelIsOpen(false);
              }}
              onOpenMyCredentials={onOpenMyCredentials}
            />
          }
          menu
          onHidden={() => setPanelIsOpen(false)}
          interactive
          arrow
        >
          <div>
            <Tooltip
              placement="bottom"
              content={
                <FormattedMessage
                  id="userPanel.nav.myProfile"
                  defaultMessage="Mon profil"
                />
              }
              caps
            >
              <LogicalLink
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setPanelIsOpen(true);
                }}
              >
                <ItemNavigation active size={40}>
                  <IconUser16 width={16} height={18} fill={colors.darkDk} />
                </ItemNavigation>
              </LogicalLink>
            </Tooltip>
          </div>
        </Tooltip>
      </div>
    );
  },
)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;

const UserPanelMenuItem = styled.div`
  display: flex;
  align-items: center;
`;

const UserPanelMenu = styled(
  ({
    className,
    onClickLogout,
    onClose,
    onOpenMyCredentials,
  }: {
    className?: string;
    onClickLogout: () => void;
    onClose: () => void;
    onOpenMyCredentials: () => void;
  }) => {
    return (
      <div className={className}>
        <UserPanelMenuItem>
          <Link
            borderless
            dark
            prefix={<IconKey20 width={20} height={20} color={colors.dark} />}
            onClick={(e) => {
              e.preventDefault();
              onOpenMyCredentials();
              onClose();
            }}
          >
            <FormattedMessage
              id="userPanel.nav.myCredentials"
              defaultMessage="Mes identifiants"
            />
          </Link>
        </UserPanelMenuItem>
        <UserPanelMenuItem>
          <Link
            borderless
            dark
            prefix={<IconLogout width={16} height={21} />}
            onClick={(e) => {
              e.preventDefault();
              onClickLogout();
            }}
          >
            <FormattedMessage
              id="button.label.logout"
              defaultMessage="Déconnexion"
            />
          </Link>
        </UserPanelMenuItem>
      </div>
    );
  },
)`
  ${UserPanelMenuItem}:not(:first-child) {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid ${colors.lightLt};
  }
`;

const Container = styled.div`
  height: 90px;
  background: #ffffff;
  border-bottom: 1px solid ${colors.lightLt};
  display: flex;
  align-items: center;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RightItems = styled.div`
  display: flex;
  align-items: center;
`;
