import { omit } from "lodash";
import React from "react";
import { mapProps } from "recompose";
import styled, { css } from "styled-components";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { ILogicalLinkProps, LogicalLink } from "./LogicalLink";

const Icon = styled.div<{ dark: boolean }>`
  svg {
    fill: ${colors.primary};
    transition: fill 0.2s;

    ${p =>
      p.dark &&
      css`
        fill: ${colors.darkDk};
      `}
  }
`;

const LinkContainer = styled(
  mapProps((p: ILogicalLinkProps) =>
    omit(p, ["dark", "prefix", "suffix", "light", "borderless"]),
  )(LogicalLink),
)<{
  dark?: boolean;
  light?: boolean;
  prefix?: boolean;
  suffix?: boolean;
  borderless?: boolean;
}>`
  color: ${colors.primary};
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s, border-color 0.2s;
  display: inline-block;

  ${p =>
    !p.borderless &&
    css`
      border-bottom: 1px solid ${colors.primaryLt};
    `}

  ${p =>
    (p.prefix || p.suffix) &&
    css`
      display: inline-flex;
      align-items: center;
    `}

  &:hover {
    color: ${colors.primaryDk};

    ${Icon} svg {
      fill: ${colors.primaryDk};
    }

    ${p =>
      !p.borderless &&
      css`
        border-color: ${colors.primaryDk};
      `}
  }

  ${p =>
    p.dark &&
    css`
      color: ${colors.darkDk};
      border-color: ${colors.lightLt};

      &:hover {
        color: ${colors.primary};
    `}

  ${p =>
    p.light &&
    css`
        color: ${colors.dark};
        border-color: ${colors.lightLt};

        &:hover {
          color: ${colors.primary};
      `}
`;

export const Link = ({
  children,
  href,
  dark,
  light,
  prefix,
  suffix,
  onClick,
  className,
  borderless,
  target,
}: {
  children: React.ReactNode;
  href?: string;
  dark?: boolean;
  light?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  onClick?: (e: any) => void;
  className?: string;
  borderless?: boolean;
  target?: string;
}) => (
  <LinkContainer
    className={className}
    onClick={onClick}
    dark={!!dark}
    light={!!light}
    href={href}
    prefix={!!prefix}
    suffix={!!suffix}
    borderless={!!borderless}
    target={target}
  >
    {prefix && (
      <Icon style={{ marginRight: 10 }} dark={!!dark}>
        {prefix}
      </Icon>
    )}
    {children}
    {suffix && (
      <Icon style={{ marginLeft: 10 }} dark={!!dark}>
        {suffix}
      </Icon>
    )}
  </LinkContainer>
);
