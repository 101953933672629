import { LocaleValue } from "@royalcanin-be-partner-portal/settings";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Card } from "./Card";
import { CardHeader } from "./CardHeader";
import {
  CardOpeningHoursFormEdit,
  getWeekdays,
} from "./CardOpeningHoursFormEdit";
import { ffdinPro, fontFamily } from "./lib/fonts";
import { Modal } from "./Modal";
import { Placeholder } from "./Placeholder";

export const CardOpeningHours = ({
  locale,
  partner,
  loading,
  onSubmit,
}: {
  locale: LocaleValue;
  partner?: React.ComponentProps<
    typeof CardOpeningHoursFormEdit
  >["initialValues"];
  onSubmit: React.ComponentProps<typeof CardOpeningHoursFormEdit>["onSubmit"];
  loading?: boolean;
}) => {
  const [
    modalEditOpeningHoursIsOpen,
    setModalEditOpeningHoursIsOpen,
  ] = useState(false);
  const weekdays = getWeekdays(locale);

  return (
    <>
      <Modal
        maxWidth="m"
        title={
          <FormattedMessage
            id="informations.hours.title"
            defaultMessage="Horaires"
          />
        }
        open={modalEditOpeningHoursIsOpen}
        onClose={() => setModalEditOpeningHoursIsOpen(false)}
      >
        <CardOpeningHoursFormEdit
          locale={locale}
          onClose={() => setModalEditOpeningHoursIsOpen(false)}
          onSubmit={onSubmit}
          initialValues={partner}
        />
      </Modal>
      <Card>
        <CardHeader onClick={() => setModalEditOpeningHoursIsOpen(true)}>
          <FormattedMessage
            id="informations.hours.title"
            defaultMessage="Horaires"
          />
        </CardHeader>
        {loading || !partner ? (
          <Placeholder />
        ) : (
          <Table>
            <tbody>
              {weekdays.map((dayName, i) => {
                const hours = partner.openingHours?.find(
                  ({ dayIndex }) => dayIndex === i + 1,
                );
                const nbPriods = (!!hours && hours.periods.length) || 0;
                return (
                  <tr key={i}>
                    <td>
                      <Day>{dayName}</Day>
                    </td>
                    <td align="right" colSpan={nbPriods > 1 ? 1 : 2}>
                      {!!hours && nbPriods > 0 ? (
                        <>
                          <Highlight>{hours.periods[0].from}</Highlight>
                          <span> - </span>
                          <Highlight>{hours.periods[0].to}</Highlight>
                        </>
                      ) : (
                        <Highlight>
                          <FormattedMessage
                            id="informations.openingHour.closed"
                            defaultMessage="Fermé"
                          />
                        </Highlight>
                      )}
                    </td>
                    {!!hours && nbPriods > 1 && (
                      <td align="right">
                        <Highlight>{hours.periods[1].from}</Highlight>
                        <span> - </span>
                        <Highlight>{hours.periods[1].to}</Highlight>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </Card>
    </>
  );
};

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr:not(:first-child) td {
    padding-top: 15px;
  }
`;

const Highlight = styled.div`
  display: inline-block;
  ${fontFamily(ffdinPro.medium)}
`;

const Day = styled.span`
  text-transform: capitalize;
`;
