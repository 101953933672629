import gql from "graphql-tag";

export const RoleDefinitionFragment = gql`
  fragment RoleDefinitionFragment on RoleDefinition {
    id
    sectionEnabled
    sectionProductEnabled
    billingMailto
    litterKitsRestrictionPartnergroup
  }
`;

export const UserRoleFragment = gql`
  fragment UserRoleFragment on Role {
    isCurrentRole
    token
    name
    roleDefinition {
      ...RoleDefinitionFragment
    }
    partnerRelation {
      extId
      partner {
        extId
        name
        type
      }
    }
  }
  ${RoleDefinitionFragment}
`;
