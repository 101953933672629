import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import posed from "react-pose";
import styled, { css } from "styled-components";
import { Alert } from "./Alert";
import { Button } from "./Button";
import { IconUpload16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { fontSize } from "./lib/fonts";
import { Link } from "./Link";
import { UploadZoneThumbnails } from "./UploadZoneThumbnails";
import { FormattedMessage } from "react-intl";
import { mediaQuery } from "react-styled-mediaquery";

export interface IUploadZoneFile {
  id?: string | number;
  file?: File;
  preview?: string;
}

export const Zone = ({
  isDragActive,
  isDragReject,
  isFileTooLarge,
  zoneHeight,
}: {
  isDragActive?: boolean;
  isDragReject?: boolean;
  isFileTooLarge?: boolean;
  zoneHeight?: number;
}) => (
  <ZoneContainer zoneHeight={zoneHeight}>
    {!isDragActive && (
      <>
        <IconUpload16 width={16} height={16} fill={colors.darkDk} />
        <FormattedMessage
          id="uploadZone.zone.text"
          defaultMessage="Glissez vos photos ici ou {link}"
          values={{
            link: (
              <Link dark>
                <FormattedMessage
                  id="uploadZone.zone.text.link"
                  defaultMessage="choisissez un fichier"
                />
              </Link>
            ),
          }}
        />
      </>
    )}
    {isDragActive && !isDragReject && (
      <>
        <IconUpload16 width={16} height={16} fill={colors.darkDk} />
        <FormattedMessage
          id="uploadZone.dragZone.text"
          defaultMessage="Déposer vos fichiers ici"
        />
      </>
    )}
    {isDragReject && (
      <div style={{ color: colors.warning }}>
        <FormattedMessage
          id="uploadZone.error.fileType"
          defaultMessage="Type de fichier non reconnu, uniquement .jpg ou .png"
        />
      </div>
    )}
    {isFileTooLarge && (
      <div
        style={{
          color: colors.warning,
          flexBasis: "100%",
          marginTop: 10,
        }}
      >
        <FormattedMessage
          id="uploadZone.error.fileSize"
          defaultMessage="Fichier trop lourd, max 1mb"
        />
      </div>
    )}
  </ZoneContainer>
);

export const UploadZone = ({
  files,
  setFiles,
  setFile,
  onDelete,
  maxFiles = 8,
  maxSize = 1048576,
  accept = "image/*",
  thumbnails = true,
  thumbnailSize,
  disabled,
  multiple,
  zoneStyle,
  zoneHeight,
  thumbnailItemWrap,
}: {
  files: IUploadZoneFile[];
  setFiles?: (files: IUploadZoneFile[]) => void;
  setFile?: (file: IUploadZoneFile) => void;
  onDelete?: (files: IUploadZoneFile[]) => void;
  maxSize?: number;
  maxFiles?: number;
  accept?: string;
  thumbnails?: boolean;
  thumbnailSize?: number;
  disabled?: boolean;
  multiple?: boolean;
  zoneStyle?: React.CSSProperties;
  zoneHeight?: number;
  thumbnailItemWrap?: number;
}) => {
  const [filesToDelete, setFilesToDelete] = useState<IUploadZoneFile[]>([]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    accept,
    maxSize,
    minSize: 0,
    onDrop: (acceptedFiles) => {
      if (setFiles) {
        setFiles(
          [
            ...files,
            ...acceptedFiles.map((file) => ({
              file,
              preview: URL.createObjectURL(file),
            })),
          ].slice(0, maxFiles),
        );
      }
      if (setFile && acceptedFiles.length) {
        const file = acceptedFiles.pop();
        setFile({
          file,
          preview: URL.createObjectURL(file as Blob),
        });
      }
    },
    disabled,
    multiple,
  });

  const isFileTooLarge =
    fileRejections.length > 0 && fileRejections[0].file.size > maxSize;

  const isSameFile = (
    file1: IUploadZoneFile,
    file2: IUploadZoneFile,
  ): boolean => {
    if (file1.id) {
      return file1.id === file2.id;
    }
    return file1.preview === file2.preview;
  };

  return (
    <>
      {!!thumbnails && (
        <>
          <div style={{ marginBottom: 25 }}>
            <UploadZoneThumbnails
              files={[
                ...files.map((f) => ({
                  ...f,
                  selected:
                    filesToDelete.findIndex((ftd) => isSameFile(ftd, f)) >= 0,
                })),
                ...Array(maxFiles - files.length).fill({}),
              ]}
              onSelectFile={(file, checked) => {
                if (checked) {
                  setFilesToDelete((prevFiles) => [...prevFiles, file]);
                } else {
                  setFilesToDelete((prevFiles) =>
                    prevFiles.filter((f) => !isSameFile(f, file)),
                  );
                }
              }}
              selectable={!!onDelete}
              thumbnailSize={thumbnailSize}
              itemWrap={thumbnailItemWrap}
            />
          </div>
          <AnimContainer pose={filesToDelete.length ? "on" : "off"}>
            <AlertRemove type="delete">
              <FormattedMessage
                id="uploadZone.removeMessage.text"
                defaultMessage="Voulez-vous supprimer les photos sélectionnées ?"
              />
              <AlertRemoveButton
                theme="primaryDk"
                mini
                onClick={() => {
                  if (filesToDelete.length) {
                    if (onDelete) {
                      onDelete(filesToDelete);
                    }
                    setFilesToDelete([]);
                  }
                }}
              >
                <FormattedMessage
                  id="uploadZone.removeMessage.buttonRemove"
                  defaultMessage="Supprimer"
                />
              </AlertRemoveButton>
            </AlertRemove>
          </AnimContainer>
        </>
      )}

      <AnimContainer
        pose={filesToDelete.length ? "off" : "on"}
        style={zoneStyle}
      >
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Zone
            isDragActive={isDragActive}
            isDragReject={isDragReject}
            isFileTooLarge={isFileTooLarge}
            zoneHeight={zoneHeight}
          />
        </div>
      </AnimContainer>
    </>
  );
};

const ZoneContainer = styled.div<{ zoneHeight?: number }>`
  ${fontSize("14px")}
  align-items: center;
  background: ${colors.lightLter};
  border-radius: 10px;
  border: 1px solid ${colors.lightLt};
  box-shadow: inset 0 0 0 3px #fff;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  text-align: center;
  transition: border-color 0.2s;
  flex: 1;
  ${(p) =>
    p.zoneHeight &&
    css`
      height: ${p.zoneHeight}px;
    `}

  &:hover {
    border-color: ${colors.dark};
  }

  svg {
    display: block;
    margin-right: 15px;
  }

  > span {
    margin-left: 5px;
  }
`;

const AlertRemoveButton = styled(Button)``;

const AnimContainer = posed.div({
  off: {
    height: 0,
    opacity: 0,
    overflow: "hidden",
  },
  on: {
    height: "auto",
    opacity: 1,
  },
});

const AlertRemove = styled(Alert)`
  align-items: center;
  margin-top: 20px;

  ${AlertRemoveButton} {
    margin-left: auto;
  }

  ${mediaQuery("<=", "mobile")`
    display: block;
    text-align: center;

    ${AlertRemoveButton} {
      display: block;
      margin: 15px auto 0;
    }
  `}
`;
