import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Card } from "./Card";
import { CardCoordinatesFormEdit, messages } from "./CardCoordinatesFormEdit";
import { CardHeader } from "./CardHeader";
import { CardIconRow } from "./CardIconRow";
import { Fieldset } from "./Fieldset";
import { IconMail16, IconShop16, IconPlane16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { countryName, formatPhone } from "./lib/utils";
import { Modal } from "./Modal";
import { Placeholder } from "./Placeholder";
import { ICountriesValue } from "./types";
import styled from "styled-components";
import { fontFamily, ffdinPro } from "./lib/fonts";

export const CardCoordinates = ({
  loading,
  partner,
  countries = [],
  onSubmit,
  autoOpenForm,
}: {
  loading?: boolean;
  countries?: ICountriesValue;
  partner?: React.ComponentProps<
    typeof CardCoordinatesFormEdit
  >["initialValues"];
  onSubmit: React.ComponentProps<typeof CardCoordinatesFormEdit>["onSubmit"];
  autoOpenForm?: boolean;
}) => {
  const [modalEditCoordinatesIsOpen, setModalEditCoordinatesIsOpen] = useState(
    false,
  );

  useEffect(() => {
    if (autoOpenForm) {
      setTimeout(() => {
        setModalEditCoordinatesIsOpen(true);
      }, 100);
    }
  }, [autoOpenForm]);

  return (
    <>
      <Modal
        maxWidth="m"
        title={
          <FormattedMessage
            id="website.informations.coordinates.title"
            defaultMessage="Mes coordonnées"
          />
        }
        open={modalEditCoordinatesIsOpen}
        onClose={() => setModalEditCoordinatesIsOpen(false)}
      >
        <CardCoordinatesFormEdit
          onClose={() => setModalEditCoordinatesIsOpen(false)}
          onSubmit={onSubmit}
          initialValues={partner}
          countries={countries}
          publicAddressDisplayPreferenceRequired={partner?.type === "breeders"}
        />
      </Modal>
      <Card>
        <CardHeader onClick={() => setModalEditCoordinatesIsOpen(true)}>
          <FormattedMessage
            id="login.coordonates.title"
            defaultMessage="Mes coordonnées"
          />
        </CardHeader>
        <CardIconRow
          icon={<IconShop16 fill={colors.darkDk} width={16} height={16} />}
          title={
            <FormattedMessage
              id="website.informations.coordinates.address"
              defaultMessage="Adresse"
            />
          }
        >
          {loading || !partner ? (
            <Placeholder />
          ) : (
            <div>
              {partner.address?.street1} {partner.address?.streetnum}
            </div>
          )}

          {loading || !partner ? (
            <>
              <Placeholder />
              <Placeholder />
            </>
          ) : (
            <>
              <Fieldset offset={5}>
                {partner.address?.postalCode} {partner.address?.city}
              </Fieldset>
              <Fieldset offset={5}>
                {countryName(countries, partner.address?.country || undefined)}
              </Fieldset>
            </>
          )}
        </CardIconRow>

        {partner?.type === "breeders" && (
          <CardIconRow
            offset
            icon={<IconPlane16 fill={colors.darkDk} width={16} height={16} />}
            title={
              <FormattedMessage
                id="website.informations.coordinates.formPreference.title"
                defaultMessage="Préférence"
              />
            }
          >
            <Fieldset offset={5}>
              {partner.publicAddressDisplayPreference === "city" ? (
                <FormattedMessage
                  {...messages.publicAddressDisplayPreferenceValueCity}
                />
              ) : (
                <FormattedMessage
                  {...messages.publicAddressDisplayPreferenceValueComplete}
                />
              )}
            </Fieldset>
          </CardIconRow>
        )}

        <CardIconRow
          offset
          icon={<IconMail16 fill={colors.darkDk} width={16} height={14} />}
          title={
            <FormattedMessage
              id="website.informations.coordinates.contact"
              defaultMessage="Contact"
            />
          }
        >
          {loading || !partner ? (
            <>
              <Placeholder />
              <Placeholder />
              <Placeholder />
            </>
          ) : (
            <>
              <div>{partner.email}</div>
              {!!partner.phone && (
                <Fieldset offset={5}>
                  <TextBold>Tel. :</TextBold> {formatPhone(partner.phone)}
                </Fieldset>
              )}
              {!!partner.fax && (
                <Fieldset offset={5}>
                  <TextBold>Fax :</TextBold> {formatPhone(partner.fax)}
                </Fieldset>
              )}
            </>
          )}
        </CardIconRow>
      </Card>
    </>
  );
};

const TextBold = styled.span`
  ${fontFamily(ffdinPro.bold)}
`;
