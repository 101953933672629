import * as React from "react";
import styled from "styled-components";
const SvgTwitter16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M31.25 90.62A58 58 0 010 81.42a41.67 41.67 0 004.87 0 41.13 41.13 0 0025.5-8.27 20.57 20.57 0 01-19.18-14.28 20.52 20.52 0 009.25-.38A20.53 20.53 0 014 38.08a20.41 20.41 0 009.31 2.57 20.63 20.63 0 01-6.25-27.42A58.21 58.21 0 0049.38 34.7a20.5 20.5 0 0134.93-18.78 41 41 0 0013-5 20.6 20.6 0 01-9 11.4A40.94 40.94 0 00100 18.86a41.73 41.73 0 01-10.25 10.46v2.63a58.06 58.06 0 01-58.5 58.67" />
  </svg>
);
export default styled(SvgTwitter16)``;
