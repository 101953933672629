import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { ICountriesValue } from "../types";

export const countryName = (countries: ICountriesValue, code?: string) => {
  if (!code) {
    return "";
  }
  const country = countries.find(({ value }) => value === code);
  if (country) {
    return country.label;
  }
  return code;
};

export const formatPhone = (value?: string) => {
  if (!value) {
    return "-";
  }
  if (!isValidPhoneNumber(value) && isValidPhoneNumber("+" + value)) {
    return formatPhoneNumberIntl("+" + value);
  }
  return value;
};
