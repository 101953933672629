import React from "react";
import ContentLoader from "react-content-loader";
import { FormattedMessage } from "react-intl";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { Caps, CapsList } from "./Caps";
import { Card } from "./Card";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import gql from "graphql-tag";
import { ProductCriteriaFragment as ProductFragment } from "./__generated__/ProductCriteriaFragment";
import { OmitRecursively } from "./types";

type IProductCriteria = OmitRecursively<ProductFragment, "__typename">;

export const CardProductsCriteria = ({
  product,
  loading,
}: {
  product?: IProductCriteria;
  loading?: boolean;
}) => {
  if (loading || !product) {
    return <ContentLoading />;
  }
  return (
    <Container>
      <Top>
        <TopCol>
          <div>
            <Muted>
              <FormattedMessage
                id="website.products.breeds"
                defaultMessage="Races"
              />
            </Muted>
            <Bold>
              {product.breeds.length ? (
                product.breeds.join(" ")
              ) : (
                <FormattedMessage
                  id="website.products.criteria.all"
                  defaultMessage="Tous"
                />
              )}
            </Bold>
          </div>
          <Item>
            <Muted>
              <FormattedMessage
                id="website.products.gender"
                defaultMessage="Genre"
              />
            </Muted>
            <Bold>
              {product.gender || (
                <FormattedMessage
                  id="website.products.criteria.all"
                  defaultMessage="Tous"
                />
              )}
            </Bold>
          </Item>
        </TopCol>
        <TopCol>
          <div>
            <Muted>
              <FormattedMessage
                id="website.products.stage"
                defaultMessage="Âge"
              />
            </Muted>
            <Bold>
              {product.stages.length ? (
                product.stages.join(" ")
              ) : (
                <FormattedMessage
                  id="website.products.criteria.all"
                  defaultMessage="Tous"
                />
              )}
            </Bold>
          </div>
          <Item>
            <Muted>
              <FormattedMessage
                id="website.products.neutered"
                defaultMessage="Stérilisation"
              />
            </Muted>
            <Bold>
              {product.neutered === "Yes" ? (
                <FormattedMessage id="yes" defaultMessage="Oui" />
              ) : product.neutered === "No" ? (
                <FormattedMessage id="no" defaultMessage="Non" />
              ) : (
                <FormattedMessage
                  id="website.products.criteria.all"
                  defaultMessage="Tous"
                />
              )}
            </Bold>
          </Item>
        </TopCol>
      </Top>
      <Bottom>
        <Muted>
          <FormattedMessage
            id="website.products.needs"
            defaultMessage="Besoins particuliers"
          />
        </Muted>
        <CapsList>
          {product.pathologies.map((item, i) => (
            <Caps key={i} small>
              {item}
            </Caps>
          ))}
        </CapsList>
        <Item>
          <Muted>
            <FormattedMessage
              id="website.products.counters"
              defaultMessage="Contre-indications"
            />
          </Muted>
          <CapsList>
            {product.contraindication.map((item, i) => (
              <Caps key={i} small>
                {item}
              </Caps>
            ))}
          </CapsList>
        </Item>
      </Bottom>
    </Container>
  );
};

const ContentLoading = () => (
  <Container>
    {Array(2)
      .fill("")
      .map((e, i) => (
        <ContentLoader height={130} width={400} key={i}>
          <circle cx="10" cy="20" r="8" />
          <rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
          <circle cx="10" cy="50" r="8" />
          <rect x="25" y="45" rx="5" ry="5" width="220" height="10" />
          <circle cx="10" cy="80" r="8" />
          <rect x="25" y="75" rx="5" ry="5" width="220" height="10" />
          <circle cx="10" cy="110" r="8" />
          <rect x="25" y="105" rx="5" ry="5" width="220" height="10" />
        </ContentLoader>
      ))}
  </Container>
);

export const ProductCriteriaFragment = gql`
  fragment ProductCriteriaFragment on Collection {
    breeds
    stages
    neutered
    gender
    pathologies
    contraindication
  }
`;

const Container = styled(Card)`
  ${mediaQuery("<=", "tablet")`
    margin-top: 15px;
  `}
`;

const Muted = styled.div`
  ${fontSize("14px")}
  margin-bottom: 10px;
  line-height: 17px;
  color: ${colors.dark};
`;

const Bold = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
`;

const Top = styled.div`
  display: flex;
`;

const TopCol = styled.div`
  width: 50%;
`;

const Item = styled.div`
  margin-top: 30px;
`;

const Bottom = styled.div`
  border-top: 1px solid ${colors.lightLt};
  padding-top: 30px;
  margin-top: 30px;
`;
