import React from "react";
import ContentLoaderBase from "react-content-loader";

export const ContentLoader = ({
  height,
  width,
}: {
  height: number;
  width: number;
}) => (
  <div style={{ width, height }}>
    <ContentLoaderBase height={height} width={width}>
      <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
    </ContentLoaderBase>
  </div>
);
