import GoogleMapReact, { Coords } from "google-map-react";
import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { Card } from "./Card";
import { CardHeader } from "./CardHeader";
import { IPartner } from "./types";
import { IconPointerMap } from "./icons-ts";
import { IconPartnerType } from "./IconPartnerType";
import { IErrorResult } from "./lib/validationUtils";
import { Modal } from "./Modal";
import { ModalFooter } from "./ModalFooter";

export const CardLocation = ({
  partner,
  onSubmit,
}: {
  partner?: Pick<IPartner, "lat" | "lng" | "type">;
  onSubmit: React.ComponentProps<typeof GoogleMap>["onSubmit"];
}) => (
  <Container>
    <CardHeader>
      <FormattedMessage
        id="informations.location.title"
        defaultMessage="Localisation"
      />
    </CardHeader>
    <MapContainer>
      <GoogleMap
        lat={partner && partner.lat ? parseFloat(partner.lat) : undefined}
        lng={partner && partner.lng ? parseFloat(partner.lng) : undefined}
        partnerType={(partner && partner.type) || undefined}
        onSubmit={onSubmit}
      />
    </MapContainer>
  </Container>
);

const GoogleMap = ({
  lat,
  lng,
  partnerType,
  onSubmit,
}: {
  lat?: number;
  lng?: number;
  partnerType?: string;
  onSubmit: (coords: Coords) => Promise<IErrorResult | null | void>;
}) => {
  const defaultCenter = {
    lat: 50.82,
    lng: 4.36,
  };
  const [draggable, setDraggable] = useState(true);
  const [center, setCenter] = useState<Coords | undefined>();
  const [markerCenter, setMarkerCenter] = useState<Coords | undefined>();
  const [coordsConfirmPosition, setCoordsConfirmPosition] = useState<
    Coords | undefined
  >();
  const [zoom, setZoom] = useState<number>(7);

  useEffect(() => {
    if (lat && lng) {
      setMarkerCenter({ lat, lng });
      setTimeout(() => {
        setCenter({ lat, lng });
        setZoom(16);
      }, 100);
    } else {
      setMarkerCenter(undefined);
      setCenter(defaultCenter);
      setZoom(7);
    }
  }, [lat, lng]);

  const onCancelPosition = () => {
    setCoordsConfirmPosition(undefined);
    setMarkerCenter(center);
    setDraggable(true);
  };

  return (
    <Map>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCfVzGfKCuSSOkC9Y08Pps0JXnl7i39yic" }}
        defaultCenter={defaultCenter}
        zoom={zoom}
        center={center}
        draggable={draggable}
        onChildMouseDown={(childKey, childProps, mouse) => {
          setDraggable(false);
        }}
        onChildMouseMove={(childKey, childProps, mouse) => {
          setMarkerCenter(mouse);
        }}
        onChildMouseUp={(childKey, childProps, mouse) => {
          setCoordsConfirmPosition(mouse);
        }}
      >
        {markerCenter && <Marker {...markerCenter} type={partnerType} />}
      </GoogleMapReact>
      <Modal
        maxWidth="sm"
        title={
          <FormattedMessage
            id="informations.location.title"
            defaultMessage="Localisation"
          />
        }
        open={!!coordsConfirmPosition}
        onClose={onCancelPosition}
      >
        <ModalConfirmPosition
          onCancel={onCancelPosition}
          onConfirm={() => {
            setCoordsConfirmPosition(undefined);
            setMarkerCenter(coordsConfirmPosition);
            setCenter(coordsConfirmPosition);
            if (coordsConfirmPosition) {
              onSubmit(coordsConfirmPosition);
            }
          }}
        />
      </Modal>
    </Map>
  );
};

const Map = styled.div`
  height: 293px;
  width: 100%;
`;

const MapContainer = styled.div`
  margin: -30px;
`;

const Container = styled(Card)`
  ${mediaQuery("<=", "960px")`
    margin-top: 15px;
  `}
`;

const Marker = styled(
  ({
    type,
    className,
  }: {
    type?: string;
    className?: string;
    lat: number;
    lng: number;
  }) => (
    <div className={className}>
      <IconPointerMap width={36} height={40} />
      {!!type && (
        <MarkerIconPartnerType>
          <IconPartnerType type={type} background="none" />
        </MarkerIconPartnerType>
      )}
    </div>
  ),
)`
  width: 36px;
  height: 40px;
  transform: translate(-50%, -100%);
  position: relative;
`;

const MarkerIconPartnerType = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalConfirmPosition = ({
  onCancel,
  onConfirm,
}: {
  onCancel: () => void;
  onConfirm: () => void;
}) => (
  <>
    <FormattedMessage
      id="informations.location.confirmMessage"
      defaultMessage="Vous venez de modifier la position du curseur. Désirez-vous enregistrer
    cette nouvelle position sur la carte ?"
    />
    <ModalFooter
      onClose={onCancel}
      onClick={onConfirm}
      closeLabel={
        <FormattedMessage
          id="informations.location.confirmMessage.no"
          defaultMessage="Non"
        />
      }
      clickLabel={
        <FormattedMessage
          id="informations.location.confirmMessage.yes"
          defaultMessage="Oui, c'est la bonne position"
        />
      }
    />
  </>
);
