import * as React from "react";
import styled from "styled-components";
const SvgCoupon20 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M17.38 49.3l-5.85 5.86a5.23 5.23 0 000 7.4l25.91 25.91a5.23 5.23 0 007.41 0l5.85-5.84zm81.1-47.78a5.18 5.18 0 010 7.32l-4.86 4.86A14.94 14.94 0 0195 20v26.28a15 15 0 01-4.4 10.61L78.53 69a5 5 0 01-6.6.41l-.47-.41a5 5 0 010-7.07l12.07-12.11A5 5 0 0085 46.28v-24l-6.16 6.17a5.18 5.18 0 01-7.32-7.32L77.67 15h-24a5 5 0 00-3.54 1.47L24.3 42.38 65 83.08 58.08 90l-.31-.31-5.85 5.85a15.23 15.23 0 01-21.54 0L4.46 69.63a15.23 15.23 0 010-21.54L43.11 9.4A15 15 0 0153.72 5H80a14.94 14.94 0 016.3 1.38l4.87-4.86a5.18 5.18 0 017.31 0z" />
  </svg>
);
export default styled(SvgCoupon20)``;
