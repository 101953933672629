import React from "react";
import ContentLoader from "react-content-loader";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "react-styled-flexboxgrid";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { Card } from "./Card";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { ProductItem } from "./ProductItem";
import { OmitRecursively } from "./types";
import gql from "graphql-tag";
import { ProductRelatedFragment as ProductFragment } from "./__generated__/ProductRelatedFragment";
import { Link } from "./Link";

type IProductRelated = OmitRecursively<ProductFragment, "__typename">;

export const CardProductsRelated = ({
  product,
  loading,
}: {
  product?: IProductRelated;
  loading?: boolean;
}) => {
  if (loading || !product) {
    return <ContentLoading />;
  }

  return (
    <Container>
      <Title>
        <FormattedMessage
          id="website.products.mixTitle"
          defaultMessage="Aliments à combiner"
        />
      </Title>
      <Row>
        {product.relatedCollections.map(
          (relatedCollection, i) =>
            !!relatedCollection.collection && (
              <Col xs={4} key={i}>
                <Link
                  href={`/products/detail?reference=${relatedCollection.collection.reference}`}
                  borderless
                  dark
                >
                  <ProductItem item={relatedCollection.collection} />
                </Link>
              </Col>
            ),
        )}
      </Row>
    </Container>
  );
};

export const ProductRelatedFragment = gql`
  fragment ProductRelatedFragment on Collection {
    relatedCollections {
      type
      collection {
        reference
        name
        imageMedium
        range
      }
    }
  }
`;

const ContentLoading = () => (
  <Container>
    <div style={{ display: "flex" }}>
      {Array(3)
        .fill("")
        .map((e, i) => (
          <div key={i} style={{ width: 200, height: 200 }}>
            <ContentLoader height={200} width={200}>
              <rect x="51" y="1" rx="5" ry="5" width="104" height="130" />
              <rect x="25" y="149" rx="5" ry="5" width="155" height="6" />
              <rect x="67" y="169" rx="5" ry="5" width="74" height="6" />
            </ContentLoader>
          </div>
        ))}
    </div>
  </Container>
);

const Container = styled(Card)`
  ${mediaQuery("<=", "tablet")`
    margin-top: 15px;
  `}
`;

const Title = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("19px")}
  line-height: 23px;
  margin-bottom: 30px;
`;
