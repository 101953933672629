import React, { useState } from "react";
import ContentLoader from "react-content-loader";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import { Card } from "./Card";
import { CardHeader } from "./CardHeader";
import { CardPicturesFormEdit } from "./CardPicturesFormEdit";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { Modal } from "./Modal";
import { Thumb } from "./UploadZoneThumbnails";
import { mediaQuery } from "react-styled-mediaquery";

export const CardPictures = ({
  loading,
  partner,
  onSubmit,
}: {
  loading?: boolean;
  partner?: React.ComponentProps<typeof CardPicturesFormEdit>["initialValues"];
  onSubmit: React.ComponentProps<typeof CardPicturesFormEdit>["onSubmit"];
}) => {
  const [modalEditPicturesIsOpen, setModalEditPicturesIsOpen] = useState(false);
  const numberOfItems = 11;
  const numberOfPictures = (partner?.pictures && partner.pictures.length) || 0;
  const numberOfThumbs =
    numberOfPictures < numberOfItems
      ? numberOfItems - numberOfPictures
      : Math.max(0, numberOfItems - numberOfPictures);
  return (
    <>
      <Modal
        maxWidth="m"
        title={
          <FormattedMessage
            id="informations.pictures.title"
            defaultMessage="Mes photos"
          />
        }
        open={modalEditPicturesIsOpen}
        onClose={() => setModalEditPicturesIsOpen(false)}
      >
        <CardPicturesFormEdit
          initialValues={partner}
          onClose={() => setModalEditPicturesIsOpen(false)}
          onSubmit={onSubmit}
          disabled={!!loading}
        />
      </Modal>
      <Card>
        <CardHeader onClick={() => setModalEditPicturesIsOpen(true)}>
          <FormattedMessage
            id="informations.pictures.title"
            defaultMessage="Mes photos"
          />
        </CardHeader>
        <Thumbnails>
          {loading || !partner ? (
            [...Array(numberOfItems)].map((e, i) => (
              <div style={{ width: 100, height: 100, marginRight: 10 }} key={i}>
                <ContentLoader height={100} width={100} speed={3}>
                  <rect x="0" y="0" rx="0" ry="0" width="100" height="100" />
                </ContentLoader>
              </div>
            ))
          ) : (
            <>
              {(partner.pictures || [])
                .slice(0, numberOfItems)
                .map((item, i) => (
                  <Square
                    key={i}
                    viewMore={
                      i === numberOfItems - 1 &&
                      numberOfItems !== numberOfPictures
                    }
                  >
                    <Content
                      style={{
                        background: `url(${item.content}) center/cover no-repeat`,
                      }}
                    />
                    {i === numberOfItems - 1 &&
                      numberOfItems !== numberOfPictures && (
                        <Content style={{ zIndex: 3 }}>
                          + {(partner.pictures || []).length - numberOfItems}
                        </Content>
                      )}
                  </Square>
                ))}
              {[...Array(numberOfThumbs)].map((e, i) => (
                <Square hideOnMobile key={i}>
                  <Content>
                    <Thumb
                      style={{
                        margin: 0,
                        flex: 1,
                        width: "auto",
                        height: "auto",
                      }}
                      single
                    />
                  </Content>
                </Square>
              ))}
            </>
          )}
        </Thumbnails>
      </Card>
    </>
  );
};

const Thumbnails = styled.div`
  display: flex;

  ${mediaQuery("<", "tablet")`
    flex-wrap: wrap;
  `}

  ${mediaQuery(">=", "tablet")`
      justify-content: space-between;

  `}
`;

const Square = styled.div<{ viewMore?: boolean; hideOnMobile?: boolean }>`
  position: relative;
  border-radius: 20px;
  overflow: hidden;

  ${mediaQuery(">=", "tablet")`
    flex: 1;
  `}

  ${p =>
    p.hideOnMobile &&
    css`
      ${mediaQuery("<", "tablet")`
      display: none;
  `}
    `}

  ${mediaQuery("<", "tablet")`
    &:nth-child(5n) {
      margin-right: 0;
    }

    &:not(:nth-child(-1n+5)) {
      margin-top: 15px;
    }

    flex-basis: calc(96% / 5);
    margin-right: 1%;
  `}

  ${p =>
    p.viewMore &&
    css`
      color: #fff;
      ${fontSize("19px")}
      ${fontFamily(ffdinPro.bold)}

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: ${colors.primary};
        opacity: 0.75;
        z-index: 2;
      }
    `}

  &:not(:first-child) {
    ${mediaQuery(">=", "tablet")`
      margin-left: 15px;
    `}
  }

  &::after {
    content: "";
    display: block;
    padding-top: 100%;
  }
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
