import * as React from "react";
import styled from "styled-components";
const SvgArrowR10 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 50 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.58 3.22a10.82 10.82 0 00-15.39 0c-4.251 4.304-4.251 11.226 0 15.53L23.72 50 3.19 81.26c-4.246 4.306-4.246 11.224 0 15.53a10.83 10.83 0 0015.39 0l28.23-39c4.278-4.293 4.278-11.237 0-15.53L18.58 3.22z"
      fillRule="nonzero"
    />
  </svg>
);
export default styled(SvgArrowR10)``;
