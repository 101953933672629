exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-coupons-index-tsx": () => import("./../../../src/pages/coupons/index.tsx" /* webpackChunkName: "component---src-pages-coupons-index-tsx" */),
  "component---src-pages-coupons-list-tsx": () => import("./../../../src/pages/coupons/list.tsx" /* webpackChunkName: "component---src-pages-coupons-list-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-information-tsx": () => import("./../../../src/pages/information.tsx" /* webpackChunkName: "component---src-pages-information-tsx" */),
  "component---src-pages-litters-add-tsx": () => import("./../../../src/pages/litters/add.tsx" /* webpackChunkName: "component---src-pages-litters-add-tsx" */),
  "component---src-pages-litters-edit-tsx": () => import("./../../../src/pages/litters/edit.tsx" /* webpackChunkName: "component---src-pages-litters-edit-tsx" */),
  "component---src-pages-litters-index-tsx": () => import("./../../../src/pages/litters/index.tsx" /* webpackChunkName: "component---src-pages-litters-index-tsx" */),
  "component---src-pages-loyalty-index-tsx": () => import("./../../../src/pages/loyalty/index.tsx" /* webpackChunkName: "component---src-pages-loyalty-index-tsx" */),
  "component---src-pages-loyalty-purchase-tsx": () => import("./../../../src/pages/loyalty/purchase.tsx" /* webpackChunkName: "component---src-pages-loyalty-purchase-tsx" */),
  "component---src-pages-passwordmanager-tsx": () => import("./../../../src/pages/passwordmanager.tsx" /* webpackChunkName: "component---src-pages-passwordmanager-tsx" */),
  "component---src-pages-products-detail-tsx": () => import("./../../../src/pages/products/detail.tsx" /* webpackChunkName: "component---src-pages-products-detail-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-promos-tsx": () => import("./../../../src/pages/promos.tsx" /* webpackChunkName: "component---src-pages-promos-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */)
}

