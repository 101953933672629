import React from "react";
import { FormattedMessage } from "react-intl";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { Button } from "./Button";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { Wrapper } from "./Wrapper";
import { PageLoading } from "./PageLoading";

const Title = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("19px")}

  ${mediaQuery("<=", "tablet")`
    display: none;
  `}
`;

const Container = styled.div`
  ${mediaQuery(">", "tablet")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  `}

  ${mediaQuery("<=", "tablet")`
    margin-bottom: 15px;
  `}
`;

export const LittersHeader = ({
  loading,
  disableCreate,
}: {
  loading?: boolean;
  disableCreate?: boolean;
}) => {
  if (loading) {
    return <PageLoading />;
  }
  return (
    <Wrapper>
      <Container>
        <Title>
          <FormattedMessage
            id="website.litters.title"
            defaultMessage="Mes portées"
          />
        </Title>
        <div>
          <Button fullwidth href="/litters/add" disabled={disableCreate}>
            <FormattedMessage
              id="website.litters.add"
              defaultMessage="Ajouter une portée"
            />
          </Button>
        </div>
      </Container>
    </Wrapper>
  );
};
