import React from "react";
import styled, { css } from "styled-components";
import {
  IconInfo16,
  IconRoundedCross16,
  IconRoundedCheck16,
  IconTrash16,
} from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { mediaQuery } from "react-styled-mediaquery";

const Container = styled.div<{ type?: string }>`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
  background: #fff;
  border-radius: 10px;
  border: 1px solid ${colors.lightLt};
  color: ${colors.dark};
  display: flex;
  justify-content: center;
  padding: 20px;
  align-items: center;

  svg {
    margin-right: 10px;
  }

  ${p =>
    p.type === "warning" &&
    css`
      color: ${colors.warning};
    `}

  ${p =>
    p.type === "error" &&
    css`
      color: ${colors.primaryDk};
      border-color: ${colors.primaryDk};
    `}

    ${p =>
      p.type === "success" &&
      css`
        color: ${colors.success};
        border-color: ${colors.success};
      `}

    ${p =>
      p.type === "delete" &&
      css`
        color: ${colors.primaryDk};
        border-color: transparent;
        background: rgba(191, 0, 22, 0.05);
      `}
`;

const Icon = styled.div`
  flex-shrink: 0;

  svg {
    display: block;
  }

  ${mediaQuery("<=", "mobile")`
    display: none;
  `}
`;

export const Alert = ({
  children,
  className,
  type,
  style,
}: {
  children: React.ReactNode;
  className?: string;
  type?: "warning" | "error" | "success" | "delete";
  style?: React.CSSProperties;
}) => (
  <Container type={type} className={className} style={style}>
    {type === "warning" && (
      <Icon>
        <IconInfo16 fill={colors.warning} width={16} height={16} />
      </Icon>
    )}
    {type === "error" && (
      <Icon>
        <IconRoundedCross16 fill={colors.primaryDk} width={16} height={16} />
      </Icon>
    )}
    {type === "success" && (
      <Icon>
        <IconRoundedCheck16 fill={colors.success} width={16} height={16} />
      </Icon>
    )}
    {type === "delete" && (
      <Icon>
        <IconTrash16 fill={colors.primaryDk} width={14} height={16} />
      </Icon>
    )}
    {children}
  </Container>
);
