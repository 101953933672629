import React from "react";
import styled from "styled-components";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { OmitRecursively } from "./types";
import gql from "graphql-tag";
import { ProductListItemFragment as ProductFragment } from "./__generated__/ProductListItemFragment";

type IProduct = OmitRecursively<ProductFragment, "__typename">;

export const ProductItem = ({ item }: { item: IProduct }) => (
  <>
    <Cover
      style={{
        background: `url(${item.imageMedium}) no-repeat center/contain`,
      }}
    />
    <Name>{item.name}</Name>
    <Range>{item.range}</Range>
  </>
);

export const ProductListItemFragment = gql`
  fragment ProductListItemFragment on Product {
    name
    imageMedium
    range
  }
`;

const Cover = styled.div`
  height: 130px;
  margin-bottom: 30px;
`;

const Name = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
  text-align: center;
`;

const Range = styled.div`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("12px")}
  text-align: center;
  padding-top: 10px;
  margin-top: auto;
`;
