import { useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { useUser } from "@igloo-be-omnipartners/hooks";
import { useUserRole } from "./hooks/useUserRole";

export const SentryConfigure = () => {
  const { data } = useUser();
  const user = data?.user?.result;
  const { data: role } = useUserRole();
  const partnerRelation = role?.partnerRelation;

  useEffect(() => {
    Sentry.configureScope((scope) => {
      scope.setUser({
        guid: user?.owner.guid || undefined,
        email: user?.owner.email || undefined,
        extId: partnerRelation?.extId || undefined,
      });
    });
  }, [user, partnerRelation]);

  return null;
};
