import * as React from "react";
import styled from "styled-components";
const SvgDoc16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M68.75 87.5h-1.38A6.25 6.25 0 0175 79.87v1.38a6.25 6.25 0 01-6.25 6.25zM25 81.25v-62.5a6.25 6.25 0 016.25-6.25h37.5A6.25 6.25 0 0175 18.75v48.63A18.38 18.38 0 0054.88 87.5H31.25A6.25 6.25 0 0125 81.25zM68.75 0h-37.5A18.75 18.75 0 0012.5 18.75v62.5A18.75 18.75 0 0031.25 100h37.5A18.75 18.75 0 0087.5 81.25v-62.5A18.75 18.75 0 0068.75 0z" />
  </svg>
);
export default styled(SvgDoc16)``;
