import * as React from "react";
import styled from "styled-components";
const SvgUser16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M22.16 80.94a3.09 3.09 0 000 6.19h55.68a3.09 3.09 0 000-6.19zm0-12.37h55.68a15.47 15.47 0 010 30.94H22.16a15.47 15.47 0 010-30.94zM50 12.88a18.56 18.56 0 1018.56 18.56A18.56 18.56 0 0050 12.88zM50 .5a30.94 30.94 0 11-30.94 30.94A30.94 30.94 0 0150 .5z" />
  </svg>
);
export default styled(SvgUser16)``;
