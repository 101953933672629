import * as React from "react";
import styled from "styled-components";
const SvgShop12 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M16.67 0h66.66A16.67 16.67 0 01100 16.67v66.66A16.67 16.67 0 0183.33 100H16.67A16.67 16.67 0 010 83.33V16.67A16.67 16.67 0 0116.67 0zm0 33.33A8.33 8.33 0 0025 25v-8.33h-8.33zm41.66-16.66H41.67V25a8.33 8.33 0 0016.67 0zm25 16.67V16.67H75V25a8.33 8.33 0 008.33 8.33zm-50 50V75a16.67 16.67 0 0133.33 0v8.33h16.67V50a24.91 24.91 0 01-16.67-6.37 25 25 0 01-33.33 0A24.91 24.91 0 0116.67 50v33.33z" />
  </svg>
);
export default styled(SvgShop12)``;
