import React from "react";
import { LoginForm } from "./LoginForm";
import { LayoutNotLogged } from "./LayoutNotLogged";

export const LayoutLogin = ({
  links,
  onLocaleChange,
  locale,
  loginError,
}: React.ComponentProps<typeof LayoutNotLogged> & { loginError?: string }) => {
  return (
    <LayoutNotLogged
      links={links}
      onLocaleChange={onLocaleChange}
      locale={locale}
    >
      <LoginForm loginError={loginError} />
    </LayoutNotLogged>
  );
};
