import * as React from "react";
import styled from "styled-components";
const SvgGroom12 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M19 63.57a7.95 7.95 0 0111.23-11.24L48.87 71l5.43-5.43L35.66 46.9A7.95 7.95 0 1146.9 35.66L65.54 54.3 71 48.87 52.33 30.23A7.95 7.95 0 1163.57 19l18.54 18.54a8.22 8.22 0 00-.95-10.48l-13-13A8.22 8.22 0 0179.75 2.41l13 13a24.65 24.65 0 010 34.86L50.29 92.78a24.65 24.65 0 01-34.86 0l-13-13A8.22 8.22 0 0114 68.13l13 13a8.22 8.22 0 0010.48.95z" />
  </svg>
);
export default styled(SvgGroom12)``;
