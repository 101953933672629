import React from "react";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { IconEdit } from "./IconEdit";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { Link } from "./Link";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  ${fontSize("16px")}
  ${fontFamily(ffdinPro.bold)}
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${colors.lightLt};

  ${mediaQuery("<=", "768px")`
    padding-bottom: 20px;
    margin-bottom: 20px;
  `}
`;

export const CardHeader = ({
  children,
  onClick,
  href,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  href?: string;
}) => (
  <Container>
    {children}
    {onClick && <IconEdit onClick={onClick} />}
    {!!href && (
      <Link href={href} borderless>
        <IconEdit />
      </Link>
    )}
  </Container>
);
