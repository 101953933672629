import * as React from "react";
import styled from "styled-components";
const SvgKit40 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M32.5 65v12.5h10V65zM66 22.5L72.94 35H91.5l-6.94-12.5zm-50.56 0L8.5 35h38.56L54 22.5zM82.5 40H70l-7.5-13.5v51h15a5 5 0 005-5zm5 0v32.5a10 10 0 01-10 10h-55a10 10 0 01-10-10V40H0l12.5-22.5h75L100 40zm-60 37.5V60h20v17.5h10v-51L50 40H17.5v32.5a5 5 0 005 5z" />
  </svg>
);
export default styled(SvgKit40)``;
