import { FORM_ERROR } from "final-form";
import gql from "graphql-tag";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import { object, string } from "yup";
import { CardNetworksPartnerFragment } from "./__generated__/CardNetworksPartnerFragment";
import { Alert } from "./Alert";
import { Form, useInputProps } from "./Form";
import {
  IconDesktop16,
  IconFacebook16,
  IconInstagram16,
  IconTwitter16,
  IconYoutube16,
} from "./icons-ts";
import { Input } from "./Input";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily } from "./lib/fonts";
import { IErrorResult, validationErrorsToObject } from "./lib/validationUtils";
import { ModalFooter } from "./ModalFooter";
import { OmitRecursively } from "./types";
import { mediaQuery } from "react-styled-mediaquery";
import { debounce } from "lodash";

type CardNetworksFormEditValues = OmitRecursively<
  CardNetworksPartnerFragment,
  "__typename"
>;

const messages = defineMessages({
  website: {
    id: "form.label.website",
    defaultMessage: "Site internet",
  },
  websiteErrorValidation: {
    id: "form.error.website_validation",
    defaultMessage: "Ce site web n'existe pas",
  },
  facebook: {
    id: "form.label.facebook",
    defaultMessage: "Facebook",
  },
  twitter: {
    id: "form.label.twitter",
    defaultMessage: "Twitter",
  },
  instagram: {
    id: "form.label.instagram",
    defaultMessage: "Instagram",
  },
  youtube: {
    id: "form.label.youtube",
    defaultMessage: "YouTube",
  },
});

export const CardNetworksFormEdit = ({
  onClose,
  onSubmit,
  initialValues,
  validateWebsite,
}: {
  onClose: () => void;
  onSubmit: (
    values: CardNetworksFormEditValues,
  ) => Promise<IErrorResult | null | void>;
  initialValues: CardNetworksFormEditValues | undefined;
  validateWebsite: (website: string) => Promise<boolean>;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Form<CardNetworksFormEditValues>
      initialValues={{
        website: "",
        facebook: "",
        twitter: "",
        instagram: "",
        youtube: "",
        ...initialValues,
      }}
      fieldLabels={{
        website: formatMessage(messages.website),
        facebook: formatMessage(messages.facebook),
        twitter: formatMessage(messages.twitter),
        instagram: formatMessage(messages.instagram),
        youtube: formatMessage(messages.youtube),
      }}
      schema={object().shape({
        website: string()
          .nullable()
          .test(
              "checkDNS",
              formatMessage(messages.websiteErrorValidation),
              async (value) => {
                if (!value) {
                  return true;
                }
                const isValid = await validateWebsite(value);
                return isValid;
              },
          ),
        facebook: string().nullable(),
        twitter: string().nullable(),
        instagram: string().nullable(),
        youtube: string().nullable(),
      })}
      onSubmit={async (values: CardNetworksFormEditValues) => {
        const error = await onSubmit({
          website: values.website || "",
          facebook: values.facebook || "",
          twitter: values.twitter || "",
          instagram: values.instagram || "",
          youtube: values.youtube || "",
        });
        if (error) {
          return {
            [FORM_ERROR]: error.message,
            ...validationErrorsToObject(error.validationErrors),
          };
        }

        onClose();
        return;
      }}
      render={({ handleSubmit, submitError }) => {
        const { label: websiteLabel, ...inputPropsWebsite } = useInputProps({
          name: "website",
        });
        const { label: facebookLabel, ...inputPropsFacebook } = useInputProps({
          name: "facebook",
        });
        const { label: twitterLabel, ...inputPropsTwitter } = useInputProps({
          name: "twitter",
        });
        const {
          label: instagramLabel,
          ...inputPropsInstagram
        } = useInputProps({ name: "instagram" });
        const { label: youtubeLabel, ...inputPropsYoutube } = useInputProps({
          name: "youtube",
        });
        const inputPropsWebsiteOnChange = debounce(inputPropsWebsite.onChange, 500)
        
        return (
          <form onSubmit={handleSubmit}>
            {submitError && (
              <div style={{ marginBottom: 30 }}>
                <Alert type="error">{submitError}</Alert>
              </div>
            )}
            <Item>
              <Title>
                <IconDesktop16 fill={colors.darkDk} width={16} height={16} />
                <div>{websiteLabel}</div>
              </Title>
              <InputContainer>
                <Input
                  fullWidth
                  {...inputPropsWebsite}
                  onChange={(e) => {
                    const value = e.currentTarget.value
                    inputPropsWebsiteOnChange(value)
                  }}
                  defaultValue={inputPropsWebsite.value}
                  value={undefined}
                />
              </InputContainer>
            </Item>
            <Item>
              <Title>
                <IconFacebook16 fill={colors.darkDk} height={16} width={8} />
                <div>{facebookLabel}</div>
              </Title>
              <InputContainer>
                <Input fullWidth {...inputPropsFacebook} />
              </InputContainer>
            </Item>
            <Item>
              <Title>
              <IconTwitter16 fill={colors.darkDk} width={16} height={13} />
                <div>{twitterLabel}</div>
              </Title>
              <InputContainer>
                <Input fullWidth {...inputPropsTwitter} />
              </InputContainer>
            </Item>
            <Item>
              <Title>
                <IconInstagram16 fill={colors.darkDk} width={16} height={16} />
                <div>{instagramLabel}</div>
              </Title>
              <InputContainer>
                <Input fullWidth {...inputPropsInstagram} />
              </InputContainer>
            </Item>
            <Item>
              <Title>
                <IconYoutube16 fill={colors.darkDk} height={14} width={16} />
                <div>{youtubeLabel}</div>
              </Title>
              <InputContainer>
                <Input fullWidth {...inputPropsYoutube} />
              </InputContainer>
            </Item>
            <ModalFooter onClose={onClose} buttonType="submit" />
          </form>
        );
      }}
    />
  );
};

CardNetworksFormEdit.PartnerFragment = gql`
  fragment CardNetworksPartnerFragment on PartnerFull {
    website
    facebook
    twitter
    instagram
    youtube
  }
`;

const InputContainer = styled.div`
  ${mediaQuery(">", "phablet")`
    flex: 2;
  `}

  ${mediaQuery("<=", "phablet")`
    margin-top: 20px;
  `}
`;

const Item = styled.div`
  ${mediaQuery(">=", "phablet")`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}

  &:not(:first-child) {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px dashed ${colors.lightLt};
  }
`;

const Title = styled.div`
  display: flex;
  ${fontFamily(ffdinPro.bold)}
  margin-right: 30px;
  flex: 1;

  > svg {
    display: block;
    margin-right: 20px;
  }
`;
