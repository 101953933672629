import { format, startOfToday, startOfDay, differenceInDays } from "date-fns";
import React from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { IconPen16, IconPicture16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { ILitter } from "./types";
import { Link } from "./Link";

export const messages = defineMessages({
  pack: {
    id: "litters.pack",
    defaultMessage: "Colis",
  },
  litterType_dog: {
    id: "litters.type.dog",
    defaultMessage: "chiot",
  },
  litterType_cat: {
    id: "litters.type.cat",
    defaultMessage: "chaton",
  },
  litterUnitType_dog: {
    id: "litters.unit.type.dog",
    defaultMessage: "chiots",
  },
  litterUnitType_cat: {
    id: "litters.unit.type.cat",
    defaultMessage: "chatons",
  },
});

export const LittersListItems = ({ litters }: { litters: ILitter[] }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Table>
        <thead>
          <tr>
            <Thead>&nbsp;</Thead>
            <Thead>
              <FormattedMessage
                id="website.litters.table.litter"
                defaultMessage="Portée"
              />
            </Thead>
            <Thead>
              <FormattedMessage
                id="website.litters.table.order"
                defaultMessage="Commande"
              />
            </Thead>
            <Thead>
              <FormattedMessage
                id="website.litters.table.available"
                defaultMessage="Disponible"
              />
            </Thead>
            <Thead>
              <FormattedMessage
                id="website.litters.table.ad"
                defaultMessage="Annonce"
              />
            </Thead>
            <Thead>&nbsp;</Thead>
            <Thead>&nbsp;</Thead>
          </tr>
        </thead>
        <tbody>
          {litters.map((item, i) => (
            <Row key={i}>
              <CellMainInfo {...item} breedName={item.breed?.name} />
              <td>
                <Order>
                  <div>
                    <TextMedium>
                      {formatMessage(messages.pack)}{" "}
                      {formatMessage(
                        messages[
                          `litterType_${item.type.toLowerCase()}` as keyof typeof messages
                        ],
                      )}{" "}
                      {item.univers?.name}
                    </TextMedium>
                    <TextMuted>
                      <FormattedMessage
                        id="website.litters.table.of"
                        defaultMessage="le"
                      />
                      <Space>
                        {format(new Date(item.createdAt), "dd.MM.yyyy")}
                      </Space>
                    </TextMuted>
                  </div>
                </Order>
              </td>
              <td>
                <TextMedium>
                  {item.availableFemale + item.availableMale} / {item.total}
                  <Type>
                    {formatMessage(
                      messages[
                        `litterUnitType_${item.type.toLowerCase()}` as keyof typeof messages
                      ],
                    )}
                  </Type>
                </TextMedium>
                <TextMuted>
                  {item.availableFemale}
                  <Space>
                    <FormattedMessage
                      id="website.litters.table.female"
                      defaultMessage="Femelle"
                    />
                  </Space>
                  , {item.availableMale}
                  <Space>
                    <FormattedMessage
                      id="website.litters.table.male"
                      defaultMessage="Mâles"
                    />
                  </Space>
                </TextMuted>
              </td>
              <td>
                <AdStatus
                  visible={!!item.visible}
                  dateVisibleFrom={item.dateVisibleFrom || undefined}
                  dateVisibleTo={item.dateVisibleTo || undefined}
                />
              </td>
              <td>
                <Pics>
                  <IconPicture16 width={16} height={16} fill={colors.darkDk} />
                  <TextBold>{item.photos.length}</TextBold>
                </Pics>
              </td>
              <td>
                {!!item.editUrl ? (
                  <Link href={item.editUrl} borderless>
                    <IconPen16 height={16} width={16} fill={colors.primary} />
                  </Link>
                ) : (
                  <IconPen16 height={16} width={16} fill={colors.light} />
                )}
              </td>
            </Row>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export const isLitterAdNotPublished = (
  litter: Pick<ILitter, "visible" | "dateVisibleFrom" | "dateVisibleTo">,
) => {
  return !(!litter.visible || !litter.dateVisibleFrom || !litter.dateVisibleTo);
};

export const isLitterArchived = ({
  dateVisibleTo,
}: Pick<ILitter, "dateVisibleTo">) => {
  if (!dateVisibleTo) {
    return false;
  }
  const today = startOfToday();
  const to = startOfDay(new Date(dateVisibleTo));
  return differenceInDays(today, to) > 0;
};

const AdStatus = ({
  visible,
  dateVisibleFrom,
  dateVisibleTo,
}: {
  visible: boolean;
  dateVisibleFrom?: string | Date;
  dateVisibleTo?: string | Date;
}) => {
  if (
    !isLitterAdNotPublished({
      visible,
      dateVisibleFrom: dateVisibleFrom || null,
      dateVisibleTo: dateVisibleTo || null,
    })
  ) {
    return (
      <TextMuted>
        <FormattedMessage
          id="litters.ad.status.notPublished"
          defaultMessage="Pas d'annonce publiée"
        />
      </TextMuted>
    );
  }

  const today = startOfToday();
  const from = startOfDay(
    dateVisibleFrom ? new Date(dateVisibleFrom) : new Date(),
  );
  if (differenceInDays(today, from) < 0) {
    return (
      <>
        <TextMedium>
          <FormattedMessage
            id="litters.ad.status.soonPublished"
            defaultMessage="Bientôt publiée"
          />
        </TextMedium>
        <TextMuted>
          <FormattedMessage
            id="litters.ad.status.fromTheDate"
            defaultMessage="À partir du"
          />
          <Space>{format(from, "dd.MM.yyyy")}</Space>
        </TextMuted>
      </>
    );
  }

  const to = startOfDay(dateVisibleTo ? new Date(dateVisibleTo) : new Date());
  if (isLitterArchived({ dateVisibleTo: dateVisibleTo || null })) {
    return (
      <>
        <TextMedium>
          <FormattedMessage
            id="litters.ad.status.archived"
            defaultMessage="Archivée"
          />
        </TextMedium>
        <TextMuted>
          <FormattedMessage
            id="litters.ad.status.publishedSince"
            defaultMessage="Depuis le"
          />
          <Space>{format(from, "dd.MM.yyyy")}</Space>
        </TextMuted>
      </>
    );
  } else {
    return (
      <>
        <TextMedium>
          <FormattedMessage
            id="litters.ad.status.published"
            defaultMessage="Publiée"
          />
        </TextMedium>
        <TextMuted>
          <FormattedMessage
            id="litters.ad.status.publishedUntil"
            defaultMessage="Jusqu'au"
          />
          <Space>{format(to, "dd.MM.yyyy")}</Space>
        </TextMuted>
      </>
    );
  }
};

export const CellMainInfo = ({
  total,
  breedName,
  dob,
}: Pick<ILitter, "total" | "dob"> & { breedName: string | React.ReactNode }) => (
  <>
    <td>
      <TextBold>
        <TextNowrap>{total} x</TextNowrap>
      </TextBold>
    </td>
    <td>
      <TextBold>{breedName || "-"}</TextBold>
      <TextMuted>
        <FormattedMessage
          id="website.litters.table.birth"
          defaultMessage="Né le"
        />
        <Space>{dob ? format(new Date(dob), "dd.MM.yyyy") : "-"}</Space>
      </TextMuted>
    </td>
  </>
);

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
`;

export const TextMedium = styled.div`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("16px")}
`;

export const TextBold = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("16px")}
  flex-shrink: 0;
`;

const TextNowrap = styled.span`
  white-space: nowrap;
`;

export const TextMuted = styled.div`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("14px")}
  color: ${colors.dark};
  margin-top: 10px;
`;

const Thead = styled.th`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
  color: ${colors.dark};
  text-align: left;
  padding-bottom: 20px;
  padding: 0 30px 10px 30px;
`;

export const Row = styled.tr`
  td {
    background: #fff;
    padding: 20px 30px;

    ${mediaQuery("<", "870px")`
      padding: 10px 15px;
    `}

    &:first-child {
      border-radius: 10px 0 0 10px;
      padding-right: 0;
      vertical-align: baseline;
      width: 1px;
    }
    &:nth-child(2) {
      padding-left: 10px;
    }

    &:last-child {
      border-radius: 0 10px 10px 0;
    }
  }
`;

const Order = styled.div`
  display: flex;

  ${TextBold} {
    margin-right: 10px;
  }
`;

const Pics = styled.div`
  display: flex;
  align-items: center;

  ${TextBold} {
    margin-left: 10px;
  }
`;

const Type = styled.span`
  display: inline-block;
  margin-left: 5px;
`;

const Space = styled.span`
  display: inline-block;
  margin-left: 5px;
`;
