import React from "react";
import styled from "styled-components";
import { LayoutFooterItem } from "./LayoutFooter";
import { colors } from "./lib/constants";
import { ILink } from "./types";
import { Wrapper } from "./Wrapper";

const Links = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const Container = styled.div`
  background: ${colors.lightLter};
  margin-top: 15px;
`;

export const LayoutFooterMobile = ({
  footerLinks,
}: {
  footerLinks: ILink[];
}) => (
  <Container>
    <Wrapper>
      <Links>
        {footerLinks.map((item, i) => (
          <LayoutFooterItem key={i} href={item.href} target="_blank">
            {item.label}
          </LayoutFooterItem>
        ))}
      </Links>
    </Wrapper>
  </Container>
);
