import React, { useEffect } from "react";
import { slide as Menu, Styles as MenuStyles } from "react-burger-menu";
import styled from "styled-components";
import { IconCross16 } from "./icons-ts";
import { Header, Languages, NavLink } from "./LayoutNavigationMobile";
import { colors } from "./lib/constants";
import { ILink } from "./types";
import logoCrown from "../assets/crown.svg";

export const bmStyles: MenuStyles = {
  bmBurgerBars: {},
  bmBurgerButton: {},
  bmCross: {},
  bmCrossButton: {},
  bmItemList: {
    display: "flex",
    flexDirection: "column",
  },
  bmMenuWrap: {
    transitionDuration: "400ms",
  },
  bmMenu: {
    background: "#fff",
  },
  bmMorphShape: {},
  bmOverlay: {
    background: "rgba(255, 255, 255, 0.8)",
  },
};

const Close = styled(IconCross16)`
  display: block;
  cursor: pointer;
  position: absolute;
  left: 15px;
  top: 50%;
  margin-top: -8px;
`;

const HeaderStyled = styled(Header)`
  box-shadow: none;
  border-bottom: 1px solid ${colors.lightLt};
`;

export const LayoutLoginMobilePanel = ({
  isOpen,
  onClose,
  links,
  locale,
  onLocaleChange,
}: {
  isOpen: boolean;
  onClose: () => void;
  links: ILink[];
  locale: string;
  onLocaleChange: (locale: any) => void;
}) => {
  // Remove body scroll if menu is Open
  useEffect(() => {
    isOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "");
  }, [isOpen]);

  return (
    <Menu
      customCrossIcon={false}
      customBurgerIcon={false}
      width={"100%"}
      styles={bmStyles}
      isOpen={isOpen}
    >
      <HeaderStyled>
        <img src={logoCrown} />
        <Close width={16} height={16} fill={colors.darkDk} onClick={onClose} />
      </HeaderStyled>
      <Header>Informations</Header>
      {links.map((item, i) => (
        <NavLink key={i} borderless dark href={item.href}>
          {item.label}
        </NavLink>
      ))}
      <Languages locale={locale} onLocaleChange={onLocaleChange} />
    </Menu>
  );
};
