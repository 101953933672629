import React, { ReactNode } from "react";
import ContentLoader, { IContentLoaderProps } from "react-content-loader";
import { ILitter } from "./types";
import { Wrapper } from "./Wrapper";
import { format } from "date-fns";
import styled, { css } from "styled-components";
import { Card } from "./Card";
import { IconPen16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import {
  TextBold,
  TextMedium,
  TextMuted,
  LittersListItems,
  messages,
} from "./LittersListItems";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "./Link";

export const LittersList = ({
  litters,
  loading,
}: {
  litters?: ILitter[];
  loading?: boolean;
}) => {
  return (
    <Wrapper>
      {loading || !litters ? <Table /> : <LittersListItems litters={litters} />}
    </Wrapper>
  );
};

export const LittersListMobile = ({ litters }: { litters: ILitter[] }) => {
  const { formatMessage } = useIntl();
  return (
    <div>
      <Wrapper>
        {litters.map((item, i) => (
          <Item key={i}>
            <Title>
              <FormattedMessage
                id="website.litters.table.order"
                defaultMessage="Commande"
              />
            </Title>
            <Flex>
              <TextMedium>
                {formatMessage(messages.pack)}{" "}
                {formatMessage(
                  messages[
                    `litterType_${item.type.toLowerCase()}` as keyof typeof messages
                  ],
                )}{" "}
                {item.univers?.name}
              </TextMedium>
              <TextBold>{item.total} x</TextBold>
            </Flex>
            <TextMuted>
              <FormattedMessage
                id="website.litters.table.of"
                defaultMessage="le"
              />{" "}
              {format(new Date(item.createdAt), "dd.MM.yyyy")}
            </TextMuted>
            <Title offset>
              <FormattedMessage
                id="website.litters.table.litter"
                defaultMessage="Portée"
              />
            </Title>
            <TextBold>{item.breed?.name || "-"}</TextBold>
            <Flex>
              <TextMuted>
                <FormattedMessage
                  id="website.litters.table.birth"
                  defaultMessage="Né le"
                />{" "}
                {item.dob ? format(new Date(item.dob), "dd.MM.yyyy") : "-"}
              </TextMuted>
              {!!item.editUrl ? (
                <Link href={item.editUrl} borderless>
                  <IconPen16 width={16} height={16} fill={colors.primary} />
                </Link>
              ) : (
                <IconPen16 width={16} height={16} fill={colors.light} />
              )}
            </Flex>
          </Item>
        ))}
      </Wrapper>
    </div>
  );
};

const TableRow = (props: IContentLoaderProps) => {
  const random = Math.random() * (1 - 0.7) + 0.7;
  return (
    <ContentLoader height={40} width={1290} {...props}>
      <rect x="0" y="15" rx="4" ry="4" width="6" height="6.4" />
      <rect x="34" y="13" rx="6" ry="6" width={200 * random} height="12" />
      <rect x="633" y="13" rx="6" ry="6" width={23 * random} height="12" />
      <rect x="653" y="13" rx="6" ry="6" width={78 * random} height="12" />
      <rect x="755" y="13" rx="6" ry="6" width={117 * random} height="12" />
      <rect x="938" y="13" rx="6" ry="6" width={83 * random} height="12" />

      <rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
    </ContentLoader>
  );
};

const Table = () => (
  <>
    {Array(10)
      .fill("")
      .map((e, i) => (
        <TableRow key={i} style={{ opacity: Number(2 / i).toFixed(1) }} />
      ))}
  </>
);

const Title = styled(
  ({
    offset,
    children,
    ...props
  }: {
    offset?: boolean;
    children: ReactNode;
  }) => <div {...props}>{children}</div>,
)`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
  color: ${colors.dark};
  margin-bottom: 15px;

  ${p =>
    p.offset &&
    css`
      margin-top: 20px;
    `}
`;

const Item = styled(Card)`
  &:not(:first-child) {
    margin-top: 15px;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;
