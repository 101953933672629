import React, { useState } from "react";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { GlobalStyles } from "./GlobalStyles";
import { LayoutLoginMobilePanel } from "./LayoutLoginMobilePanel";
import { colors } from "./lib/constants";
import { LoginFooter } from "./LoginFooter";
import { LoginHeader } from "./LoginHeader";
import { ILink } from "./types";
import { Wrapper } from "./Wrapper";

export const LayoutNotLogged = ({
  links,
  onLocaleChange,
  locale,
  children,
}: {
  links: ILink[];
  onLocaleChange: (locale: any) => void;
  locale: string;
  children?: React.ReactNode
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <GlobalStyles />
      <LayoutLoginMobilePanel
        links={links}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        locale={locale}
        onLocaleChange={onLocaleChange}
      />
      <Container>
        <WrapperStyled>
          <LoginHeader
            onClick={() => setIsOpen(true)}
            onLocaleChange={onLocaleChange}
            locale={locale}
          />
          {children}
          <LoginFooter links={links} />
        </WrapperStyled>
      </Container>
    </>
  );
};

const Container = styled.div`
  flex: 1 0 auto;
  background-image: linear-gradient(
    180deg,
    rgba(246, 246, 246, 0) 0%,
    ${colors.lightLter} 100%
  );
  display: flex;
  padding: 100px 0;

  ${mediaQuery("<=", "tablet")`
    padding: 50px 0;
  `}

  ${mediaQuery("<=", "phablet")`
    padding: 20px 0;
  `}
`;

const WrapperStyled = styled(Wrapper)`
  width: 100%; /* Fix for IE11 otherwise the layout is not centered */
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

