import * as React from "react";
import styled from "styled-components";
const SvgWarning16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg height={16} width={16} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 2C4.691 2 2 4.691 2 8s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6m0 14c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8m0-7a1 1 0 01-1-1V5a1 1 0 112 0v3a1 1 0 01-1 1m0 3a.994.994 0 01-1-1c0-.13.03-.26.08-.38s.12-.23.21-.33c.05-.04.1-.09.16-.12.05-.04.11-.07.17-.09a.949.949 0 01.76 0c.12.05.23.12.33.21.09.1.16.21.21.33a.995.995 0 01-.21 1.09A.994.994 0 018 12"
      fillRule="evenodd"
    />
  </svg>
);
export default styled(SvgWarning16)``;
