import Tippy, { TippyProps } from "@tippy.js/react";
import React from "react";
import styled, { css } from "styled-components";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily } from "./lib/fonts";
import { IReactChildren } from "./types";

const CustomTippy = styled(({ caps, menu, ...props }) => <Tippy {...props} />)<{
  caps: boolean;
}>`
  background-color: ${colors.darkDk};
  color: #fff;
  ${fontFamily(ffdinPro.bold)}
  padding: 5px;
  border-radius: 10px;
  max-width: 250px !important;

  ${(p) =>
    p.caps &&
    css`
      border-radius: 99px !important;
      padding: 1px 10px !important;
    `}

  ${(p) =>
    p.menu &&
    css`
      &.tippy-tooltip {
        background-color: #ffffff;
        box-shadow: 0 5px 15px 0 rgba(51, 51, 51, 0.1);
        padding: 20px;
        border-radius: 10px;
        color: ${colors.darkDk};

        &[data-placement^=bottom]>.tippy-arrow {
          border-bottom-color: #fff;
          filter: drop-shadow(0 0 1px rgba(51, 51, 51, 0.1));
          z-index: -1;
        }
      }
    `}

  &[data-placement^="bottom"] {
    .tippy-arrow {
      border-width: 0 6px 5px;
      top: -5px;
    }
  }
`;

export const Tooltip = ({
  children,
  content,
  placement,
  arrow,
  distance,
  caps,
  menu,
  ...props
}: TippyProps & {
  children?: React.ReactNode;
  content: IReactChildren;
  arrow?: boolean;
  caps?: boolean;
  menu?: boolean;
}) => (
  <CustomTippy
    {...props}
    menu={!!menu}
    caps={!!caps}
    arrow={arrow || false}
    animation="shift-toward"
    placement={placement || "bottom"}
    content={content}
    distance={distance || 15}
  >
    {children ? children : <div></div>}
  </CustomTippy>
);
