import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
} from "@material-ui/core";
import Select, { SelectProps } from "@material-ui/core/Select";
import React from "react";
import styled from "styled-components";
import { IconArrowB16 } from "./icons-ts";
import { colors } from "./lib/constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Input } from "./Input";
import { useIntl, defineMessages } from "react-intl";

export interface IDropdownItem<T> {
  value: T;
  label: string;
  items?: IDropdownItem<T>[];
}

const Arrow = styled(({ className }) => (
  <div className={className}>
    <IconArrowB16 width={16} height={7} fill={colors.darkDk} />
  </div>
))`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const messages = defineMessages({
  autoCompleteNoOptions: {
    id: "dropdown.noOptionsText",
    defaultMessage: "Aucun élément",
  },
});

type DropdownProps<T> = Omit<SelectProps, "autoComplete" | "onChange"> & {
  items: IDropdownItem<T>[];
  label?: string | JSX.Element;
  helperText?: string | React.ReactNodeArray;
  autoComplete?: boolean;
  loading?: boolean;
  noOptionsText?: string;
  onChange?: (value: any) => void;
};

type DropdownAutocompleteProps<T> = Omit<
  DropdownProps<T>,
  "autoComplete" | "helperText"
>;

export const Dropdown = <T extends string | number = string>({
  items,
  label,
  helperText,
  autoComplete,
  loading,
  noOptionsText,
  ...props
}: DropdownProps<T>) => {
  return (
    <FormControl fullWidth margin="none">
      {!autoComplete ? (
        <>
          {label && typeof label === "string" ? (
            <InputLabel htmlFor={label}>{label}</InputLabel>
          ) : (
            label
          )}
          <Select
            IconComponent={Arrow}
            {...props}
            onChange={(e) => {
              if (props.onChange) {
                props.onChange(
                  e.target?.value !== undefined ? e.target.value : null,
                );
              }
            }}
          >
            {items.map((item, i) => {
              if (item.items) {
                if (!item.items.length) {
                  return;
                }
                return [
                  <ListSubheader key={item.value}>{item.label}</ListSubheader>,
                  ...item.items.map((z) => (
                    <MenuItem key={z.value} value={z.value as any}>
                      {z.label}
                    </MenuItem>
                  )),
                ];
              }
              return (
                <MenuItem key={i} value={item.value as any}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </>
      ) : (
        <DropdownAutocomplete<T>
          {...props}
          items={items}
          loading={loading}
          noOptionsText={noOptionsText}
          label={label}
        />
      )}
      {helperText &&
        (Array.isArray(helperText) ? (
          helperText.map((item, i) => (
            <FormHelperText key={i} error={true}>
              {item}
            </FormHelperText>
          ))
        ) : (
          <FormHelperText error={true}>{helperText}</FormHelperText>
        ))}
    </FormControl>
  );
};

const DropdownAutocomplete = <T extends any = any>({
  items,
  onChange,
  name,
  disabled,
  loading,
  noOptionsText,
  value,
  label,
}: DropdownAutocompleteProps<T>) => {
  const { formatMessage } = useIntl();
  return (
    <Autocomplete
      options={items}
      getOptionLabel={(option) => option.label}
      onChange={(e: any, option: IDropdownItem<T> | null) => {
        if (onChange) {
          onChange((option && option.value) || null);
        }
      }}
      disabled={disabled}
      loading={loading}
      loadingText={formatMessage({ id: "website.loading" })}
      noOptionsText={
        noOptionsText || formatMessage(messages.autoCompleteNoOptions)
      }
      value={items.find((item) => item.value === value) || null}
      renderInput={(params) => (
        <Input {...params} label={label} icon={<ArrowAutoComplete />} />
      )}
    />
  );
};

const ArrowAutoComplete = styled(Arrow)`
  right: 0;
`;
