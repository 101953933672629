import React from "react";
import styled, { css } from "styled-components";
import { IconArrowL16, IconArrowR16 } from "./icons-ts";
import { colors } from "./lib/constants";

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
`;

const Left = styled(
  ({
    disabled,
    onClick,
    ...props
  }: {
    disabled?: boolean;
    onClick: () => void;
  }) => (
    <IconArrowL16
      {...props}
      height={16}
      width={7}
      fill={!disabled ? colors.darkDk : colors.light}
      onClick={onClick}
    />
  ),
)`
  margin-right: 60px;
  cursor: pointer;
  ${p =>
    p.disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const Input = styled.input`
  appearance: none;
  width: 42px;
  height: 40px;
  text-align: center;
  background: #fff;
  border-radius: 3px;
  border: 1px solid ${colors.lightLt};
  margin-right: 10px;
`;

const Right = styled(
  ({
    disabled,
    onClick,
    ...props
  }: {
    disabled?: boolean;
    onClick: () => void;
  }) => (
    <IconArrowR16
      {...props}
      height={16}
      width={7}
      fill={!disabled ? colors.darkDk : colors.light}
      onClick={onClick}
    />
  ),
)`
  margin-left: 60px;
  cursor: pointer;
  ${p =>
    p.disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const Pagination = ({
  value,
  pages = 1,
  onChange = () => {},
  disabled,
}: {
  value?: number;
  pages?: number;
  onChange?: (page: number) => void;
  disabled?: boolean;
}) => {
  const leftDisabled = disabled || !value || value <= 1;
  const rightDisabled = disabled || !value || value >= pages;

  return (
    <Container>
      <Inner>
        <Left
          onClick={() => {
            if (!leftDisabled && value) {
              onChange(value - 1);
            }
          }}
          disabled={disabled || leftDisabled}
        />
        <Input
          type="number"
          min={1}
          max={pages}
          step={1}
          value={value}
          onChange={e => {
            const v = parseInt(e.currentTarget.value, 10);
            if (!isNaN(v)) {
              onChange(v);
            }
          }}
          disabled={disabled}
        />{" "}
        sur {pages}
        <Right
          onClick={() => {
            if (!rightDisabled && value) {
              onChange(value + 1);
            }
          }}
          disabled={rightDisabled}
        />
      </Inner>
    </Container>
  );
};
