import React from "react";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { Link } from "./Link";
import { ILink } from "./types";
import loginPets from "../assets/login.jpg";
import { useIntl } from "react-intl";

const Img = styled.img`
  margin: 0 auto;
  display: block;
  margin-bottom: 60px;
  max-width: 100%;
  height: auto;
`;

const LinkCustom = styled(Link)`
  ${fontFamily(ffdinPro.medium)}
`;

const Links = styled.div`
  text-align: center;

  > ${LinkCustom}:not(:first-child) {
    margin-left: 20px;
  }

  ${mediaQuery("<=", "600px")`
    display: none;
  `}
`;

export const LoginFooter = ({ links }: { links: ILink[] }) => {
  const { locale } = useIntl();

  return (
    <div>
      <Img src={loginPets} width="330" />
      <Links>
        {links.map((item, i) => (
          <LinkCustom key={i} borderless dark href={item.href}>
            {item.label}
          </LinkCustom>
        ))}
      </Links>
      <Copyright>
        {locale === "fr" ? (
          <>
            <div>
              ©{new Date().getFullYear()} Royal Canin SAS. Tous droits réservés.
            </div>
            <div>Une filiale de Mars, Incorporated.</div>
          </>
        ) : locale === "nl" ? (
          <>
            <div>
              ©{new Date().getFullYear()} Royal Canin SAS. Alle rechten voorbehouden.
            </div>
            <div>Een dochteronderneming van Mars, Incorporated.</div>
          </>
        ) : (
          ""
        )}
      </Copyright>
    </div>
  );
};

const Copyright = styled.div`
  text-align: center;
  ${fontSize("11px")}
  color: #999;
  padding-top: 10px;
`;
