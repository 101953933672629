import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import { PageHeader } from "./PageHeader";
import { CardGift } from "./CardGift";
import styled from "styled-components";
import { mediaQuery } from "react-styled-mediaquery";
import { Alert } from "./Alert";

const ColItem = styled(Col)`
  ${mediaQuery(">", "960px")`
     &:not(:nth-child(-n + 4)) {
    margin-top: 30px;
  }
  `}

  ${mediaQuery("<=", "960px")`
    &:not(:nth-child(-n + 3)) {
      margin-top: 30px;
    }
  `}

  ${mediaQuery("<=", "tablet")`
    &:not(:nth-child(-n + 2)) {
      margin-top: 30px;
    }
  `}

  ${mediaQuery("<=", "mobile")`
    &:not(:nth-child(-n + 1)) {
      margin-top: 30px;
    }
  `}

  &:not(:nth-child()) {
    margin-top: 30px;
  }
`;

export const LoyaltyCards: React.FC<{
  gifts: React.ComponentProps<typeof CardGift>[];
  error?: React.ReactNode;
  success?: React.ReactNode;
}> = ({ gifts, error, success }) => (
  <>
    <PageHeader backLink="/loyalty" />
    {!!error && (
      <Alert type="error" style={{ marginBottom: 30 }}>
        {error}
      </Alert>
    )}
    {!!success && (
      <Alert type="success" style={{ marginBottom: 30 }}>
        {success}
      </Alert>
    )}

    <Row>
      {gifts.map((gift, index) => (
        <ColItem key={index} xs={12} sm={6} md={4} lg={3}>
          {/* <ModalCardGift {...gift} /> */}
          <CardGift {...gift} />
        </ColItem>
      ))}
    </Row>
  </>
);
