import * as React from "react";
import styled from "styled-components";
const SvgArrowT10 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path
      d="M96.78 56.42a10.82 10.82 0 010 15.39 11.05 11.05 0 01-15.53 0L50 51.28 18.74 71.81a11.06 11.06 0 01-15.53 0 10.83 10.83 0 010-15.39l39-28.23a11 11 0 0115.53 0z"
      fillRule="evenodd"
    />
  </svg>
);
export default styled(SvgArrowT10)``;
