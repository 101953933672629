import { InputProps } from "@material-ui/core/Input";
import React from "react";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { Card } from "./Card";
import { Checkbox } from "./Checkbox";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";

const Container = styled(Card)`
  position: relative;
  padding: 60px 30px;

  ${mediaQuery("<=", "phablet")`
    padding: 30px 20px;
  `}

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  }
`;

const CheckboxStyled = styled(Checkbox)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const Children = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("19px")}
  color: ${colors.darkDk};
  text-align: center;
  margin-top: 30px;
`;

export interface ICardIconProps {
  icon: React.ReactNode;
  children: React.ReactNode;
  inputProps?: Pick<InputProps, "name" | "onChange" | "disabled">;
}

export const CardIcon = ({ children, icon, inputProps }: ICardIconProps) => (
  <Container elevation>
    <label htmlFor={inputProps ? inputProps.name : undefined}>
      <CheckboxStyled {...inputProps} id={inputProps ? inputProps.name : undefined} />
      <div>{icon}</div>
      <Children>{children}</Children>
    </label>
  </Container>
);
