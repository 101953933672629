import { mediaQuery } from "react-styled-mediaquery";
import styled, { css } from "styled-components";

type MediaType = "mobile" | "phablet" | "tablet" | "desktop";

export const Media = styled.div<{ when: MediaType | MediaType[] }>`
  display: none;

  ${props => {
    const when = Array.isArray(props.when) ? props.when : [props.when];
    return when.map(value => {
      switch (value) {
        case "mobile":
          return css`
            ${mediaQuery("<", "600px")`
              display: block;
            `}
          `;
        case "phablet":
          return css`
            ${mediaQuery("between", "601px", "768px")`
              display: block;
            `}
          `;
        case "tablet":
          return css`
            ${mediaQuery("<=", "768px")`
              display: block;
            `}
          `;
        case "desktop":
          return css`
            ${mediaQuery(">", "768px")`
              display: block;
            `}
          `;
      }
    });
  }}
`;
