import React from "react";
import styled, { css } from "styled-components";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";

const Container = styled(
  ({
    children,
    spacing,
    ...props
  }: {
    spacing?: boolean;
    children: React.ReactNode;
  }) => <div {...props}>{children}</div>,
)`
  ${fontFamily(ffdinPro.bold)};
  ${fontSize("16px")}
  margin-bottom: 20px;

  ${p =>
    p.spacing &&
    css`
      margin-top: 40px;
    `}
`;

export const FormTitle = ({
  children,
  spacing,
}: {
  children: React.ReactNode;
  spacing?: boolean;
}) => <Container spacing={!!spacing}>{children}</Container>;
