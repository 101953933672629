import React from "react";
import { IInputProps, Input } from "./Input";

export const Textarea = ({ label, inputProps, ...props }: IInputProps) => (
  <Input
    {...props}
    multiline
    fullWidth
    variant="outlined"
    inputProps={{
      style: {
        minHeight: 80,
      },
      ...inputProps,
    }}
  />
);
