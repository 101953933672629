import styled from "styled-components";
import { Col } from "react-styled-flexboxgrid";

export const ColFormWrapper = styled(Col)`
  @media only screen and (min-width: 60em) {
    width: 100%;
    flex-basis: 100%;
    max-width: 880px; // 850 + 2 * 15 padding
  }
`;
