import { Checkbox as MUICheckbox } from "@material-ui/core";
import { CheckboxProps } from "@material-ui/core/Checkbox";
import React from "react";
import styled from "styled-components";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  ${fontFamily(ffdinPro.regular)}
  ${fontSize("16px")}
  letter-spacing: -0.5px;
`;

export type ICheckboxProps = CheckboxProps;

export const Checkbox = ({
  children,
  className,
  error,
  helperText,
  ...props
}: CheckboxProps & {
  error?: boolean;
  helperText?: string;
  children?: string | React.ReactNode;
}) => {
  return (
    <Container className={className}>
      <MUICheckbox
        style={{ marginLeft: -12, marginTop: -12, marginBottom: -12 }}
        {...props}
      />
      <Text>{children}</Text>
    </Container>
  );
};
