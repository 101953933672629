import React from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";
import { Card } from "./Card";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { Link } from "./Link";
import { OmitRecursively } from "./types";
import gql from "graphql-tag";
import { ProductLinksFragment as ProductFragment } from "./__generated__/ProductLinksFragment";
import { FormattedMessage } from "react-intl";
import { IconNews16, IconVideo16, IconPicture16 } from "./icons-ts";

type IProductLinks = OmitRecursively<ProductFragment, "__typename">;

export const CardProductsLinks = ({
  product,
  loading,
}: {
  product?: IProductLinks;
  loading?: boolean;
}) => {
  if (loading || !product) {
    return <ContentLoading />;
  }

  return (
    <Card>
      <Title>
        <FormattedMessage
          id="website.products.linksToDownload"
          defaultMessage="Sources à télécharger"
        />
      </Title>
      {product.productSheets.map((link, i) => (
        <ProductLink
          key={i}
          icon={<IconNews16 width={16} height={16} fill={colors.darkDk} />}
          label={
            <FormattedMessage
              id="website.products.sources.sheet"
              defaultMessage="Fiche produit"
            />
          }
          link={link}
        />
      ))}
      {product.videos.map((link, i) => (
        <ProductLink
          key={i}
          icon={<IconVideo16 width={16} height={16} fill={colors.darkDk} />}
          label={
            <FormattedMessage
              id="website.products.sources.video"
              defaultMessage="Vidéo"
            />
          }
          link={link}
        />
      ))}
      {product.images.map(({ reference, link }, i) => (
        <ProductLink
          key={i}
          icon={<IconPicture16 width={16} height={16} fill={colors.darkDk} />}
          label={
            reference === "kibble" ? (
              <FormattedMessage
                id="website.products.sources.kibble"
                defaultMessage="Croquette"
              />
            ) : (
              <FormattedMessage
                id="website.products.sources.product"
                defaultMessage="Produit"
              />
            )
          }
          link={link}
        />
      ))}
    </Card>
  );
};

export const ProductLinksFragment = gql`
  fragment ProductLinksFragment on Collection {
    videos
    productSheets
    kibble
    images {
      reference
      link
    }
  }
`;

const ProductLink = ({
  icon,
  label,
  link,
}: {
  icon: JSX.Element;
  label: string | JSX.Element;
  link: string;
}) => (
  <Container>
    <Item>
      {icon}
      <Label>{label}</Label>
    </Item>
    <div>
      <Link target="_blank" href={link}>
        <FormattedMessage
          id="button.label.download"
          defaultMessage="Télécharger"
        />
      </Link>
    </div>
  </Container>
);

export const ContentLoading = () => (
  <Card>
    {Array(4)
      .fill("")
      .map((e, i) => (
        <TableRow key={i} style={{ opacity: Number(2 / i).toFixed(1) }} />
      ))}
  </Card>
);

export const Title = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("19px")}
  margin-bottom: 40px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid ${colors.lightLt};
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
  margin-left:10px;
`;

const TableRow = (props: any) => {
  const random = Math.random() * (1 - 0.7) + 0.7;
  return (
    <ContentLoader height={40} width={1060} speed={2} {...props}>
      <rect x="0" y="15" rx="4" ry="4" width="6" height="6.4" />
      <rect x="34" y="13" rx="6" ry="6" width={200 * random} height="12" />
      <rect x="938" y="13" rx="6" ry="6" width={83 * random} height="12" />

      <rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
    </ContentLoader>
  );
};
