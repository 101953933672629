import { LocaleValue } from "@royalcanin-be-partner-portal/settings";
import { FORM_ERROR } from "final-form";
import gql from "graphql-tag";
import React from "react";
import { CardDescPartnerFragment } from "./__generated__/CardDescPartnerFragment";
import { Alert } from "./Alert";
import { Form } from "./Form";
import { IErrorResult, validationErrorsToObject } from "./lib/validationUtils";
import { ModalFooter } from "./ModalFooter";
import { OmitRecursively } from "./types";
import { InputDescription, IInputDescriptionValues } from "./InputDescription";
import striptags from "striptags";

type CardDescFormEditValues = OmitRecursively<
  CardDescPartnerFragment,
  "__typename"
>;

export const CardDescFormEdit = ({
  locale,
  onClose,
  onSubmit,
  initialValues,
}: {
  locale: LocaleValue;
  onClose: () => void;
  onSubmit: (
    values: CardDescFormEditValues,
  ) => Promise<IErrorResult | null | void>;
  initialValues: CardDescFormEditValues | undefined;
}) => {
  return (
    <Form<CardDescFormEditValues & IInputDescriptionValues>
      initialValues={{
        description: "",
        descriptionTranslations: [],
        descriptionByLang:
          initialValues?.descriptionTranslations?.reduce(
            (prev, item) => ({
              ...prev,
              [item.language.toLocaleLowerCase()]: item.value,
            }),
            {},
          ) || {},
        ...initialValues,
      }}
      onSubmit={async ({
        descriptionByLang,
        ...values
      }: CardDescFormEditValues & IInputDescriptionValues) => {
        const descriptionTranslations = Object.keys(descriptionByLang).map(
          (lang) => ({
            language: lang.toUpperCase(),
            value: striptags(descriptionByLang[lang] || "") as string,
          }),
        );

        const error = await onSubmit({
          ...values,
          descriptionTranslations,
        });
        if (error) {
          return {
            [FORM_ERROR]: error.message,
            ...validationErrorsToObject(error.validationErrors),
          };
        }

        onClose();
        return;
      }}
      render={({ handleSubmit, submitError }) => {
        return (
          <form onSubmit={handleSubmit}>
            {submitError && (
              <div style={{ marginBottom: 30 }}>
                <Alert type="error">{submitError}</Alert>
              </div>
            )}

            <InputDescription locale={locale} />

            <ModalFooter onClose={onClose} buttonType="submit" />
          </form>
        );
      }}
    />
  );
};

CardDescFormEdit.PartnerFragment = gql`
  fragment CardDescPartnerFragment on PartnerFull {
    description
    descriptionTranslations {
      language
      value
    }
  }
`;
