import * as React from "react";
import styled from "styled-components";
const SvgBreeder12 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M97.65 44.66A8 8 0 1186.33 56L50 19.66 13.67 56A8 8 0 112.35 44.66L44 2.35A8 8 0 0150 0a8 8 0 016 2.34l41.65 42.32zM35.8 83a27.81 27.81 0 01-2.47-12.13V58.33A8.33 8.33 0 0141.67 50h16.66a8.33 8.33 0 018.33 8.33v12.5A27.81 27.81 0 0164.2 83 8.34 8.34 0 0075 75a8.33 8.33 0 0116.67 0A25 25 0 0150 93.63 25 25 0 018.33 75 8.33 8.33 0 0125 75a8.34 8.34 0 0010.8 8z" />
  </svg>
);
export default styled(SvgBreeder12)``;
