import { CircularProgress } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";

const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.85);
    z-index: -1;
  }
`;

const Message = styled.div`
  margin-top: 20px;
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("16px")}
  color: ${colors.primary};

`;

export const PageLoading = () => (
  <Container>
    <CircularProgress color="primary" />
    <Message>
      <FormattedMessage id="website.loading" defaultMessage="Chargement..." />
    </Message>
  </Container>
);
