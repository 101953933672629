import * as React from "react";
import styled from "styled-components";
const SvgDeal20 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M37.5 45a7.5 7.5 0 117.5-7.5 7.5 7.5 0 01-7.5 7.5zm25 25a7.5 7.5 0 117.5-7.5 7.5 7.5 0 01-7.5 7.5zm6-38.46a5.25 5.25 0 010 7.42L39 68.46A5.25 5.25 0 1131.54 61L61 31.54a5.25 5.25 0 017.46 0zm11.68-7.83A40 40 0 1090 50a5 5 0 0110 0 50 50 0 11-12.23-32.77l-.71.71a5 5 0 11-6.91 5.77z" />
  </svg>
);
export default styled(SvgDeal20)``;
