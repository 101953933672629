import React from "react";
import { FormattedMessage } from "react-intl";
import { Card } from "./Card";
import { CardBillingFormEdit } from "./CardBillingFormEdit";
import { CardHeader } from "./CardHeader";
import { CardIconRow } from "./CardIconRow";
import { Fieldset } from "./Fieldset";
import { IconCoin16, IconPointer16, IconUser16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { countryName } from "./lib/utils";
import { Placeholder } from "./Placeholder";
import { ICountriesValue } from "./types";
import styled from "styled-components";
import { mediaQuery } from "react-styled-mediaquery";

export const CardBilling = ({
  loading,
  partner,
  onSubmit,
  countries = [],
  editMailTo,
}: {
  loading?: boolean;
  partner?: React.ComponentProps<typeof CardBillingFormEdit>["initialValues"];
  onSubmit: React.ComponentProps<typeof CardBillingFormEdit>["onSubmit"];
  countries?: ICountriesValue;
  editMailTo?: string;
}) => {
  // const [modalEditBillingIsOpen, setModalEditBillingIsOpen] = useState(false);
  return (
    <>
      {/* <Modal
        maxWidth="m"
        title={
          <FormattedMessage
            id="website.informations.billing.title"
            defaultMessage="Données de facturation"
          />
        }
        open={modalEditBillingIsOpen}
        onClose={() => setModalEditBillingIsOpen(false)}
      >
        <CardBillingFormEdit
          onClose={() => setModalEditBillingIsOpen(false)}
          initialValues={partner}
          onSubmit={onSubmit}
          countries={countries}
        />
      </Modal> */}
      <Container>
        <CardHeader href={editMailTo ? `mailto:${editMailTo}` : undefined}>
          <FormattedMessage
            id="website.informations.billing.title"
            defaultMessage="Données de facturation"
          />
        </CardHeader>
        {partner?.primaryContact?.firstname ||
        partner?.primaryContact?.lastname ? (
          <CardIconRow
            icon={<IconUser16 fill={colors.darkDk} width={16} height={14} />}
            title={[
              partner.primaryContact.firstname || "",
              partner.primaryContact.lastname || "",
            ]
              .join(" ")
              .trim()}
          />
        ) : null}
        <CardIconRow
          offset={20}
          icon={<IconPointer16 fill={colors.darkDk} width={16} height={14} />}
          title={
            <FormattedMessage
              id="website.informations.billing.address"
              defaultMessage="Adresse"
            />
          }
        >
          <div>
            {loading || !partner ? (
              <Placeholder />
            ) : (
              <>
                {partner.billing?.street1} {partner.billing?.streetnum}
              </>
            )}
          </div>
          <Fieldset offset={5}>
            {loading || !partner ? (
              <Placeholder />
            ) : (
              <>
                {partner.billing?.postalCode} {partner.billing?.city}
              </>
            )}
          </Fieldset>
          <Fieldset offset={5}>
            {loading || !partner ? (
              <Placeholder />
            ) : (
              countryName(countries, partner.billing?.country || undefined)
            )}
          </Fieldset>
        </CardIconRow>
        <CardIconRow
          offset
          icon={<IconCoin16 fill={colors.darkDk} width={16} height={16} />}
          title={
            <FormattedMessage
              id="website.informations.billing.tva"
              defaultMessage="Numéro de TVA"
            />
          }
        >
          <div>
            {loading || !partner ? <Placeholder /> : partner.vat || "-"}
          </div>
        </CardIconRow>
      </Container>
    </>
  );
};

const Container = styled(Card)`
  ${mediaQuery("<=", "960px")`
    margin-top: 15px;
  `}
`;
