import React from "react";
import styled from "styled-components";
import { Button } from "./Button";
import { IconWarning16 } from "./icons-ts";
import { fontFamily, fontSize } from "./lib/cssUtils";
import { ffdinPro } from "./lib/fonts";
import { colors } from "./lib/constants";
import { Modal } from "./Modal";
import { CircularProgress } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const ModalCardGift: React.FC<
  Pick<React.ComponentProps<typeof Modal>, "open" | "onClose"> &
    React.ComponentProps<typeof CardGift>
> = ({
  amount,
  company,
  points,
  image,
  open,
  onClose,
  onPurchase,
  isSubmitting,
}) => (
  <Modal onClose={onClose} open={open}>
    <ModalContent>
      <Display bg={image} />
      <TitleModal>
        Carte {company} {amount}
      </TitleModal>
      <DescModal>
        <FormattedMessage
          id="loyalty.purchase.trade"
          defaultMessage="Êtes-vous sûr de vouloir échanger vos points contre ce cadeau ?"
        />
      </DescModal>
      <div>
        <Button
          onClick={() => {
            if (isSubmitting) {
              return;
            }
            onPurchase();
          }}
          suffix={
            isSubmitting && <CircularProgress color="inherit" size={14} />
          }
        >
          <FormattedMessage
            id="loyalty.purchase.button"
            defaultMessage="Échanger contre {points} pts"
            values={{ points }}
          />
        </Button>
      </div>
    </ModalContent>
  </Modal>
);

export const CardGift: React.FC<{
  amount: React.ReactNode;
  company: string;
  points: number;
  userPoints: number;
  image: string;
  onPurchase: () => void | Promise<void>;
  isSubmitting?: boolean;
}> = ({
  amount,
  company,
  points,
  image,
  onPurchase,
  userPoints,
  isSubmitting,
}) => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  return (
    <Container>
      <Display bg={image} />
      <Title>
        <FormattedMessage
          id="loyalty.giftCard.title"
          defaultMessage="Carte {company} {amount}"
          values={{ company, amount }}
        />
      </Title>
      <Points>
        {points}{" "}
        <FormattedMessage id="loyalty.points.unit" defaultMessage="pts" />
      </Points>
      {userPoints < points ? (
        <Button
          prefixBackground={colors.warningLt}
          disabled
          prefix={<IconWarning16 fill={colors.warning} />}
          theme="warning"
          fullwidth
        >
          <FormattedMessage
            id="loyalty.giftCard.missing.points"
            defaultMessage="{points} points manquants"
            values={{
              points: Math.abs(userPoints - points),
            }}
          />
        </Button>
      ) : (
        <Button
          fullwidth
          onClick={() => {
            setModalIsOpen(true);
          }}
        >
          <FormattedMessage
            id="loyalty.button.exchange.points"
            defaultMessage="Échanger mes points"
          />
        </Button>
      )}
      <ModalCardGift
        open={modalIsOpen}
        onClose={() => {
          setModalIsOpen(false);
        }}
        amount={amount}
        company={company}
        points={points}
        userPoints={userPoints}
        image={image}
        onPurchase={async () => {
          await onPurchase();
          setModalIsOpen(false);
        }}
        isSubmitting={isSubmitting}
      />
    </Container>
  );
};

const TitleModal = styled.div`
  ${fontSize("19px")}
  ${fontFamily(ffdinPro.bold)}
`;

const DescModal = styled.div`
  ${fontSize("16px")}
  ${fontFamily(ffdinPro.regular)}
  margin-top: 15px;
  margin-bottom: 30px;
`;

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  ${fontSize("16px")}
  ${fontFamily(ffdinPro.bold)}
`;

const Points = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  ${fontSize("14px")}
`;

const Display = styled.div<{ bg: string }>`
  width: 200px;
  height: 200px;
  border-radius: 10px;
  margin-bottom: 40px;
  background: url(${(p) => p.bg}) no-repeat center/contain;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
`;
