import React from "react";
import { mediaQuery } from "react-styled-mediaquery";
import styled, { css } from "styled-components";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { IReactChildren } from "./types";

const Container = styled(
  ({
    offset,
    ...props
  }: { offset?: boolean | number } & React.HTMLAttributes<HTMLDivElement>) => (
    <div {...props} />
  ),
)`
  display: flex;
  ${(p) =>
    p.offset &&
    css`
      margin-top: ${typeof p.offset === "number" ? p.offset : 30}px;

      ${mediaQuery("<=", "768px")`
        margin-top: 20px;
      `}
    `}
`;

const Icon = styled.div`
  margin-right: 20px;
`;

const Content = styled.div`
  flex: 1;
`;

const Title = styled.div`
  ${fontSize("16px")}
  ${fontFamily(ffdinPro.bold)}
  margin-bottom: 10px;
`;

export const CardIconRow = ({
  icon,
  title,
  children,
  offset,
}: {
  icon: React.ReactNode;
  title: IReactChildren;
  children?: React.ReactNode;
  offset?: boolean | number;
}) => (
  <Container offset={offset}>
    <Icon>{icon}</Icon>
    <Content>
      <Title>{title}</Title>
      {children}
    </Content>
  </Container>
);
