import React, { useState } from "react";
import styled, { css } from "styled-components";
import { LanguageSelector } from "./LanguageSelector";
import { colors } from "./lib/constants";
import { LogicalLink } from "./LogicalLink";
import { Tooltip } from "./Tooltip";
import { INavLink } from "./types";

const Container = styled.div`
  background: #fff;
  bottom: 0;
  box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.1);
  left: 0;
  position: fixed;
  top: 0;
  width: 90px;
  z-index: 2;
  display: flex;
`;

const Inner = styled.div`
  position: relative;
  padding: 20px 0;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Item = styled(
  ({
    active,
    size,
    children,
    ...props
  }: {
    active?: boolean;
    size?: number;
    children: React.ReactNode;
  }) => <div {...props}>{children}</div>,
)`
  align-items: center;
  background: transparent;
  border-radius: 25px;
  display: inline-flex;
  height: ${(p) => (p.size ? p.size : 50)}px;
  width: ${(p) => (p.size ? p.size : 50)}px;
  justify-content: center;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: ${colors.primaryLt};

    svg {
      fill: ${colors.primary};
    }
  }

  ${(p) =>
    p.active &&
    css`
      background: ${colors.primaryLt};

      svg {
        fill: ${colors.primary};
      }
    `}

  svg {
    display: block;
    transition: fill 0.2s;
  }
`;

const Link = styled(LogicalLink)`
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

export const LayoutNavigation = ({
  navLinks,
  locale,
  onLocaleChange,
  onClickLogout,
}: {
  navLinks: INavLink[];
  locale: string;
  onLocaleChange: (locale: any) => void;
  onClickLogout: () => void;
}) => {
  const [isOpen, setisOpen] = useState(false);

  return (
    <Container>
      <Inner>
        {navLinks.map((item, i) => (
          <Tooltip
            caps
            distance={10}
            placement="right"
            key={i}
            content={item.label}
          >
            <Link href={item.href}>
              <Item active={!!item.active}>{item.icon}</Item>
            </Link>
          </Tooltip>
        ))}
        <LanguageSelector
          isOpen={isOpen}
          onClick={() => setisOpen(!isOpen)}
          locale={locale}
          onLocaleChange={onLocaleChange}
        />
        {/* <Logout
          onClick={() => {
            onClickLogout();
          }}
        /> */}
      </Inner>
    </Container>
  );
};

// const Logout = ({ onClick }: { onClick: (e: React.MouseEvent) => void }) => (
//   <Tooltip
//     caps
//     distance={10}
//     placement="right"
//     content={
//       <FormattedMessage id="button.label.logout" defaultMessage="Déconnexion" />
//     }
//   >
//     <LogoutItem href="#" onClick={onClick}>
//       <Item>
//         <IconLogout />
//       </Item>
//     </LogoutItem>
//   </Tooltip>
// );

// const LogoutItem = styled(Link)`
//   position: absolute;
//   bottom: 20px;
//   cursor: pointer;
//   color: inherit;
// `;
