import { FORM_ERROR } from "final-form";
import gql from "graphql-tag";
import React from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { Col, Row } from "react-styled-flexboxgrid";
import { object, string, boolean } from "yup";
import { Alert } from "./Alert";
import { Form, useInputProps } from "./Form";
import { FormTitle } from "./FormTitle";
import { Input } from "./Input";
import { IErrorResult, validationErrorsToObject } from "./lib/validationUtils";
import { ModalFooter } from "./ModalFooter";
import { OmitRecursively } from "./types";
import { CardBreederInfosPartnerFragment } from "./__generated__/CardBreederInfosPartnerFragment";
import { InputBoolean } from "./InputBoolean";
import { Fieldset } from "./Fieldset";

type CardBreederInfosFormEditValues = OmitRecursively<
  CardBreederInfosPartnerFragment,
  "__typename"
>;

export const messages = defineMessages({
  breederHKNumber: {
    id: "form.label.breederHKNumber",
    defaultMessage: "Numéro HK",
  },
  occasionalBreeder: {
    id: "form.label.occasionalBreeder",
    defaultMessage: "Je suis éleveur occasionnel",
  },
});

export const CardBreederInfosFormEdit = ({
  onClose,
  onSubmit,
  initialValues,
  HKNumberMandatory,
}: {
  onClose: () => void;
  onSubmit: (
    values: CardBreederInfosFormEditValues,
  ) => Promise<IErrorResult | null | void>;
  initialValues: CardBreederInfosFormEditValues | undefined;
  HKNumberMandatory?: boolean;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Form<CardBreederInfosFormEditValues>
      initialValues={{
        HKNumber: initialValues?.HKNumber || "",
        occasionalBreeder: initialValues?.occasionalBreeder || false,
      }}
      fieldLabels={{
        HKNumber: formatMessage(messages.breederHKNumber),
        occasionalBreeder: formatMessage(messages.occasionalBreeder),
      }}
      schema={object().shape({
        HKNumber: string(),
        // HKNumber: HKNumberMandatory
        //   ? string().required()
        //   : string().when("occasionalBreeder", {
        //       is: (occasionalBreeder) => !occasionalBreeder,
        //       then: string().required(),
        //     }),
        occasionalBreeder: boolean(),
      })}
      onSubmit={async (values: CardBreederInfosFormEditValues) => {
        const error = await onSubmit(values);
        if (error) {
          return {
            [FORM_ERROR]: error.message,
            ...validationErrorsToObject(error.validationErrors),
          };
        }

        onClose();
        return;
      }}
      render={({ handleSubmit, submitError, values }) => {
        const occasionalBreederProps = useInputProps({
          name: "occasionalBreeder",
          config: {
            type: "checkbox",
          },
        });
        const HKNumberProps = useInputProps({ name: "HKNumber" });

        return (
          <form onSubmit={handleSubmit}>
            {submitError && (
              <div style={{ marginBottom: 30 }}>
                <Alert type="error">{submitError}</Alert>
              </div>
            )}
            <FormTitle>
              <FormattedMessage
                id="form.title.breederInfos"
                defaultMessage="Identification de votre élevage"
              />
            </FormTitle>
            {!HKNumberMandatory && (
              <Row>
                <Col xs={12} md={6}>
                  <Fieldset>
                    <InputBoolean {...occasionalBreederProps} />
                  </Fieldset>
                </Col>
              </Row>
            )}

            {HKNumberMandatory || !values.occasionalBreeder ? (
              <Row>
                <Col xs={12} md={12}>
                  <Fieldset offset={HKNumberMandatory ? 0 : 30}>
                    <Input fullWidth {...HKNumberProps} />
                  </Fieldset>
                </Col>
              </Row>
            ) : null}

            <ModalFooter onClose={onClose} buttonType="submit" />
          </form>
        );
      }}
    />
  );
};

CardBreederInfosFormEdit.PartnerFragment = gql`
  fragment CardBreederInfosPartnerFragment on PartnerFull {
    HKNumber
    occasionalBreeder
  }
`;
