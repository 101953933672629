import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";

export const CardsRow = styled.div`
  margin: 30px 0;

  ${mediaQuery("<=", "960px")`
    margin: 15px 0;
  `}
`;
