import * as React from "react";
import styled from "styled-components";
const SvgPicture16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M28.13 18.75a9.38 9.38 0 11-9.37 9.38 9.37 9.37 0 019.37-9.38zM75 12.5H25A12.5 12.5 0 0012.5 25v34.37l3.78-4.71a12.5 12.5 0 0118.6-1l10.7 10.67a6.25 6.25 0 008.84 0l22.89-22.89a12.48 12.48 0 0110.19-3.59V25A12.5 12.5 0 0075 12.5zm11.15 37.78L63.26 73.17a18.75 18.75 0 01-26.52 0L26 62.46 13 78.69a12.51 12.51 0 0012 8.81h50A12.5 12.5 0 0087.5 75V52.08zM75 0a25 25 0 0125 25v50a25 25 0 01-25 25H25A25 25 0 010 75V25A25 25 0 0125 0z" />
  </svg>
);
export default styled(SvgPicture16)``;
