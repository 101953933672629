import * as React from "react";
import styled from "styled-components";

const DefaultLogicalLink = styled.a``;
let Link = DefaultLogicalLink;

export const setLogicalLink = (Component: any) => {
  Link = Component;
};

export interface ILogicalLinkProps
  extends Omit<React.HTMLAttributes<HTMLAnchorElement>, "prefix"> {
  to?: string;
  target?: string;
  href?: string;
}

const LogicalLink = React.forwardRef<any, ILogicalLinkProps>(
  (props: ILogicalLinkProps, ref) => {
    return <Link {...props} ref={ref} />;
  },
);

export { LogicalLink };
