import * as React from "react";
import styled from "styled-components";
const SvgEyeOff16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M98.6 46A95.41 95.41 0 0077 27.33l9-10.85a6.25 6.25 0 00-9.63-8l-11 13.16A44.83 44.83 0 0050 18.79C24 18.79 2.3 44.92 1.42 46a6.22 6.22 0 000 7.92 95.41 95.41 0 0021.64 18.75L14 83.52a6.25 6.25 0 009.63 8l11-13.16A44.83 44.83 0 0050 81.15c26 0 47.73-26.15 48.6-27.25a6.22 6.22 0 000-7.9zM31.25 62.81A81.7 81.7 0 0114.68 50C22 42.55 36.13 31.26 50 31.26a29.91 29.91 0 016.75.87l-4.62 5.56A12.45 12.45 0 0037.51 50a12.38 12.38 0 00.8 4.36zM50 68.68a29.91 29.91 0 01-6.75-.87l4.62-5.56A12.45 12.45 0 0062.52 50a12.38 12.38 0 00-.8-4.36l7.06-8.48A81.7 81.7 0 0185.35 50C78 57.45 63.9 68.68 50 68.68z" />
  </svg>
);
export default styled(SvgEyeOff16)``;
