import React from "react";
import { FormattedMessage } from "react-intl";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { Button } from "./Button";
import { IconArrowR10 } from "./icons-ts";

const Container = styled.div`
  text-align: center;
  margin-top: 60px;

  ${mediaQuery("<=", "phablet")`
    margin-top: 30px;
  `}
`;

export const LittersAddSubmit = ({ disabled }: { disabled?: boolean }) => (
  <Container>
    <Button
      suffix={<IconArrowR10 height={10} width={5} fill="#fff" />}
      type="submit"
      disabled={disabled}
    >
      <FormattedMessage
        id="website.litters.add.submit"
        defaultMessage="Commencer"
      />
    </Button>
  </Container>
);
