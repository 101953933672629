/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Locale {
  fr = "fr",
  nl = "nl",
}

export interface LitterInput {
  availableFemale?: number | null;
  availableMale?: number | null;
  breedId: string;
  chippedTatooed?: boolean | null;
  dateAvailable?: string | null;
  dateVisibleFrom?: string | null;
  dateVisibleTo?: string | null;
  descriptions?: LitterInputDescription[] | null;
  dob: string;
  female: number;
  genderUnspecifiedCount?: number | null;
  idFather?: string | null;
  idMother?: string | null;
  identification?: string | null;
  male: number;
  maxPrice?: string | null;
  neutered?: boolean | null;
  originBreeder?: string | null;
  originCountry?: string | null;
  packRange: string;
  pedigreeFCI?: boolean | null;
  photos?: LitterPhotoInput[] | null;
  pureBreed?: boolean | null;
  royalStartId?: string | null;
  royalStartSyncAvailable?: boolean | null;
  startingPrice?: string | null;
  type: string;
  vaccinated?: boolean | null;
}

export interface LitterInputBase {
  availableFemale?: number | null;
  availableMale?: number | null;
  chippedTatooed?: boolean | null;
  dateAvailable?: string | null;
  dateVisibleFrom?: string | null;
  dateVisibleTo?: string | null;
  descriptions?: LitterInputDescription[] | null;
  genderUnspecifiedCount?: number | null;
  idFather?: string | null;
  idMother?: string | null;
  identification?: string | null;
  maxPrice?: string | null;
  neutered?: boolean | null;
  originBreeder?: string | null;
  originCountry?: string | null;
  pedigreeFCI?: boolean | null;
  photos?: LitterPhotoInput[] | null;
  pureBreed?: boolean | null;
  royalStartId?: string | null;
  royalStartSyncAvailable?: boolean | null;
  startingPrice?: string | null;
  vaccinated?: boolean | null;
}

export interface LitterInputDescription {
  language: string;
  value?: string | null;
}

export interface LitterPhotoInput {
  id: string;
  url: string;
}

export interface PartnerDescriptionTranslationsInput {
  language: string;
  value?: string | null;
}

export interface PartnerFullAddressInput {
  city?: string | null;
  country?: string | null;
  postalCode?: string | null;
  street1?: string | null;
  streetnum?: string | null;
}

export interface PartnerFullInput {
  HKNumber?: string | null;
  address?: PartnerFullAddressInput | null;
  billing?: PartnerFullAddressInput | null;
  description?: string | null;
  descriptionGeneric?: string | null;
  descriptionTranslations?: PartnerDescriptionTranslationsInput[] | null;
  email?: string | null;
  extId: string;
  facebook?: string | null;
  fax?: string | null;
  hasLogo?: boolean | null;
  instagram?: string | null;
  isConv?: boolean | null;
  lat?: string | null;
  linkedin?: string | null;
  lng?: string | null;
  logo?: string | null;
  logoLarge?: string | null;
  logoMedium?: string | null;
  logoSmall?: string | null;
  name?: string | null;
  occasionalBreeder?: boolean | null;
  openingHours?: PartnerOpeningHoursInput[] | null;
  partnerGroups?: string[] | null;
  phone?: string | null;
  pictures?: PartnerPictureInput[] | null;
  primaryContact?: PartnerPrimaryContactDetailsInput | null;
  publicAddressDisplayPreference?: string | null;
  salesrep?: string | null;
  species?: string | null;
  twitter?: string | null;
  type?: string | null;
  vat?: string | null;
  website?: string | null;
  whatsapp?: string | null;
  youtube?: string | null;
}

export interface PartnerOpeningHoursInput {
  day: string;
  dayIndex: number;
  periods: PartnerOpeningHoursPeriodInput[];
}

export interface PartnerOpeningHoursPeriodInput {
  from: string;
  to: string;
}

export interface PartnerPictureInput {
  content: string;
  id: number;
}

export interface PartnerPrimaryContactDetailsInput {
  email?: string | null;
  firstname?: string | null;
  guid?: string | null;
  language?: string | null;
  lastname?: string | null;
  mobile?: string | null;
  title?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
