import React from "react";
import { FormattedMessage } from "react-intl";
import posed from "react-pose";
import styled from "styled-components";
import { IconCross16, IconServices16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { Link } from "./Link";
import { IService } from "./types";

const Container = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px -5px rgba(51, 51, 51, 0.1);
  border-radius: 25px;
  display: flex;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 17px 25px;
  transition: color 0.2s;

  svg {
    transition: fill 0.2s;
  }

  &:hover {
    color: ${colors.primary};

    svg {
      fill: ${colors.primary};
    }
  }
`;

const IconContainer = styled.div`
  position: relative;
`;

const Label = styled.div`
  ${fontFamily(ffdinPro.bold)};
  ${fontSize("14px")}
  margin-left: 15px;
`;

const Detail = posed.div({
  off: {
    width: 0,
    opacity: 0,
    overflow: "hidden",
  },
  on: {
    width: "auto",
    opacity: 1,
    transition: { type: "spring", stiffness: 150, damping: 20 },
  },
});

const Items = styled(Detail)`
  display: flex;
`;

const Item = styled(Link)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 17px 25px;
  position: relative;

  svg {
    transition: fill 0.2s;
  }

  &:hover {
    svg {
      fill: ${colors.primaryDk};
    }
  }

  &:not(:first-child) {
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -10px;
      left: 0;
      height: 20px;
      width: 1px;
      background: ${colors.lightLt};
    }
  }

  &:first-child {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 15px;
      background-image: linear-gradient(
        90deg,
        rgba(51, 51, 51, 0.05) 0%,
        rgba(51, 51, 51, 0) 100%
      );
    }
  }
`;

const Icon = posed.div({
  off: {
    opacity: 0,
  },
  on: {
    opacity: 1,
  },
});

const ItemLabel = styled.div`
  ${fontFamily(ffdinPro.medium)};
  ${fontSize("14px")}
  margin-left: 15px;
`;

export const LayoutFooterServices = ({
  services,
  isOpen,
  onClick,
}: {
  services: IService[];
  isOpen: boolean;
  onClick: () => void;
}) => (
  <Container>
    <Button onClick={onClick}>
      <IconContainer>
        <Icon pose={isOpen ? "off" : "on"}>
          <IconServices16
            style={{ display: "block" }}
            width={16}
            height={16}
            fill={colors.darkDk}
          />
        </Icon>
        <Icon
          style={{ left: 0, position: "absolute", top: 0, zIndex: 2 }}
          pose={isOpen ? "on" : "off"}
        >
          <IconCross16
            style={{ display: "block" }}
            width={16}
            height={16}
            fill={colors.darkDk}
          />
        </Icon>
      </IconContainer>
      <Label>
        <FormattedMessage
          id="website.services.title"
          defaultMessage="Services"
        />
      </Label>
    </Button>
    <Items pose={isOpen ? "on" : "off"}>
      <>
        {services.map((item, i) => (
          <Item key={i} borderless href={item.href} target="_blank" onClick={item.onClick}>
            {item.icon}
            <ItemLabel>{item.label}</ItemLabel>
          </Item>
        ))}
      </>
    </Items>
  </Container>
);
