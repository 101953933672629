import * as React from "react";
import styled from "styled-components";
const SvgCross10 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M64.78 50l32.16 32.16a10.45 10.45 0 11-14.78 14.78L50 64.78 17.84 96.94A10.45 10.45 0 113.06 82.16L35.22 50 3.06 17.84A10.45 10.45 0 0117.84 3.06L50 35.22 82.16 3.06a10.45 10.45 0 0114.78 14.78z" />
  </svg>
);
export default styled(SvgCross10)``;
