import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Card } from "./Card";
import { CardHeader } from "./CardHeader";
import { Modal } from "./Modal";
import { Placeholder } from "./Placeholder";
import { CardBreederInfosFormEdit, messages } from "./CardBreederInfosFormEdit";
import styled from "styled-components";
import { fontFamily, ffdinPro } from "./lib/fonts";
import { colors } from "./lib/constants";

interface IRegion {
  reference: string;
  isFlanders: boolean;
}

export const CardBreederInfos = ({
  partner,
  region,
  loading,
  onSubmit,
  autoOpenForm,
}: {
  partner?: React.ComponentProps<
    typeof CardBreederInfosFormEdit
  >["initialValues"];
  region?: IRegion;
  onSubmit: React.ComponentProps<typeof CardBreederInfosFormEdit>["onSubmit"];
  loading?: boolean;
  autoOpenForm?: boolean;
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (autoOpenForm) {
      setTimeout(() => {
        setModalIsOpen(true);
      }, 100);
    }
  }, [autoOpenForm]);

  return (
    <>
      <Modal
        maxWidth="m"
        title={
          <FormattedMessage
            id="informations.breederInfos.title"
            defaultMessage="Identification de votre élevage"
          />
        }
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      >
        <CardBreederInfosFormEdit
          onClose={() => setModalIsOpen(false)}
          onSubmit={onSubmit}
          initialValues={partner}
          HKNumberMandatory={region?.isFlanders ? false : true}
        />
      </Modal>
      <Card>
        <CardHeader onClick={() => setModalIsOpen(true)}>
          <FormattedMessage
            id="informations.breederInfos.title"
            defaultMessage="Identification de votre élevage"
          />
        </CardHeader>
        {loading || !partner ? (
          <Placeholder />
        ) : (
          <>
            {partner.HKNumber ? (
              <div>
                <TextBold>
                  <FormattedMessage {...messages.breederHKNumber} /> :
                </TextBold>{" "}
                {partner.HKNumber}
              </div>
            ) : (
              <div>
                {region?.isFlanders ? (
                  <div>
                    <TextBold>
                      <FormattedMessage {...messages.occasionalBreeder} />:{" "}
                    </TextBold>
                    {partner.occasionalBreeder ? (
                      <FormattedMessage id="yes" defaultMessage="Oui" />
                    ) : (
                      <FormattedMessage id="no" defaultMessage="Non" />
                    )}
                  </div>
                ) : (
                  <TextWarning>
                    <FormattedMessage
                      id="informations.breederInfos.warning_no_hk_number"
                      defaultMessage="Vous devez compléter l'identification de votre élevage pour être visible sur les sites Royal Canin"
                    />
                  </TextWarning>
                )}
              </div>
            )}
          </>
        )}
      </Card>
    </>
  );
};

const TextBold = styled.span`
  ${fontFamily(ffdinPro.bold)}
`;

const TextWarning = styled.span`
  color: ${colors.warning};
`;
