import * as React from "react";
import styled from "styled-components";
const SvgEyeOn16 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M50 37.5A12.5 12.5 0 1137.5 50 12.5 12.5 0 0150 37.5zm0-6.25C36.12 31.25 22.05 42.5 14.68 50c6.25 6.25 20.5 18.75 35.32 18.75 13.88 0 27.95-11.25 35.32-18.75-6.25-6.25-20.5-18.75-35.32-18.75zm0-12.5c26 0 47.7 26.19 48.58 27.31a6.25 6.25 0 010 7.94C97.7 55.06 76 81.25 50 81.25S2.3 55.06 1.42 53.94a6.25 6.25 0 010-7.94C2.3 44.94 24 18.75 50 18.75z" />
  </svg>
);
export default styled(SvgEyeOn16)``;
