import * as React from "react";
import styled from "styled-components";
const SvgFolder20 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M90 45a5 5 0 00-5-5H57.07a15 15 0 01-10.61-4.39L35.86 20H15a5 5 0 00-5 5v50a5 5 0 005 5h70a5 5 0 005-5zm0-14.15V25a5 5 0 00-5-5H65a5 5 0 010-10h20a15 15 0 0115 15v50a15 15 0 01-15 15H15A15 15 0 010 75V25a15 15 0 0115-15h22.93a5 5 0 013.54 1.46l12.07 17.08A5 5 0 0057.07 30H85a15 15 0 015 .85z" />
  </svg>
);
export default styled(SvgFolder20)``;
