import React from "react";
import { Form, useInputProps } from "./Form";
import {
  AlertContainer,
  FormLoginContainer,
  Footer,
  Submit,
} from "./LoginForm";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import { Alert } from "./Alert";
import { Link } from "./Link";
import { Input } from "./Input";
import * as yup from "yup";

export const messages = defineMessages({
  password: {
    id: "form.label.newPassword",
    defaultMessage: "Nouveau mot de passe",
  },
  passwordConfirm: {
    id: "form.label.passwordConfirm",
    defaultMessage: "Confirmer le mot de passe",
  },
  confirmPasswordMatch: {
    id: "form.error.confirmPasswordMatch",
    defaultMessage: "Les mots de passe doivent correspondre",
  },
  passwordErrorRgx: {
    id: "form.error.password.invalidRgx",
    defaultMessage: "La complexité de votre mot de passe n’est pas assez forte",
  },
});

interface IFormValues {
  password: string;
  passwordConfirm: string;
}

export const PasswordManagerForm = ({
  loading,
  error,
  disabled,
  onSubmit,
  isSuccess,
  passwordPolicy,
}: {
  loading?: boolean;
  error?: string | JSX.Element;
  disabled?: boolean;
  onSubmit: (password: string) => void | Promise<void>;
  isSuccess?: boolean;
  passwordPolicy?: string;
}) => {
  const { formatMessage } = useIntl();
  return (
    <FormLoginContainer>
      <Form<IFormValues>
        loading={loading}
        initialValues={{
          password: "",
          passwordConfirm: "",
        }}
        fieldLabels={{
          password: formatMessage(messages.password),
          passwordConfirm: formatMessage(messages.passwordConfirm),
        }}
        schema={yup.object().shape({
          password: passwordPolicy
            ? yup
                .string()
                .matches(
                  new RegExp(passwordPolicy),
                  formatMessage(messages.passwordErrorRgx),
                )
                .required()
            : yup.string().min(6).required(),
          passwordConfirm: yup
            .string()
            .oneOf(
              [yup.ref("password"), null],
              formatMessage(messages.confirmPasswordMatch),
            ),
        })}
        onSubmit={(values) => {
          onSubmit(values.password);
        }}
        render={({ handleSubmit, submitting, pristine, submitError }) => (
          <form onSubmit={handleSubmit}>
            {(submitError || error) && (
              <AlertContainer>
                <Alert type="error">{submitError || error}</Alert>
              </AlertContainer>
            )}
            {!!isSuccess && (
              <AlertContainer>
                <Alert type="success">
                  <div>
                    <FormattedMessage
                      id="recoverypassword.success_message"
                      defaultMessage="Votre mot de passe à bien été changé !"
                    />{" "}
                    <Link href="/">
                      <FormattedMessage
                        id="button.label.recoveryPassword.successGoToLogin"
                        defaultMessage="Cliquez ici pour vous connecter"
                      />
                    </Link>
                  </div>
                </Alert>
              </AlertContainer>
            )}
            <Input
              {...useInputProps({ name: "password" })}
              fullWidth
              type="password"
              inputProps={{
                tabIndex: 1,
              }}
              disabled={disabled}
            />
            <Input
              {...useInputProps({ name: "passwordConfirm" })}
              fullWidth
              type="password"
              style={{ marginTop: 30 }}
              inputProps={{
                tabIndex: 2,
              }}
              disabled={disabled}
            />
            <Footer>
              <Link href="/">
                <FormattedMessage
                  id="login.submit"
                  defaultMessage="Se connecter"
                />
              </Link>
              <Submit disabled={submitting || pristine || disabled}>
                <FormattedMessage
                  id="passwordManager.submit"
                  defaultMessage="Envoyer"
                />
              </Submit>
            </Footer>
          </form>
        )}
      />
    </FormLoginContainer>
  );
};
