import { UserProvider } from "@igloo-be-omnipartners/hooks";
import { UIKitProvider } from "@royalcanin-be-partner-portal/ui-kit";
import { SnackbarProvider } from "notistack";
import React from "react";
import { GraphQLProvider } from "./GraphQLProvider";
import { LocaleProvider } from "./locale";
import { SentryConfigure } from "./SentryConfigure";

export const MainProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <LocaleProvider>
      <UIKitProvider>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          maxSnack={10}
          preventDuplicate
          autoHideDuration={6000}
        >
          <GraphQLProvider>
            <UserProvider>
              <>
                <SentryConfigure />
                {children}
              </>
            </UserProvider>
          </GraphQLProvider>
        </SnackbarProvider>
      </UIKitProvider>
    </LocaleProvider>
  );
};
