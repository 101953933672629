import React from "react";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "react-styled-flexboxgrid";
import { mediaQuery } from "react-styled-mediaquery";
import styled from "styled-components";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { Link } from "./Link";
import { IResource } from "./types";

const ColRessource = styled(Col)`
  &:not(:nth-child(-n + 3)) {
    margin-top: 60px;
  }

  ${mediaQuery("<=", "960px")`
    &:not(:nth-child(-n + 2)) {
      margin-top: 60px;
    }
  `}

  ${mediaQuery("<=", "mobile")`
    &:not(:first-child) {
      margin-top: 60px;
    }
  `}
`;

const Container = styled.div`
  margin-top: 60px;

  ${mediaQuery("<=", "tablet")`
    margin-top: 40px;
  `}
`;

const Item = styled.div`
  display: flex;
  flex: 1;
`;

const Cover = styled.div`
  width: 100px;
  height: 153px;
  margin-right: 30px;

  ${mediaQuery("<=", "mobile")`
    flex-shrink: 1;
  `}

  flex-shrink: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const LinkContainer = styled.div`
  margin-top: auto;
`;

const Title = styled.div`
  ${fontSize("16px")}
  ${fontFamily(ffdinPro.bold)}
`;

const Category = styled.div`
  ${fontSize("14px")}
  color: ${colors.dark};
  margin-top: 10px;
`;

export const ResourcesList = ({ resources }: { resources: IResource[] }) => (
  <Container>
    <Row>
      {resources.map((item, i) => (
        <ColRessource key={i} xs={12} sm={6} md={6} lg={4}>
          <Item>
            <Cover
              style={{
                background: `url(${item.imageUrl}) no-repeat center/contain`,
              }}
            />
            <Content>
              <div>
                <Title>{item.title}</Title>
                <Category>{item.sectionName}</Category>
                {!!item.subtitle && (
                  <div style={{ marginTop: 10 }}>{item.subtitle}</div>
                )}
              </div>
              {!!item.link && (
                <LinkContainer>
                  <Link href={item.link} target="_blank">
                    <FormattedMessage
                      id="website.resources.link"
                      defaultMessage="Télécharger"
                    />
                  </Link>
                </LinkContainer>
              )}
            </Content>
          </Item>
        </ColRessource>
      ))}

      {!resources.length && (
        <ColRessource xs={12} sm={12} md={12} lg={12}>
          <FormattedMessage
            id="website.resources.noResource"
            defaultMessage="Aucune resource disponible pour le moment"
          />
        </ColRessource>
      )}
    </Row>
  </Container>
);
