import ArrowB10 from "./ArrowB10";
export const IconArrowB10 = ArrowB10;

import ArrowB16 from "./ArrowB16";
export const IconArrowB16 = ArrowB16;

import ArrowL10 from "./ArrowL10";
export const IconArrowL10 = ArrowL10;

import ArrowL16 from "./ArrowL16";
export const IconArrowL16 = ArrowL16;

import ArrowR10 from "./ArrowR10";
export const IconArrowR10 = ArrowR10;

import ArrowR16 from "./ArrowR16";
export const IconArrowR16 = ArrowR16;

import ArrowT10 from "./ArrowT10";
export const IconArrowT10 = ArrowT10;

import ArrowT16 from "./ArrowT16";
export const IconArrowT16 = ArrowT16;

import Bag16 from "./Bag16";
export const IconBag16 = Bag16;

import Barcode16 from "./Barcode16";
export const IconBarcode16 = Barcode16;

import Breeder12 from "./Breeder12";
export const IconBreeder12 = Breeder12;

import Breeder16 from "./Breeder16";
export const IconBreeder16 = Breeder16;

import Burger16 from "./Burger16";
export const IconBurger16 = Burger16;

import Calendar16 from "./Calendar16";
export const IconCalendar16 = Calendar16;

import Check10 from "./Check10";
export const IconCheck10 = Check10;

import Close80 from "./Close80";
export const IconClose80 = Close80;

import Club12 from "./Club12";
export const IconClub12 = Club12;

import Club16 from "./Club16";
export const IconClub16 = Club16;

import Coin16 from "./Coin16";
export const IconCoin16 = Coin16;

import Coupon20 from "./Coupon20";
export const IconCoupon20 = Coupon20;

import Cross10 from "./Cross10";
export const IconCross10 = Cross10;

import Cross16 from "./Cross16";
export const IconCross16 = Cross16;

import Cup20 from "./Cup20";
export const IconCup20 = Cup20;

import Deal20 from "./Deal20";
export const IconDeal20 = Deal20;

import Desktop16 from "./Desktop16";
export const IconDesktop16 = Desktop16;

import Doc16 from "./Doc16";
export const IconDoc16 = Doc16;

import Euro16 from "./Euro16";
export const IconEuro16 = Euro16;

import EyeClose16 from "./EyeClose16";
export const IconEyeClose16 = EyeClose16;

import EyeOff16 from "./EyeOff16";
export const IconEyeOff16 = EyeOff16;

import EyeOn16 from "./EyeOn16";
export const IconEyeOn16 = EyeOn16;

import EyeOpen16 from "./EyeOpen16";
export const IconEyeOpen16 = EyeOpen16;

import Facebook16 from "./Facebook16";
export const IconFacebook16 = Facebook16;

import Female16 from "./Female16";
export const IconFemale16 = Female16;

import Folder20 from "./Folder20";
export const IconFolder20 = Folder20;

import Food20 from "./Food20";
export const IconFood20 = Food20;

import Gear20 from "./Gear20";
export const IconGear20 = Gear20;

import Groom12 from "./Groom12";
export const IconGroom12 = Groom12;

import Groom16 from "./Groom16";
export const IconGroom16 = Groom16;

import Info16 from "./Info16";
export const IconInfo16 = Info16;

import Instagram16 from "./Instagram16";
export const IconInstagram16 = Instagram16;

import Key20 from "./Key20";
export const IconKey20 = Key20;

import Kit40 from "./Kit40";
export const IconKit40 = Kit40;

import Kit60 from "./Kit60";
export const IconKit60 = Kit60;

import Litter20 from "./Litter20";
export const IconLitter20 = Litter20;

import Litter60 from "./Litter60";
export const IconLitter60 = Litter60;

import Magnify16 from "./Magnify16";
export const IconMagnify16 = Magnify16;

import Mail16 from "./Mail16";
export const IconMail16 = Mail16;

import Male16 from "./Male16";
export const IconMale16 = Male16;

import Minus10 from "./Minus10";
export const IconMinus10 = Minus10;

import News16 from "./News16";
export const IconNews16 = News16;

import Pack16 from "./Pack16";
export const IconPack16 = Pack16;

import Pen16 from "./Pen16";
export const IconPen16 = Pen16;

import Picture16 from "./Picture16";
export const IconPicture16 = Picture16;

import Plane16 from "./Plane16";
export const IconPlane16 = Plane16;

import Plus10 from "./Plus10";
export const IconPlus10 = Plus10;

import Pointer16 from "./Pointer16";
export const IconPointer16 = Pointer16;

import PointerMap from "./PointerMap";
export const IconPointerMap = PointerMap;

import RoundedCheck16 from "./RoundedCheck16";
export const IconRoundedCheck16 = RoundedCheck16;

import RoundedCheck50 from "./RoundedCheck50";
export const IconRoundedCheck50 = RoundedCheck50;

import RoundedCross16 from "./RoundedCross16";
export const IconRoundedCross16 = RoundedCross16;

import Services16 from "./Services16";
export const IconServices16 = Services16;

import Shop12 from "./Shop12";
export const IconShop12 = Shop12;

import Shop16 from "./Shop16";
export const IconShop16 = Shop16;

import Trash16 from "./Trash16";
export const IconTrash16 = Trash16;

import Twitter16 from "./Twitter16";
export const IconTwitter16 = Twitter16;

import Upload16 from "./Upload16";
export const IconUpload16 = Upload16;

import User16 from "./User16";
export const IconUser16 = User16;

import User20 from "./User20";
export const IconUser20 = User20;

import Vet12 from "./Vet12";
export const IconVet12 = Vet12;

import Vet16 from "./Vet16";
export const IconVet16 = Vet16;

import Video16 from "./Video16";
export const IconVideo16 = Video16;

import Warning16 from "./Warning16";
export const IconWarning16 = Warning16;

import World20 from "./World20";
export const IconWorld20 = World20;

import Youtube16 from "./Youtube16";
export const IconYoutube16 = Youtube16;
