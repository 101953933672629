import React from "react";
import { mediaQuery } from "react-styled-mediaquery";
import styled, { css } from "styled-components";
import { Button } from "./Button";
import { Swipper } from "./Swiper";

const Container = styled.div<{ hasScroll: boolean }>`
  bottom: 10%;
  left: 15px;
  position: fixed;
  right: 15px;
  text-align: center;

  ${mediaQuery("<=", "phablet")`
    button { width: 100%; }
  `}

  ${p =>
    p.hasScroll &&
    css`
      bottom: 1%;
    `}
`;

const FiltersMobilePanelButton = ({
  onClick,
  hasScroll,
}: {
  onClick: () => void;
  hasScroll: boolean;
}) => {
  return (
    <Container hasScroll={!!hasScroll}>
      <Button theme="dark" onClick={onClick}>
        Filtrer
      </Button>
    </Container>
  );
};

export const FiltersMobilePanel = ({
  setFiltersMobileIsOpen,
  filtersMobileIsOpen,
  hasScroll,
  children,
}: {
  setFiltersMobileIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  filtersMobileIsOpen: boolean;
  hasScroll: boolean;
  children: React.ReactNode;
}) => (
  <>
    <FiltersMobilePanelButton
      hasScroll={hasScroll}
      onClick={() => setFiltersMobileIsOpen(true)}
    />
    <Swipper
      open={filtersMobileIsOpen}
      onClose={() => setFiltersMobileIsOpen(false)}
    >
      <div>{children}</div>
    </Swipper>
  </>
);
