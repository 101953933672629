import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import {
  StandardTextFieldProps,
  TextFieldProps,
} from "@material-ui/core/TextField";
import React, { useState } from "react";
import { IconEyeClose16, IconEyeOpen16 } from "./icons-ts";
import { colors } from "./lib/constants";

export interface IInputProps extends StandardTextFieldProps {
  children?: React.ReactNode;
}

const InputPassword = (props: TextFieldProps) => {
  const [show, setShow] = useState(false);

  return (
    <TextField
      type={show ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <IconButton
            aria-label="Toggle password visibility"
            onClick={() => setShow(!show)}
          >
            {show ? (
              <IconEyeOpen16 fill={colors.darkDk} width={16} height={10} />
            ) : (
              <IconEyeClose16 fill={colors.darkDk} width={16} height={10} />
            )}
          </IconButton>
        ),
      }}
      {...props}
    />
  );
};

export const Input = React.forwardRef(
  (
    {
      type,
      icon,
      className,
      ...props
    }: {
      icon?: React.ReactNode;
      className?: string;
    } & TextFieldProps,
    ref,
  ) => {
    if (props.helperText && Array.isArray(props.helperText)) {
      // Fix error when helperText is an array and key is null
      props.helperText = props.helperText[0];
    }
    if (type === "password") {
      return <InputPassword {...props} inputRef={ref as any} />;
    }

    return (
      <TextField
        type={type}
        {...props}
        InputProps={{
          ...props.InputProps,
          className,
          endAdornment: icon && (
            <InputAdornment position="end" style={{ marginRight: 3 }}>
              {icon}
            </InputAdornment>
          ),
        }}
        inputRef={ref as any}
      />
    );
  },
);
